import {useContext, Fragment} from 'react';
import InfluencerContext from '../../../store/influencer-context';

import Loader from '../../../components/UIElements/Loader/Loader';

import downArrowGreen from '../../../assets/images/down-arrow-green.webp';
import styles from './Hero.module.css';

const Hero = props => {

	const influencerCtx = useContext(InfluencerContext);

	const iconStyles = {
		height: '32px',
		width: '32px',
		background: `url(${downArrowGreen})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat'
	}

	let mainContent = null
	if (influencerCtx.isLoading) mainContent = <Loader hero>Loading</Loader>
	if (influencerCtx.firstName) mainContent = (
		<Fragment>
			<h1 className={styles.Header}>Taste <em className='me-1'>what's</em> True</h1>
			<p className={styles.Tagline}>{`"${influencerCtx.heroQuote}"`}</p>
			<p className={styles.Name}>{`${influencerCtx.firstName} ${influencerCtx.lastName},`}</p>
			<p className={styles.Title}>{influencerCtx.title}</p>
			<button className={`btn btn-link ${styles.CTA}`} onClick={props.handleScroll}>
				<div className={styles.Icon} style={iconStyles}></div>
				<span>Bottles and more</span>
			</button>
		</Fragment>
	)	

	return (
		<section className={styles.Hero}>
			<div className='container'>
				<div className='row'>
					<div className='col-md-9 offset-md-3'>
						{mainContent}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero;