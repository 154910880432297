import facebookIcon from '../../../../assets/images/facebook-icon.webp';
import {FacebookShareButton} from 'react-share';

import styles from './FacebookButton.module.css';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../../helpers/analytics-helpers';

const FacebookButton = props => {
	
	const placeholderCopy = "I just ordered True Olive olive oil for the first time. I can't wait for my olive oil to get here." 
	const postCopy = props.postCopy ? props.postCopy : placeholderCopy;

	let shareTypeEventName = eventNames.CLICKED_ORDER_SHARE_OPTION;
	if (props.shareObject==='recipe') shareTypeEventName = eventNames.CLICKED_RECIPE_SHARE_OPTION;

	const postUrl = props.shareUrl; 

	const analyticsHandler = () => {
		ReactGA.event({
      category: eventCategories.VIRALITY, 
      action: shareTypeEventName,
      label: 'facebook'
    });
	}

	return (
		<span onClick={analyticsHandler}>
			<FacebookShareButton
	      url={postUrl}
	      quote={postCopy}
	    >
	    	<img src={facebookIcon} alt='Facebook icon' className={styles.Icon} />
	    </FacebookShareButton>
	  </span>
	)
}

export default FacebookButton;