import { createContext, useState } from 'react';
import { getFBInfluencerBySlug } from '../helpers/firebase-helpers';

const InfluencerContext = createContext({
	influencerId: '',
	slug: '',
	firstName: '',
	lastName: '',
	title: '',
	imageFileName: '',
	heroQuote: '',
	storyQuote: '',
	couponCode: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	getInfluencerBySlug: slug => {}
});

export const InfluencerContextProvider = props => {

	const [influencerId, setInfluencerId] = useState('');
	const [slug, setSlug] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [title, setTitle] = useState('');
	const [imageFileName, setImageFileName] = useState('');
	const [heroQuote, setHeroQuote] = useState('');
	const [storyQuote, setStoryQuote] = useState('');
	const [couponCode, setCouponCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const handleSuccess = (influencer) => {
		setInfluencerId(influencer.influencerId);
		setSlug(influencer.slug);
		setFirstName(influencer.firstName);
		setLastName(influencer.lastName);
		setTitle(influencer.title);
		setImageFileName(influencer.imageFileName);
		setHeroQuote(influencer.heroQuote);
		setStoryQuote(influencer.storyQuote);
		setCouponCode(influencer.couponCode);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const getInfluencerBySlug = async slug => {
		resetStates();
		setIsLoading(true);
		const influencer = await getFBInfluencerBySlug(slug)
		if (influencer){
			handleSuccess(influencer);
		 return influencer;
		}else {
			handleFail({message: 'Failed to get influencer data'});
		 return false;
		}
	}

	return (
		<InfluencerContext.Provider value={{
			influencerId: influencerId,
			slug: slug,
			firstName: firstName,
			lastName: lastName,
			title: title,
			imageFileName: imageFileName,
			heroQuote: heroQuote,
			storyQuote: storyQuote,
			couponCode: couponCode,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			getInfluencerBySlug: getInfluencerBySlug
		}}>
			{props.children}
		</InfluencerContext.Provider>
	)
}

export default InfluencerContext;