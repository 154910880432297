import React, {useState, useEffect, useContext} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import { loadStripe } from "@stripe/stripe-js";

import ReactGA from 'react-ga4';

import AuthContext from '../../store/auth-context';
import UserContext from '../../store/user-context';
import PromotionCodeContext from '../../store/promotion-code-context';

import Home from '../Home/Home';
import RestaurantLanding from '../RestaurantLanding/RestaurantLanding';
import Influencer from '../Influencer/Influencer';
import Academy from '../Academy/Academy';
import OurStory from '../OurStory/OurStory';
import OurOliveOils from '../OurOliveOils/OurOliveOils';
import BecomeAMember from '../BecomeAMember/BecomeAMember';
import HealthBenefits from '../HealthBenefits/HealthBenefits';
import ChoosingOliveOil from '../ChoosingOliveOil/ChoosingOliveOil';
import Recipes from '../Recipes/Recipes';
import Recipe from '../Recipe/Recipe';
import CreateAccount from '../CreateAccount/CreateAccount';
import RegisterPage from '../RegisterPage/RegisterPage';
import Account from '../Account/Account';
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation';
import InstaRedirect from '../InstaRedirect/InstaRedirect';

import {selectPageTitle} from '../../helpers/page-title-helpers';
import {postData} from '../../helpers/http-helpers';

import styles from './App.module.css';

import '../../assets/fonts/TTCommonsProRegular.ttf';
import '../../assets/fonts/TTCommonsProMedium.ttf';
import '../../assets/fonts/TTCommonsProDemiBold.ttf';
import '../../assets/fonts/PPEditorialNew-Ultralight.ttf';
import '../../assets/fonts/PPEditorialNew-UltralightItalic.ttf';

const App = () => {

	const authCtx = useContext(AuthContext);
	const userCtx = useContext(UserContext);
	const promotionCodeCtx = useContext(PromotionCodeContext);

	ReactGA.initialize([{trackingId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}]);

	const location = useLocation();

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const promoCode = urlParams.get('promoCode');

	// Used to fire a soft launch modal
	const pathnameFirstPart= window.location.pathname.split('/')[1];

	// Adjust page titles
	useEffect(() => {
		document.title = selectPageTitle(window.location.pathname);
    window.scrollTo(0,0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [location]);

  // Reauthenticate and get user info
  useEffect(()=>{
  	if (
      !authCtx.idToken && 
      !localStorage.getItem('TrueOliveIdToken') && 
      !authCtx.isLoading && 
      localStorage.getItem('TrueOliveRefreshToken')
    ) {
      authCtx.onRefreshAuth(localStorage.getItem('TrueOliveRefreshToken'));
    }
    if (authCtx.localId && !userCtx.userId) {
    	userCtx.getUserInfo(authCtx.localId);
    }
  },[authCtx]);

  // Get promotion code information
  useEffect(()=>{
  	if(promoCode) promotionCodeCtx.getPromotionData(promoCode);
  	// Used to fire a soft launch modal
  	if(!promoCode && promotionCodeCtx.softLaunchCode && pathnameFirstPart !== 'community') {
  		promotionCodeCtx.getPromotionData(promotionCodeCtx.softLaunchCode);
  	}
  },[promotionCodeCtx.name])

  let authProtectedRoutes = null;
  if (authCtx.isLoggedIn) {authProtectedRoutes = <Route path='/account' element={<Account />}></Route>}

	return (
		<div className={styles.Global}>
			<Routes>
				<Route path='/' element={<Home />}></Route>
				<Route path='/restaurants' element={<RestaurantLanding />}></Route>
				<Route path='/community/:influencerSlug' element={<Influencer />}></Route>
				<Route path='/academy' element={<Academy />}></Route>
				<Route path='/our-story' element={<OurStory />}></Route>
				<Route path='/our-olive-oils' element={<OurOliveOils />}></Route>
				<Route path='/become-a-member' element={<BecomeAMember />}></Route>
				<Route path='/academy/health-benefits' element={<HealthBenefits />}></Route>
				<Route path='/academy/choosing-olive-oil' element={<ChoosingOliveOil />}></Route>
				<Route path='/academy/recipes' element={<Recipes />}></Route>
				<Route path='/academy/recipes/:recipeSlug' element={<Recipe />}></Route>
				<Route path='/create-account' element={<CreateAccount />}></Route>
				<Route path='/register' element={<RegisterPage />}></Route>
				{authProtectedRoutes}
				<Route path='/order-confirmation' element={<OrderConfirmation />}></Route>
				<Route path='/recipes-redirect' element={<InstaRedirect />}></Route>
				<Route path='*' element={<Navigate to="/" replace />} />
			</Routes>
		</div>
	)
}

export default App;