import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Plans from '../../components/UIElements/Plans/Plans';
import TastingCollection from '../../components/UIElements/TastingCollection/TastingCollection';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import FAQs from '../../components/UIElements/FAQs/FAQs';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const BecomeAMember = props => {

	const academyBannerCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BANNER_CTA,
    	label: 'becomeAMember'
    });
	}

	const banner = {
		image: 'academy-promo-banner.webp',
		mobileImage: 'academy-promo-mobile-banner.webp',
		altText: 'Level up your olive oil know-how with True Olive Academy',
		title: 'Level up your olive oil know-how with True Olive Academy',
		body: 'True Olive Academy will get you up to speed on the health benefits of high-quality olive oil, how you can identify and choose high-quality olive oil, and how you can delight and nurture your senses with curated recipes.',
		CTA: 'Level up with True Olive Academy',
		linkTo: '/academy/choosing-olive-oil',
		analyticsHandler: ()=>academyBannerCTAAnalyticsHandler()
	}

	return(
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<Plans />
					<div className='col-lg-10 mb-4'><TastingCollection subscription/></div>
					<div className='col-12 mb-4'><ImageBanner banner={banner}/></div>
					<FAQs />
				</div>
			</div>
		</MainLayout>
	)
}

export default BecomeAMember;

