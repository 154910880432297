import {useContext} from 'react';
import CartContext from '../../../store/cart-context';

import PurchaseOptionItem from './PurchaseOptionItem/PurchaseOptionItem'; 

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const PurchaseOptions = props => {

	const cartCtx = useContext(CartContext);

	// Handler for analytics events
	const purchaseOptionAnalyticsHandler = name => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_PURCHASE_OPTION,
    	label: name
    })
	}

	const samplerClickHandler = () => {
		cartCtx.addItem('tastingCollection');
		purchaseOptionAnalyticsHandler('tastingCollection')	
	}

	const purchaseOptionsList = [
		{
			id: 0,
			type: 'sample',
			title: 'Try them all',
			description: 'Get a bottle of each of our olive oils. Makes a great gift.',
			CTA: 'Order a Tasting Collection - $79',
			clickHandler: ()=>samplerClickHandler()
		},
		{
			id: 1,
			type: 'shop',
			title: 'Get the bottles you want',
			description: 'Know what you want?  Send in an order.',
			CTA: 'Shop now',
			link: '/our-olive-oils',
			clickHandler: () => purchaseOptionAnalyticsHandler('bottles')
		},
		{
			id: 2,
			type: 'subscribe',
			title: 'Always have access',
			image: 'bottles',
			description: 'We have limited supply. Become a member and guarantee your shipment.',
			CTA: 'Become a member',
			link: '/become-a-member',
			clickHandler: () => purchaseOptionAnalyticsHandler('memberships')
		}
	];

	const list = purchaseOptionsList.map(option=>{
		return <PurchaseOptionItem option={option} key={option.id} />
	});

	return (
		<div className='d-lg-flex'>
			{list}
		</div>
	)

}

export default PurchaseOptions;
