import styles from './BtnLink.module.css';

const BtnLink = props => {

	const wrapStyles = props.noWrap ? styles.NoWrap : '';
	const smallStyles = props.small ? styles.Small : '';

	return (
		<button 
			className={`btn btn-link ${styles.BtnLink} ${wrapStyles} ${smallStyles} ${props.passOnClasses}`} 
			disabled={props.disabled}
			onClick={props.clickHandler}
		>
			{props.children}
		</button>
	)
}

export default BtnLink;