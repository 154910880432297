import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Membership from './Membership/Membership';
import Error from '../../../components/UIElements/Error/Error';
import Loader from '../../../components/UIElements/Loader/Loader';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import FeatureP from '../../../components/UIElements/TextElements/FeatureP/FeatureP';
import OutlineButton from '../../../components/UIElements/Buttons/OutlineButton/OutlineButton';

const Memberships = props => {

	let displayContent = null;	

	if (props.list.length>0) displayContent = props.list.map(ms => {
		return <Membership 
			membershipData={ms} 
			key={ms.subscriptionItemId} 
			cancelMembershipHandler={props.cancelMembershipHandler}
		/>
	});

	if (props.list.length===0) displayContent = (
			<Fragment>
				<FeatureP>
					You don't have any memberships yet. Our olive oil supply is limited to what our farm can produce. 
					To ensure those who appreciate our olive oil can always have access, we offer memberships 
					which guarantee your shipment and comes with other benefits.
				</FeatureP>
				<Link to='/become-a-member'>
					<OutlineButton>Explore membership options</OutlineButton>
				</Link>
			</Fragment>
		);
	if (props.isLoading) displayContent = <Loader>Loading your membership information...</Loader>;
	if (props.Error) displayContent = <Error errorCode={props.error}>There was an error loading your membership information</Error>;

	return ( 
		<Fragment>
			<ListItemHeader passOnClasses='d-none d-md-block mb-4'>Memberships</ListItemHeader>
			{displayContent}
		</Fragment>
	)
}

export default Memberships;