import React from 'react';
import {Link} from 'react-router-dom';

import styles from './BtnLink.module.css';

const BtnLink = props => {
	return (
		<Link to={props.destination}>
			<button className={`btn btn-primary ${styles.Button}`} onClick={props.clickHandler}>
				{props.children}
			</button>
		</Link>
	)
}

export default BtnLink;
