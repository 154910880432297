import { createContext, useState } from 'react';
import { getFBRecipes, getFBRecipeBySlug } from '../helpers/firebase-helpers';

const RecipesContext = createContext({
	recipes: [],
	selectedRecipe: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	selectRecipe: recipeId => {},
	resetSelectedRecipe: ()=>{},
	getRecipes: ()=>{},
	getRecipe: recipeId => {}, 
});

export const RecipesContextProvider = props => {
	const [recipes, setRecipes] = useState('');
	const [selectedRecipe, setSelectedRecipe] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const handleSuccess = recipes => {
		setRecipes(recipes);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleRecipeSuccess = recipe => {
		setSelectedRecipe(recipe);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const selectRecipe = recipeId =>{
		const newSelectedRecipe = recipes.find(recipe=>recipe.recipeId === recipeId);
		if (newSelectedRecipe) setSelectedRecipe(newSelectedRecipe);
		return;
	}

	const resetSelectedRecipe = () => {
		setSelectedRecipe('');
	}

	const getRecipes = async () => {
		resetStates();
		setIsLoading(true);

		const recipes = await getFBRecipes();
		if (recipes) {
			handleSuccess(recipes);
			return recipes;
		} else {
			handleFail({message: 'There aren\'t any recipes'});
			return;
		}
	}

	const getRecipeBySlug = async slug => {
		resetStates();
		setIsLoading(true);

		const recipe = await getFBRecipeBySlug(slug);
		if (recipe) {
			handleRecipeSuccess(recipe);
			return recipe;
		} else {
			handleFail({message: 'There aren\'t any matching recipes'});
			return;
		}
	}

	return (
		<RecipesContext.Provider value={{
			recipes: recipes,
			selectedRecipe: selectedRecipe,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			selectRecipe: selectRecipe,
			resetSelectedRecipe: resetSelectedRecipe,
			getRecipes: getRecipes,
			getRecipeBySlug: getRecipeBySlug
		}}>
			{props.children}
		</RecipesContext.Provider>
	)
}

export default RecipesContext;
