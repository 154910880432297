import CardHeader from '../TextElements/CardHeader/CardHeader';
import FeatureP from '../TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../TextElements/ListItemInfoP/ListItemInfoP';

import InnerCard from './InnerCard/InnerCard';

import styles from './HorizontalCard.module.css';

const HorizontalCard = props => {

	return (
		<div className={styles.Card}>
			<img 
				src={require(`../../../assets/images/academy/${props.card.image}`)}
				alt={props.card.altText}
				className={styles.Image}
			/>
			<div className={styles.Content}>
				<CardHeader>{props.card.title}</CardHeader>
				<FeatureP>{props.card.body}</FeatureP>
				{props.card.trueOliveDifference && <InnerCard difference={props.card.trueOliveDifference} />}
			</div>
		</div>
	)
}

export default HorizontalCard