import BaseP from '../../../TextElements/BaseP/BaseP';
import LeadP from '../../../TextElements/LeadP/LeadP';

const Question = props => {
	return (
		<div className='mb-5'>
			<LeadP bold mbSmall>{props.question.question}</LeadP>
			<BaseP>{props.question.answer}</BaseP>
		</div>
	)
}

export default Question;