import {useContext} from 'react';
import AlertContext from '../../../store/alert-context';

import checkIcon from '../../../assets/images/check-icon.webp';
import FeatureP from '../TextElements/FeatureP/FeatureP';

import styles from './Alert.module.css';

const Alert = props => {

	const alertCtx = useContext(AlertContext);

	const typeStyles = alertCtx.type==='success' ? styles.Success : '';
	let animationStyles = '';
	if (alertCtx.isShown) animationStyles = styles.SlideIn; 
	if (alertCtx.isClosed) animationStyles = styles.SlideOut; 

	return (
		<div className={`${styles.Alert} ${typeStyles} ${animationStyles}`}>
			<img src={checkIcon} alt='Check icon' className={styles.Image} />
			<FeatureP passOnClasses='mb-0'>{alertCtx.message}</FeatureP>
		</div>
	)
}

export default Alert;