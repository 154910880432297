import checkIcon from '../../../../assets/images/check-icon.webp';
import SectionHeader from '../../../UIElements/TextElements/SectionHeader/SectionHeader';

const ForgotPasswordSuccess = props => {
	return (
		<div className='text-center p-4'>
			<img src={checkIcon} alt='Check'/>
			<SectionHeader passOnClasses='mb-0 mt-4'>
				Please check your email to reset your password
			</SectionHeader>
		</div>
	)
}

export default ForgotPasswordSuccess