import React from 'react';
import styles from './FeatureP.module.css';

const FeatureP = props => {
	
	const lessLineHeightStyles = props.lessLineHeight ? styles.LessLineHeight : '';

	return <p className={`${props.passOnClasses} ${styles.FeatureP} ${lessLineHeightStyles}`}>{props.children}</p>
}

export default FeatureP;