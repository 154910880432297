import {useState, useContext} from 'react';

import AuthContext from '../../../store/auth-context';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import Logo from '../../UIElements/Logo/Logo';
import CartIcon from '../../UIElements/CartIcon/CartIcon';
import Navbar from '../Navbar/Navbar';

const RestaurantNavbar = props => {

	const authCtx = useContext(AuthContext);
	
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	const path = window.location.pathname;

	// Handlers for analytics events
	const navItetemAnalyticsHandler = name => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ON_NAV_ITEM,
    	label: name
    })
	}

	const handleSignInClick = () => {
		props.fireSignInModal();
		navItetemAnalyticsHandler('signIn');
		ReactGA.event({
    	category: eventCategories.AUTHENTICATION, 
    	action: eventNames.OPENED_SIGN_IN,
    });
	}

	const handleBecomeAMemberClick = () => {
		navItetemAnalyticsHandler('becomeAMember');
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_NAV_CTA,
    });
	}

	const handleLogoutClick = () => {
		authCtx.onLogout();
		navItetemAnalyticsHandler('logout');
	}


	const navItems = [
		{
			id: 0,
			copy: 'For Restaurants',
			type: 'link',
			destination: '/restaurants',
			showTo: 'everyone', 
			active: path === '/restaurants' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('restaurantsHome')
		},
		{
			id: 1,
			copy: 'For Consumers',
			type: 'link',
			destination: '/',
			showTo: 'everyone',
			active: path === '/' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('forConsumers')
		},
		{
			id: 2,
			copy: 'Sign In',
			type: 'link-btn',
			showTo: 'guests',
			clickHandler: () => handleSignInClick() 
		}, 
		{
			id: 3,
			copy: 'Your Account',
			type: 'link',
			destination: '/account',
			showTo: 'authenticated',
			active: path === '/account' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('yourAccount')
		},
		{
			id: 4,
			copy: 'Logout',
			type: 'link-btn',
			showTo: 'authenticated',
			clickHandler: () => handleLogoutClick()
		}
	]

	const backgroundStyles = {
		background: `#445D5C`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
	}

	const menuStyles = {
		...backgroundStyles,
		backgroundPosition: '-90px 0px'
	}

	return (
		<section style={backgroundStyles}>
			<nav className='navbar navbar-dark'>
				<div className='container'>
					<Logo />				
					<div className='d-flex d-lg-none align-items-center'>	
						<button 
							className='custom-toggler navbar-toggler' 
							type='button' 
							data-toggle='collapse'
							data-target='#navbarExpand' 
							aria-controls='navbarExpand' 
							aria-expanded={!isNavCollapsed ? true : false} 
							aria-label='Toggle navigation' 
							onClick={handleNavCollapse}
						>
							<span className='navbar-toggler-icon'></span>
	      		</button>
	      		<CartIcon />
      		</div>
      		<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} style={menuStyles} id='navbarExpand'>
		        <Navbar authenticated={authCtx.isLoggedIn} navItems={[...navItems]} />
	      	</div>
	      	<div className='d-none d-lg-flex align-items-center'>
	      		<Navbar authenticated={authCtx.isLoggedIn} navItems={[...navItems]} />
	      		<CartIcon />
	      	</div>
				</div>
			</nav>
		</section>
	)
}

export default RestaurantNavbar;