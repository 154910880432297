import downArrow from '../../../assets/images/down-arrow.webp';
import PrimaryCTA from '../../../components/UIElements/Buttons/PrimaryCTA/PrimaryCTA'
import styles from './Hero.module.css';

const Hero = props => {

	const iconStyles = {
		height: '24px',
		width: '24px',
		background: `url(${downArrow})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	}

	return (
		<section className={styles.Hero}>
			<div className='container'>
				<div className='row'>
					<div className='col-md-9 col-lg-8'>
						<h1 className={styles.Header}>
							Taste <em className='me-1'>what's</em> True
						</h1>
						<p className={styles.Tagline}>
							<span className={styles.ShortCopy}>
								Uplift, nourish and bring people to the table with our award-winning, single-estate olive oils from the Dardanelles.
							</span>
							<span className={styles.LongCopy}>
								Produced from centuries old trees on our estate in the Dardanelles, without pesticides 
								or harmful chemicals, our award-winning olive oils uplift, nourish, and bring people to 
								the table.
							</span>
						</p>
						<div className={`${styles.CTAContainer} ${styles.LargeCTA}`}>
							<PrimaryCTA xLargeSized clickHandler={props.handleScroll}>
								<div className='d-flex align-items-center'>
									<div className={styles.Icon} style={iconStyles}></div>
									Bottles and more
								</div>
							</PrimaryCTA>
						</div>
						<div className={`${styles.CTAContainer} ${styles.MediumCTA}`}>
							<PrimaryCTA clickHandler={props.handleScroll}>
								<div className='d-flex align-items-center'>
									<div className={styles.Icon} style={iconStyles}></div>
									Bottles and more
								</div>
							</PrimaryCTA>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero;