export const bottleDescriptions = {
	PREMIUM: `The perfect olive oil for cooking, Premium is ideal for baking, roasting, and frying. It takes any 
	dish from fried eggs, to baked goods to the next level. It's produced from the late-harvest olives 
	and cold-pressed within 24 hours of picking.`,
	LUXE: `Luxe is a high-end, versatile olive oil ideal for dressings, marinades, and dipping bread. 
	Great with seasonings, it enhances the flavor of any dish. It's produced from the early-harvest 
	olives and cold-pressed within 24 hours of picking.`,
	PRIVATE_RESERVE:`Private Reserve is our gold-award winner. Dress and finish your salads, meat, 
	pasta, ice cream, baked goods, and more with Private Reserve's smooth flavors. It's produced from 
	the early-harvest olives and cold-pressed within 1 hour of picking.`
}

