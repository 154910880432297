import React from 'react';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import PurchaseOptions from '../../components/UIElements/PurchaseOptions/PurchaseOptions';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';

import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import LeadP from '../../components/UIElements/TextElements/LeadP/LeadP';
import BaseP from '../../components/UIElements/TextElements/BaseP/BaseP';

import tree from '../../assets/images/tree.webp';
import founders from '../../assets/images/founders.webp';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

import styles from './OurStory.module.css';

const OurStory = () => {

	const academyBannerCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BANNER_CTA,
    	label: 'ourStory'
    });
	}

	const banner = {
		image: 'academy-promo-banner.webp',
		mobileImage: 'academy-promo-mobile-banner.webp',
		altText: 'Level up your olive oil know-how with True Olive Academy',
		title: 'Level up your olive oil know-how with True Olive Academy',
		body: 'True Olive Academy will get you up to speed on the health benefits of high-quality olive oil, how you can identify and choose high-quality olive oil, and how you can delight and nurture your senses with curated recipes.',
		CTA: 'Level up with True Olive Academy',
		linkTo: '/academy/choosing-olive-oil',
		analyticsHandler: ()=>academyBannerCTAAnalyticsHandler()
	}

	return (
		<MainLayout bottomCTA='default'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-9 py-5 mb-2'>
						<SectionHeader passOnClasses='mb-3'><em>True love that spans continents and generations</em></SectionHeader>
						<BaseP>
							Hi, my name is Can (John) and, on behalf of Nicole and I, I want to tell you True Olive's 
							story of love.
						</BaseP>
						<BaseP>
							The story starts with my parents. My mother always wanted an olive farm. 
							And my father is prone to grand gestures, especially when it comes to expressing his love. 
							So about two decades ago my father gifted my mother an olive farm nestled in the  
							Dardanelles, near the ancient city of Troy. 
						</BaseP> 
						<BaseP>
							As beautiful as the story starts, there were obstacles to overcome and choices to make. A 
							decade ago, a fire destroyed acres of olive and redwood trees on our farm. Conventional 
							wisdom says you ought to plant new trees and start anew, but my parents wanted to give 
							the trees they loved so dearly a fighting chance to come back to life. 
						</BaseP>
						<BaseP>
							Over time, the burnt trees decomposed, ultimately enriching and changing the soil. 
							The original olive and redwood trees regenerated, and eventually bore fruit with a 
							distinctive, incomparable flavor.
						</BaseP>
						<BaseP>
							That clearing and rebirth following the fire inspired them to begin again. With their new 
							partner, Mehmet T., they to brought in olive oil consultants, invested in the farm's 
							infrastructure and started crafting olive oils harmonizing time-honored practices and 
							new technology.
						</BaseP>
						<BaseP>
							Soon, their patience, hardwork, and dedication to excellence paid off. The olive oils from 
							our farm started winning awards all over the world.
						</BaseP>
						<img src={tree} alt="Olive tree from the farm" className={styles.TreeImage}/>
						<BaseP>
							While all this was happening, I was living thousands of miles away in California. That
							wasn't my original plan, but love is ignorant of our plans. I first came to the US 
							two decades ago for college and I was planning on returning back home after I graduated. 
							However, fate had a different design, as I met Nicole. Our love blossomed, culminating 
							in marriage, children, and we established our lives in California.      
						</BaseP>
						<BaseP>
							Nicole and I share a passion for food, and it was during this time that we began 
							bringing our family's olive oil to the US for personal use. We just couldn't find olive 
							oil of comparable taste here. 
						</BaseP>
						<BaseP>
							We also like entertaining. Once we started bringing the olive oil over, our friends started 
							complimenting our food even more than they used to. Don't get me wrong, Nicole and I can 
							cook, but even simple dishes, like basic salads, were getting rave reviews. Our secret 
							ingredient was the family's olive oil.
						</BaseP>
						<BaseP>
							Encouraged by the enthusiastic feedback and support of those around us, we embark on a 
							new chapter, making our family's olive oil available to all.
						</BaseP>
						<BaseP>
							If you are one of those people who appreciates finer things in life order from True Olive 
							and Taste What's True.
						</BaseP>
						<div className='d-flex align-items-center'>
							<img src={founders} alt='Nicole and Can' className={styles.FoundersImage}/>
							<div>
								<BaseP passOnClasses='mb-0'>On behalf of Nicole and I,</BaseP>
								<p className={styles.Signature}>Can (John) Babaoglu</p>
							</div>
						</div>
					</div>
					<div className='col-12'>
						<h3 className={styles.PurchasingHeader}>
							Purchasing options - <span className={styles.Tagline}>Taste <em className='me-1'>what's</em> true</span>
						</h3>
						<PurchaseOptions />
					</div>
					<div className='col-12 mb-4'><ImageBanner banner={banner}/></div>
				</div>
			</div>
		</MainLayout>
	)
}

export default OurStory;