import styles from './ListItemHeader.module.css';

const ListItemHeader = props => {
	let sizeClasses = '';
	if (props.large) sizeClasses = styles.Large

	return (
		<h3 className={`${props.passOnClasses} ${styles.ListItemHeader} ${sizeClasses}`}>{props.children}</h3>
	)
}

export default ListItemHeader;