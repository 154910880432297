import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import Loader from '../../../components/UIElements/Loader/Loader';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import ListItemInfoP from '../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import SignInForm from '../../../components/Forms/SignInForm/SignInForm';

const AccountSignIn = props => {

	let contentArea = (
		<SignInForm 
			setIsAccountLoading={props.setIsAccountLoading} 
			setIsAccountSuccessful={props.setIsAccountSuccessful}
			inline={true}
			email={props.email}
			orderConfirmationPage
		/>
	)
	if (props.isAccountLoading) contentArea = <Loader dark>Signing in to your account...</Loader> 

	return ( !props.isAccountSuccessful &&
		<OptionsWrapper passOnClasses='mb-5 mt-5'> 
			<ListItemHeader>Sign in to your account to activate your membership</ListItemHeader>
			<ListItemInfoP>
				Our records show there is an existing account with your email. Your order has 
				been processed but you need to sign in to activate your membership 
			</ListItemInfoP>
			{contentArea}
		</OptionsWrapper>
	)
}

export default AccountSignIn;