import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import Label from '../../../components/UIElements/TextElements/Label/Label';
import TwitterButton from '../../../components/UIElements/ShareOptions/TwitterButton/TwitterButton';
import FacebookButton from '../../../components/UIElements/ShareOptions/FacebookButton/FacebookButton';
import WhatsappButton from '../../../components/UIElements/ShareOptions/WhatsappButton/WhatsappButton';

const SocialSharing = props => {

	const shareUrl = `${process.env.REACT_APP_BASE_URL}/academy/recipes/${props.slug}`;
	const shareMessage = props.shareMessage;

	return (
		<OptionsWrapper small passOnClasses='mb-3'>
			<Label passOnClasses='mb-2'>Share</Label>
			<div className='d-flex align-items-center'>
				<div className='me-2'><TwitterButton tweetCopy={shareMessage} shareUrl={shareUrl}/></div>
				<div className='me-2'><WhatsappButton postCopy={shareMessage} shareUrl={shareUrl} /></div>
				<FacebookButton postCopy={shareMessage} shareUrl={shareUrl}/>
			</div>
		</OptionsWrapper>
	)

}

export default SocialSharing;