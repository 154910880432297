import {Link} from 'react-router-dom';

import ListItemHeader from '../TextElements/ListItemHeader/ListItemHeader';
import BaseP from '../TextElements/BaseP/BaseP';
import PrimaryCTA from '../Buttons/PrimaryCTA/PrimaryCTA';

import styles from './ImageBanner.module.css';

const ImageBanner = props => {
	
	const bgStyles = {
		backgroundImage: `url(${require(`../../../assets/images/${props.banner.image}`)})`
	}

	return (
		<div className={styles.Banner} style={bgStyles} >
			<ListItemHeader passOnClasses='mb-3'>{props.banner.title}</ListItemHeader>
			<img 
				src={require(`../../../assets/images/${props.banner.mobileImage}`)}
				alt={props.banner.altText}
				className={styles.MobileImage}
			/>
			<BaseP passOnClasses='mb-4'>{props.banner.body}</BaseP>
			<div className='d-none d-sm-block'>
				<Link to={props.banner.linkTo} onClick={props.banner.analyticsHandler}>
					<PrimaryCTA>{props.banner.CTA}</PrimaryCTA>
				</Link>
			</div>
			<div className='d-sm-none'>
				<Link to={props.banner.linkTo} onClick={props.banner.analyticsHandler}>
					<PrimaryCTA smallSized>{props.banner.CTA}</PrimaryCTA><
				/Link>
			</div>
		</div>
	)
}

export default ImageBanner;