import React from 'react'; 
import PlanListItem from './PlanListItem/PlanListItem';
import AdditionalInfoP from '../../TextElements/AdditionalInfoP/AdditionalInfoP';

const PlanList = props => {
	
	const list = props.plansList.map(plan=>{
		return <PlanListItem plan={plan} key={plan.id} />
	});

	return (
		<div className='mb-4'>
			<div className='d-md-flex justify-content-between pt-4'>
				{list}
			</div>
			<AdditionalInfoP>Free shipping on all memberships</AdditionalInfoP>
		</div>
	)
}

export default PlanList