import {useRef, useState, useContext, useEffect, useCallback, Fragment} from 'react';
import { useParams } from 'react-router-dom';

import InfluencerContext from '../../store/influencer-context';
import PromotionCodeContext from '../../store/promotion-code-context';
import CartContext from '../../store/cart-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Bottles from '../../components/UIElements/Bottles/Bottles';
import TastingCollection from '../../components/UIElements/TastingCollection/TastingCollection';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import Hero from './Hero/Hero';
import Story from './Story/Story';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';
import {bottleDescriptions} from '../../helpers/bottle-helpers';

import {makePossesive} from '../../helpers/misc-helpers';

const Influencer = props => {

	const ref = useRef(null);
	const { influencerSlug } = useParams();
	const [isLoading, setIsLoading] = useState(false);

	const influencerCtx = useContext(InfluencerContext);
	const cartCtx = useContext(CartContext);
	const promoCodeCtx = useContext(PromotionCodeContext);


	const academyBannerCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BANNER_CTA,
    	label: 'influencer'
    });
	}

	const banner = {
		image: 'academy-promo-banner.webp',
		mobileImage: 'academy-promo-mobile-banner.webp',
		altText: 'Level up your olive oil know-how with True Olive Academy',
		title: 'Level up your olive oil know-how with True Olive Academy',
		body: 'True Olive Academy will get you up to speed on the health benefits of high-quality olive oil, how you can identify and choose high-quality olive oil, and how you can delight and nurture your senses with curated recipes.',
		CTA: 'Level up with True Olive Academy',
		linkTo: '/academy/choosing-olive-oil',
		analyticsHandler: ()=>academyBannerCTAAnalyticsHandler()
	}

	const bottlesArray = [
		{
			id: 0,
			title: 'Premium',
			bottle: '375 ml bottle',
			description: bottleDescriptions.PREMIUM,
			img: 'premium',
			listPrice: cartCtx.getItemListPrice('premium'),  
			displayPrice: cartCtx.getItemDiscountedPrice('premium', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('premium')
		},
		{
			id: 1,
			title: 'Luxe',
			bottle: '375 ml bottle',
			description: bottleDescriptions.LUXE,
			img: 'luxe',
			listPrice: cartCtx.getItemListPrice('luxe'),
			displayPrice:cartCtx.getItemDiscountedPrice('luxe', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('luxe')
		},
		{
			id: 2,
			title: 'Private Reserve',
			bottle: '375 ml bottle',
			description: bottleDescriptions.PRIVATE_RESERVE,
			img: 'private-reserve',
			listPrice: cartCtx.getItemListPrice('privateReserve'),
			displayPrice:cartCtx.getItemDiscountedPrice('privateReserve', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('privateReserve')
		}
	];;

	const fetchData = useCallback(async slug => {
		let promotionCode = null;
		
		const influencer = await influencerCtx.getInfluencerBySlug(influencerSlug);
		if (influencer) {
			promotionCode = await promoCodeCtx.getPromotionData(influencer.couponCode);
		}
	},[]);

	useEffect(()=>{fetchData();},[promoCodeCtx.name])

	const handleScroll = () => {
	  ref.current?.scrollIntoView({behavior: 'smooth'});
	  ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.SCROLLED_FROM_INFLUENCER_HERO,
    });
	};

	let belowFoldContent = null;
	if (influencerCtx.firstName){

		const bottlesHeader = `Special Offer For ${makePossesive(influencerCtx.firstName)} Friends`;
		const bottlesTagline = `${influencerCtx.firstName} has been a supporter of True Olive since our 
			earliest days. To show our appreciation we are offering our olive oils to ${makePossesive(influencerCtx.firstName)} 
			friends at a special rate.`

		belowFoldContent = (
			<Fragment>
				<Story name={influencerCtx.firstName} story={influencerCtx.storyQuote} imageFileName={influencerCtx.imageFileName} /> 
				<Bottles header={bottlesHeader} tagline={bottlesTagline} bottlesArray={bottlesArray} />
				<div className='col-xl-10 mb-4'><TastingCollection/></div>
				<div className='col-12 mb-4'><ImageBanner banner={banner}/></div>
			</Fragment>
		)
	}

	return (
		<MainLayout>
			<Hero handleScroll={()=>handleScroll()} isLoading={isLoading}/>
			<div className='container'>
				<div className='row' ref={ref}>
					{belowFoldContent}
				</div>
			</div>
		</MainLayout>
	)

}

export default Influencer