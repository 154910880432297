import React from 'react';

import OutlineButton from '../../../Buttons/OutlineButton/OutlineButton';
import FeatureP from '../../../TextElements/FeatureP/FeatureP';
import ListItemHeader from '../../../TextElements/ListItemHeader/ListItemHeader';
import ListItemInfoP from '../../../TextElements/ListItemInfoP/ListItemInfoP';

import styles from './BottleListItem.module.css';

import premiumBottle from '../../../../../assets/images/premium.webp';
import luxeBottle from '../../../../../assets/images/luxe.webp';
import privateReserveBottle from '../../../../../assets/images/private-reserve.webp';
import premium750Bottle from '../../../../../assets/images/premium-750.webp';
import luxe750Bottle from '../../../../../assets/images/luxe-750.webp';
import privateReserve750Bottle from '../../../../../assets/images/private-reserve-750.webp';


const BottleListItem = props => {

	let image = <img src={premiumBottle} alt='Premium Bottle' className={styles.Image} />
	if (props.bottle.img === 'luxe') image = <img src={luxeBottle} alt='Luxe Bottle' className={styles.Image} />
	if (props.bottle.img === 'private-reserve') image = <img src={privateReserveBottle} alt='Private Reserve Bottle' className={styles.Image} />
	if (props.bottle.img === 'premium-750') image = <img src={premium750Bottle} alt='Premium Bottle' className={styles.Image} />
	if (props.bottle.img === 'luxe-750') image = <img src={luxe750Bottle} alt='Luxe Bottle' className={styles.Image} />
	if (props.bottle.img === 'private-reserve-750') image = <img src={privateReserve750Bottle} alt='Private Reserve Bottle' className={styles.Image} />

	return(
		<div className={styles.BottleContainer}>
			<div className='mb-4'>{image}</div>
			<ListItemHeader>{props.bottle.title}</ListItemHeader>
			<ListItemInfoP>{props.bottle.bottle}</ListItemInfoP>
			<FeatureP>{props.bottle.description}</FeatureP>
			<OutlineButton passOnClasses='mb-1' clickHandler={props.bottle.clickHandler}>
				{`Add to cart - $${props.bottle.displayPrice}`}
			</OutlineButton>
			{props.bottle.listPrice !== props.bottle.displayPrice && <ListItemInfoP>{`(List price $${props.bottle.listPrice})`}</ListItemInfoP>}
		</div>
	)
}

export default BottleListItem;