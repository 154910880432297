import {useState, useContext} from 'react';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import Logo from '../../UIElements/Logo/Logo';
import CartIcon from '../../UIElements/CartIcon/CartIcon';
import Navbar from '../Navbar/Navbar';

const AcademyNavbar = props => {
	
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	const path = window.location.pathname;

	// Handlers for analytics events
	const navItetemAnalyticsHandler = name => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ON_NAV_ITEM,
    	label: name
    })
	}

	const navItems = [
		{
			id: 0,
			copy: 'Home',
			type: 'link',
			destination: '/academy',
			showTo: 'everyone', 
			active: path === '/academy' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('academy')
		},
		{
			id: 1,
			copy: 'Health Benefits',
			type: 'link',
			destination: '/academy/health-benefits',
			showTo: 'everyone', 
			active: path === '/academy/health-benefits' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('healthBenefits')
		},
		{
			id: 2,
			copy: 'Choosing Olive Oil',
			type: 'link',
			destination: '/academy/choosing-olive-oil',
			showTo: 'everyone',
			active: path === '/academy/choosing-olive-oil' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('choosingOliveOil')
		},
		{
			id: 3,
			copy: 'Recipes',
			type: 'link',
			destination: '/academy/recipes',
			showTo: 'everyone',
			active: path === '/academy/recipes' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('recipes')
		},
		{
			id: 5,
			copy: 'Shop',
			type: 'btn-link',
			destination: '/',
			showTo: 'everyone',
			active: path === '/' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('shop')
		}
	]

	const backgroundStyles = {
		background: `#445D5C`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
	}

	const transparentStyles = {
		position: 'absolute',
		width: '100%'
	}

	const menuStyles = {
		...backgroundStyles,
		backgroundPosition: '-90px 0px'
	}

	const navStyles = props.transparentNavbar ? transparentStyles : backgroundStyles;

	return (
		<section style={navStyles}>
			<nav className='navbar navbar-dark'>
				<div className='container'>
					<div className='d-none d-sm-block'><Logo academy/></div>	
					<div className='d-block d-sm-none'><Logo academy small/></div>				
					<div className='d-flex d-lg-none align-items-center'>	
						<button 
							className='custom-toggler navbar-toggler' 
							type='button' 
							data-toggle='collapse'
							data-target='#navbarExpand' 
							aria-controls='navbarExpand' 
							aria-expanded={!isNavCollapsed ? true : false} 
							aria-label='Toggle navigation' 
							onClick={handleNavCollapse}
						>
							<span className='navbar-toggler-icon'></span>
	      		</button>
	      		<CartIcon />
      		</div>
      		<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} style={menuStyles} id='navbarExpand'>
		        <Navbar navItems={[...navItems]} />
	      	</div>
	      	<div className='d-none d-lg-flex align-items-center'>
	      		<Navbar navItems={[...navItems]} />
	      		<CartIcon shiftUp />
	      	</div>

				</div>
			</nav>
		</section>
	)
}

export default AcademyNavbar;