import {Fragment, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import GuestContext from '../../../store/guest-context';
import AuthContext from '../../../store/auth-context';
import OrdersContext from '../../../store/orders-context';
import AlertContext, {alertMessages} from '../../../store/alert-context';

import Form from '../../UIElements/Form/Form';
import BtnLink from '../../UIElements/Buttons/BtnLink/BtnLink';

import {validateEmail, validatePassword} from '../../../helpers/validation-functions';
import {fireNewAccountEmail} from '../../../helpers/email-helpers';

import useInput from '../../../hooks/use-input';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const CreateAccountForm = props => {

  const navigate = useNavigate();

	const guestCtx = useContext(GuestContext);
  const authCtx = useContext(AuthContext);
  const ordersCtx = useContext(OrdersContext);
  const alertCtx = useContext(AlertContext);

  const email = useInput(validateEmail);
  const password = useInput(validatePassword);

	const fields = [
    {
      fid: 0,
      label: 'Your email',
      id: 'create-email',
      type: 'email',
      errorMessage: 'Please enter a valid email',
      value: guestCtx.email,
      disabled: true,
      isValid: true,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
    },
    {
      fid: 1,
      label: 'Create your password',
      id: 'create-password',
      type: 'password',
      errorMessage: 'Please enter a valid password',
      value: password.value,
      isValid: password.isValid,
      hasError: password.hasError,
      valueChangedHandler: password.valueChangedHandler,
      touchHandler: password.touchHandler,
      reset: ()=>password.reset()
    }
  ];

	const submitHandler = async event => {
    props.setIsAccountLoading(true);
    props.setIsAccountSuccessful(false);

    event.preventDefault();
    fields.map(field=>field.reset());

    ReactGA.event({
      category: eventCategories.AUTHENTICATION, 
      action: eventNames.SUBMITTED_ACCOUNT_CREATION
    });
    
    // 1- Authenticate and create user
    const userId = await authCtx.onRegister({
      firstName: guestCtx.firstName,
      lastName: guestCtx.lastName,
      email: guestCtx.email,
      guestId: guestCtx.guestId,
      stripeCustomerId: guestCtx.stripeCustomerId,
      password: password.value 
    });

    // 2- Update the guest
    const guestUpdated = await guestCtx.updateGuestInfo(guestCtx.guestId, {userId: userId});

    // 3- Update all orders from the guest to include the userId
    const ordersUpdated = await ordersCtx.updateAllOrdersByGuest(guestCtx.guestId, userId)

    // 4- Fire the new account email
    const emailData ={
      email: guestCtx.email,
      fullName: `${guestCtx.firstName} ${guestCtx.lastName}`,
      firstName: guestCtx.firstName  
    }

    const emailToFire = await fireNewAccountEmail(emailData);

    // 5- Set isLoading false and conditionally set isSuccessful and show an alert
    props.setIsAccountLoading(false);
    if (ordersUpdated) {
      props.setIsAccountSuccessful(true);
      if (props.orderConfirmationPage) alertCtx.setAlert('success', alertMessages.MEMBERSHIP_ACTIVATED);
      if (props.createAccountPage) {
        alertCtx.setAlert('success', alertMessages.SIGN_IN_SUCCESS);
        navigate('/account?tab=orders');
      }
    }
  }

  return (
    <Fragment>
      <Form fields={fields} submitHandler={submitHandler} inline>
      	Create account
      </Form>
    </Fragment>
  );
} 

export default CreateAccountForm;