import BaseP from '../../../../components/UIElements/TextElements/BaseP/BaseP';
import LeadP from '../../../../components/UIElements/TextElements/LeadP/LeadP';

const Point = props => {
	return (
		<div className='mb-5'>
			<LeadP bold mbSmall>{props.point.header}</LeadP>
			<BaseP>{props.point.body}</BaseP>
		</div>
	)
}

export default Point;