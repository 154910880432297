import BtnLink from '../../../../components/UIElements/Buttons/BtnLink/BtnLink';
import FeatureP from '../../../../components/UIElements/TextElements/FeatureP/FeatureP';

import amex from '../../../../assets/images/amex.webp';
import discover from '../../../../assets/images/discover.webp';
import mastercard from '../../../../assets/images/mastercard.webp';
import visa from '../../../../assets/images/visa.webp';

const Card = props => {

	let displayBrand = 'American Express';
	let cardImg = <img src={amex} alt={displayBrand} />

	if (props.cardData.card.brand === 'discover') {
		displayBrand = 'Discover';
		cardImg = <img src={discover} alt={displayBrand} />;
	}
	if (props.cardData.card.brand === 'mastercard') {
		displayBrand = 'Mastercard';
		cardImg = <img src={mastercard} alt={displayBrand} />;
	}
	if (props.cardData.card.brand === 'visa') {
		displayBrand = 'Visa';
		cardImg = <img src={visa} alt={displayBrand} />;
	}	

	return(
		<div className='d-md-flex justify-content-between align-items-center'>
			<div className='d-flex align-items-center'>
				{cardImg}
				<div className='ms-2'>
					<FeatureP passOnClasses='m-0'>{`${displayBrand} ending in ${props.cardData.card.last4}`}</FeatureP>
					<FeatureP passOnClasses='m-0'>Expires: 03/27</FeatureP>
				</div>
			</div>
			<BtnLink noWrap clickHandler={()=>props.removeCardHandler(props.cardData.id)}>
				Delete payment method
			</BtnLink>
		</div>
	)
}

export default Card;