import TrueOliveSign from '../../../../assets/images/true-olive-bullet.webp';

import styles from './InnerCard.module.css';

const InnerCard = props => {
	return (
		<div className={styles.InnerCard}>
			<img src={TrueOliveSign} className={styles.Sign} alt='True Olive Mark'/>
			<div>
				<h4 className={styles.Title}>The True Olive Difference</h4>
				<p>{props.difference}</p>
			</div>
		</div>
	)

}

export default InnerCard;