import { createContext, useState, useContext } from 'react';

import BetaContext from './beta-context';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../helpers/analytics-helpers';

const RestaurantAccessContext = createContext({
	restaurantMode: '',
	modalIsShown: '',
	setModalIsShown: boolean => {},
	checkAccess: accessCode => {}
});

export const RestaurantAccessContextProvider = props => {

	const [restaurantMode, setRestaurantMode] = useState(localStorage.getItem('TrueOliveRestaurantAccess') ? true : false); 
	const [modalIsShown, setModalIsShown] = useState(localStorage.getItem('TrueOliveRestaurantAccess') ? false : true);
	const [error, setError] = useState('');

	const betaCtx = useContext(BetaContext);

	const checkAccess = accessCode => {
		if(accessCode === 'topflight'){
    	setModalIsShown(false);
    	setRestaurantMode(true);
    	localStorage.setItem('TrueOliveRestaurantAccess', 'true');
    	betaCtx.giveAccess();
    	ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.RESTAURANT_ACCESS_CODE_SUCCESS
	    })
    } else {
    	setError({message: 'Incorrect access code'});
    	ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.RESTAURANT_ACCESS_CODE_ERROR
	    })

    }
	}

	return (
		<RestaurantAccessContext.Provider value={{
			restaurantMode: restaurantMode,
			modalIsShown: modalIsShown,
			setModalIsShown: setModalIsShown,
			checkAccess: checkAccess
		}}>
			{props.children}
		</RestaurantAccessContext.Provider>
	)
}

export default RestaurantAccessContext;
