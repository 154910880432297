import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import FeatureP from '../../../components/UIElements/TextElements/FeatureP/FeatureP';
import Label from '../../../components/UIElements/TextElements/Label/Label';

const Tags = props => {

	let otherTags = null;
	if (props.otherTags) {
		otherTags = (
			<div className='mb-3'>
				<Label>Other</Label>
				<FeatureP passOnClasses='mb-0'>{props.otherTags}</FeatureP>
			</div>
		)
	}

	return (
		<OptionsWrapper small passOnClasses='mb-3'>
			<div className='mb-3'>
				<Label>Good for as a</Label>
				<FeatureP passOnClasses='mb-0'>{props.goodFor}</FeatureP>
			</div>
			<div className='mb-3'>
				<Label>Ease of preparation</Label>
				<FeatureP passOnClasses='mb-0'>{props.ease}</FeatureP>
			</div>
			<div className='mb-3'>
				<Label>Olive oils used</Label>
				<FeatureP passOnClasses='mb-0'>{props.oliveOils}</FeatureP>
			</div>
			{otherTags}
		</OptionsWrapper>
	)
}

export default Tags;