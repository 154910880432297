import { createContext, useState } from 'react';
import {postData} from '../helpers/http-helpers';

const PromotionCodeContext = createContext({
	softLaunchCode: '',
	showSoftLaunchModal: '',
	couponId: '',
	name: '',
	amountOff: '',
	percentOff: '',
	isLoading: '',
	error: '',
	getPromotionData: codeName => {}
});

export const PromotionCodeContextProvider = props => {
	const [softLaunchCode, setSoftLaunchCode] = useState('');
	const [showSoftLaunchModal, setShowSoftLaunchModal] = useState(false);
	const [couponId, setCouponId] = useState('');
	const [name, setName] = useState('');
	const [amountOff, setAmountOff] = useState(''); 
	const [percentOff, setPercentOff] = useState(''); 
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
	};

	const setPromotionCode = code => {
		if (code.active && code.coupon.valid) {
			setCouponId(code.coupon.id);
			setName(code.code);
			if(code.coupon.amount_off) setAmountOff(code.coupon.amount_off/100);
			if(code.coupon.percent_off) setPercentOff(code.coupon.percent_off);
		}
	}

	const getPromotionData = async codeName => {
		resetStates();
		setIsLoading(true);

		try {
	  	const response = await postData(
	  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}getPromotionCodeData`,
	  		{codeName: codeName}
	    );
	    if(!response){
				throw ({message: 'Something went wrong. Please try again'});
			}
			setIsLoading(false);
			setPromotionCode(response.data.promotionCodeData);
			return response.data.promotionCodeData;
	  } catch (error) {
	  	setIsLoading(false);
	  	setError(error);
	  	console.log(error)
	  	return;
	  }
	}

	return (
		<PromotionCodeContext.Provider value={{
			softLaunchCode: softLaunchCode,
			showSoftLaunchModal: showSoftLaunchModal,
			setShowSoftLaunchModal: setShowSoftLaunchModal,
			couponId: couponId,
			name: name,
			amountOff: amountOff,
			percentOff: percentOff,
			isLoading: isLoading,
			error: error,
			getPromotionData: getPromotionData
		}}>
			{props.children}
		</PromotionCodeContext.Provider>
	)

}

export default PromotionCodeContext;

