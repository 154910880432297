import styles from './LeadP.module.css';

const LeadP = props => {

	const boldStyles = props.bold ? styles.Bold : '';
	const marginBottomSmallStyles = props.mbSmall ? styles.MbSmall : ''; 

	return <p className={`${props.passOnClasses} ${styles.LeadP} ${boldStyles} ${marginBottomSmallStyles}`}>{props.children}</p>
}

export default LeadP;