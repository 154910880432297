import {useState, useEffect, useCallback, Fragment, useContext} from 'react';

import UserContext from '../../store/user-context';
import OrdersContext from '../../store/orders-context';
import AuthContext from '../../store/auth-context';
import AlertContext, {alertMessages} from '../../store/alert-context'

import {postData} from '../../helpers/http-helpers';
import {fireCancelledMembershipEmail} from '../../helpers/email-helpers';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import SideNav from '../../components/Navs/SideNav/SideNav';
import AccountInfo from './AccountInfo/AccountInfo';
import Memberships from './Memberships/Memberships';
import ShippingInfo from './ShippingInfo/ShippingInfo';
import Cards from './Cards/Cards';
import Orders from './Orders/Orders';
import Loader from '../../components/UIElements/Loader/Loader';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const Account = props => {

	const userCtx = useContext(UserContext);
	const ordersCtx = useContext(OrdersContext);
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);

	const sideNavItems = [
		{
			id: 0,
			name: 'Account Information',
			active: true,
		},
		{
			id: 1,
			name: 'Shipping Information',
			active: false,
		},
		{
			id: 2,
			name: 'Payment Methods',
			active: false,	
		},
		{
			id: 3,
			name: 'Orders',
			active: false,
		},
		{
			id: 4,
			name: 'Memberships',
			active: false,
		}
	]

	
	const queryParams = new Proxy(new URLSearchParams(window.location.search), {
	  get: (searchParams, prop) => searchParams.get(prop),
	});
	// DATA STATES
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState(null);
	const [customerError, setCustomerError] = useState(null);

	const [memberships, setMemberships] = useState([]);
	const [shippingInfo, setShippingInfo] = useState({});
	const [cards, setCards] = useState([]);

	// HIDE/SHOW STATES
	const [tabs, setTabs] = useState(sideNavItems);
	const [showTab, setShowTab] = useState(0);

	// DISPLAY CONTROLS
  const tabClickHandler = index => {
  	setShowTab(index);
  	const updatedSideNavItems = [...sideNavItems];
  	updatedSideNavItems.map(item=>item.active = false);
  	updatedSideNavItems[index].active = true;
  	setTabs(updatedSideNavItems);
  }
	

	// GET STRIPE AND FB DATA 
	const fetchStripeData = useCallback(async stripeCustomerId => {

		const url = `${process.env.REACT_APP_FUNCTIONS_BASE_URL}getCustomerInfo?customerId=${stripeCustomerId}`;

		const response = await fetch(url,{
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const resdata = await response.json();
    const jsData = await JSON.parse(resdata)
		
		return jsData;
	},[]);

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		
		const	user = await userCtx.getUserInfo(authCtx.localId);
		if (user) {
			const orders = await ordersCtx.getOrdersByUser(authCtx.localId);
			const stripeData = await fetchStripeData(user.stripeCustomerId); 
			
			const membershipList = stripeData.memberships;
			const shipInfo = stripeData.shippingInfo;
			const cards = stripeData.cards;
			
			setMemberships(membershipList);
			setShippingInfo(shipInfo);
			setCards(cards);
 
			setIsLoading(false);
			setIsSuccessful(true);
		}
	},[])

  //FETCH DATA WHEN PAGE LOADS & ADJUST TAB
  useEffect(() => {

  	if (authCtx.localId && !isLoading) {
  		fetchData();
  	}
    if (queryParams.tab){
    	if (queryParams.tab === 'account-info') tabClickHandler(0);
    	if (queryParams.tab === 'shipping-info') tabClickHandler(1);
    	if (queryParams.tab === 'payment-methods') tabClickHandler(2);
    	if (queryParams.tab === 'orders') tabClickHandler(3);
    	if (queryParams.tab === 'memberships') tabClickHandler(4);
    };
  }, [fetchData]);


  // OPTIONS
  const cancelMembershipHandler = async (subscriptionId, itemId, productName) => {

  	setCustomerError(null);
		setIsLoading(true);

		ReactGA.event({
    	category: eventCategories.REVENUE, 
    	action: eventNames.CLICKED_CANCEL_MEMBERSHIP,
    	label: productName
    });

	  try {
	  	// 1- Cancel the membership
	  	const response = await postData(
	  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}cancelSubscription`,
	  		{subscriptionId: subscriptionId, itemId: itemId}
	    );

	    if(!response.ok){
				throw ({message: response.error});
			}

			ReactGA.event({
	    	category: eventCategories.REVENUE, 
	    	action: eventNames.CANCEL_MEMBERSHIP_SUCCESS,
	    	label: productName
	    });

			// 2- Adjust display
	    const editedMemberships = [...memberships];
	  	const index = editedMemberships.findIndex(i => i.subscriptionItemId === itemId);
	  	editedMemberships.splice(index,1);
	  	setMemberships(editedMemberships);
	  	
			// 3- Show alert
	  	alertCtx.setAlert('success', alertMessages.MEMBERSHIP_CANCELLED);

	  	// 4- Send email
	  	const emailData = {
	  		email: userCtx.email,
	      fullName: `${userCtx.firstName} ${userCtx.lastName}`,
	      firstName: userCtx.firstName  
	  	}

	  	const emailToFire = await fireCancelledMembershipEmail(emailData);

	  } catch (error) {
	  	ReactGA.event({
	    	category: eventCategories.REVENUE, 
	    	action: eventNames.CANCEL_MEMBERSHIP_ERROR,
	    	label: productName
	    });

	  	setCustomerError(error.message);
	  }
	  setIsLoading(false);
  }

  const removeCardHandler = async id => {
  	setCustomerError(null);
		setIsLoading(true);

		ReactGA.event({
    	category: eventCategories.REVENUE, 
    	action: eventNames.CLICKED_REMOVE_PAYMENT_METHOD,
    });

	  try {
	  	const response = await postData(
	  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}deletePaymentMethod`,
	  		{id: id}      
	    );

	    if(!response.ok){
				throw ({message: response.error});
			}

			ReactGA.event({
	    	category: eventCategories.REVENUE, 
	    	action: eventNames.REMOVE_PAYMENT_METHOD_SUCCESS,
	    });

	    const editedCards = [...cards];
	  	const index = editedCards.findIndex(i => i.id === id);
	  	editedCards.splice(index,1);
	  	setCards(editedCards);
	  	alertCtx.setAlert('success', alertMessages.REMOVED_PAYMENT_METHOD);
	  } catch (error) {

	  	ReactGA.event({
	    	category: eventCategories.REVENUE, 
	    	action: eventNames.REMOVE_PAYMENT_METHOD_ERROR,
	    });

	  	setCustomerError(error.message);
	  }
	  setIsLoading(false);
  }

  // CONTENT 
  let accountContent = null;
  if (showTab === 0) accountContent = <AccountInfo />;

  let shippingContent = null;
  if (showTab === 1) shippingContent = (
  	<ShippingInfo 
  		info={shippingInfo} 
  		isLoading={isLoading}
  		error={customerError}
  		setShippingInfo={setShippingInfo}
  	/>
  );

  let paymentContent = null;
	if (showTab === 2) paymentContent = (
		<Cards 
			list={cards} 
			isLoading={isLoading}
  		error={customerError}
			removeCardHandler={removeCardHandler} 
		/>
	);

	let orderContent = null;
  if (showTab === 3) orderContent = <Orders orders={ordersCtx.orders}/>

  let membershipContent = null;
  if (showTab === 4) membershipContent = (
  	<Memberships 
  		list={memberships} 
  		isLoading={isLoading}
  		error={customerError}
  		cancelMembershipHandler={cancelMembershipHandler}
  	/>
  );

  let mainContent = <Loader>Loading your account information</Loader>
	if (isSuccessful) {
		mainContent = (
			<Fragment>
				{accountContent}					
				{shippingContent}
				{paymentContent}
				{orderContent}
				{membershipContent}
			</Fragment>
		)
	}


	return(
		<MainLayout>
			<div className='container py-5'>
				<div className='row'>
					<div className='col-md-3'>
						<SideNav items={tabs} clickHandler={tabClickHandler}/>
					</div>
					<div className='col-md-8'>
						{mainContent}
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default Account;
