import ListItemInfoP from '../../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import FeatureP from '../../../../../components/UIElements/TextElements/FeatureP/FeatureP';

import styles from './TaxItem.module.css';

const TaxItem = props => {
	return (

		<div className={styles.TaxItem}>
			<FeatureP passOnClasses='mb-0'>Tax</FeatureP>
			<div className={styles.PriceWrapper}>
				<FeatureP passOnClasses='mb-0 ms-1 text-end'>{`$${props.taxAmount}`}</FeatureP>
			</div>
		</div>
	);
}

export default TaxItem;