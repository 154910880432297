import{ Fragment, useContext } from 'react';

import CartContext from '../../../store/cart-context';

// import ReactGA from 'react-ga4';
// import {eventNames, eventCategories} from '../../../helpers/AnalyticsHelpers';

import PlanList from './PlanList/PlanList';

import SectionHeader from '../TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../TextElements/HeaderTagline/HeaderTagline';

const Plans = props => {

	const cartCtx = useContext(CartContext);

	const plansArray = [
		{
			id: 0,
			invitationOnly: false,
			title: 'Everyday Luxury',
			price: `$${cartCtx.getItemDiscountedPrice('everydayLuxury')}/m`,
			features: [
				'Guaranteed monthly shipment of one Premium & one Luxe',
				'One complimentary Luxe bottle each year',
				'Versatile: sauté, grill, fry, bake, marinate, dress'
			],
			CTA:'Join today',
			clickHandler: ()=>cartCtx.addItem('everydayLuxury')
		},
		{
			id: 1,
			invitationOnly: false,
			title: 'Refined Palate',
			price: `$${cartCtx.getItemDiscountedPrice('refinedPalate')}/m`,
			features: [
				'Guaranteed monthly shipment of one Premium & one Private Reserve',
				'One complimentary Private Reserve each per year',
				'Versatility coupled with finer refinement for finishing'
			],
			CTA:'Join today',
			clickHandler: ()=>cartCtx.addItem('refinedPalate')
		},
		{
			id: 2,
			invitationOnly: false,
			title: 'True Connoisseur',
			price: `$${cartCtx.getItemDiscountedPrice('trueConnoisseur')}/m`,
			features: [
				'Guaranteed monthly shipment of one Luxe & one Private Reserve',
				'Two complimentary Private Reserve bottles per year',
				'For those who appreciate the finer tastes in life'
			],
			CTA:'Join today',
			clickHandler: ()=>cartCtx.addItem('trueConnoisseur')
		}
	];

	return (
		<Fragment>
			<div className='col-xl-10 py-5 mb-2'>
				<SectionHeader passOnClasses='mb-2'><em>Become a member, guarantee your supply</em></SectionHeader>
				<HeaderTagline>
					As a single estate producer, quantities are limited to what our farm can produce. To ensure 
					those who appreciate our olive oil can always have access, we offer memberships which guarantee your shipment 
					and comes with additional benefits. 
				</HeaderTagline>
			</div>
			<div className='col-12 mb-5'>
				<PlanList plansList={plansArray} />
			</div>
		</Fragment>
	)

}

export default Plans;