import {useState} from 'react';

const useInput = (validateValue, initialValue) => { 
	const [enteredValue, setEnteredValue] = useState(initialValue ? initialValue : '');
	const [isTouched, setIsTouched] = useState(false);
	const [isBlured, setIsBlured] = useState(false);

	const valueIsValid = validateValue(enteredValue);
	const hasError = !valueIsValid && isTouched && isBlured; 

	const valueChangedHandler = event => {
		setEnteredValue(event.target.value);
		if (event.target.value.length > 0) {setIsTouched(true)}
		else {setIsTouched(false)}
	}

	const touchHandler = boolean => {
		setIsBlured(boolean);
	}

	const reset = () => {
		setIsTouched(false);
		setEnteredValue('');
	}

	return {
		value: enteredValue,
		isValid: valueIsValid,
		hasError,
		valueChangedHandler,
		touchHandler,
		reset
	}
}

export default useInput;