import styles from './DropDown.module.css';
import ListItemHeader from '../../../UIElements/TextElements/ListItemHeader/ListItemHeader';

const DropDown = props => {

	const setDisplayName = items => {
		let displayName = 'Account information';
		items.map(item=>{if(item.active) displayName=item.name})
		return displayName;
	}

	const dropDownItems = props.items.map(item=>{
		return (
			<li key={item.id}>
				<div className='dropdown-item' onClick={()=>props.clickHandler(item.id)}>{item.name}</div>
			</li>
		)
	})

	return (
		<li className={`nav-item dropdown w-100 d-md-none ${styles.DropDown}`}>
			<a 
				className={`nav-link dropdown-toggle ${styles.DisplayName}`} 
				data-bs-toggle='dropdown' 
				href='#' role='button' aria-expanded='false"'
			>
				<ListItemHeader passOnClasses='d-inline-block'>{setDisplayName(props.items)}</ListItemHeader>
			</a>
			<ul className='dropdown-menu w-100'>
				{dropDownItems}
			</ul>
		</li>
	)

}

export default DropDown;