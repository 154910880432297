import {Fragment, useContext} from 'react';
import PromotionCodeContext from '../../../store/promotion-code-context';

import BottleList from './BottleList/BottleList';
import PromoBanner from '../PromoBanner/PromoBanner';

import SectionHeader from '../TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../TextElements/HeaderTagline/HeaderTagline';

const Bottles = props => {

	const promoCodeCtx = useContext(PromotionCodeContext);

	// Default Copy
	let headerCopy = 'With love from the Dardanelles';
	let taglineCopy = `True Olive olive oils are produced on our 400 acre family farm nestled in the Dardanelles 
					countryside, near the ancient city of Troy. A decade ago, a fire destroyed acres of olive 
					and redwood trees. Over time, the burnt trees decomposed, ultimately enriching and changing 
					the soil. Five years later, the original olive and redwood trees regenerated, and 
					eventually bore fruit with a distinctive, incomparable flavor.`;

	//Custom Copy
	if (props.header) headerCopy = props.header;
	if (props.tagline) taglineCopy = props.tagline;				

	return (
		<Fragment>
			<div className='col-xl-10 py-5 mb-2'>
				<SectionHeader passOnClasses='mb-2'><em>{headerCopy}</em></SectionHeader>
				<HeaderTagline>{taglineCopy}</HeaderTagline>
			</div>
			<div className='col-12 text-center'>
				{!props.noDiscount && promoCodeCtx.couponId && <PromoBanner />}
			</div>
			<div className='col-12 mb-5'>
				<BottleList bottleList={props.bottlesArray} />
			</div>
		</Fragment>
	)
}

export default Bottles;