import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';
import founders from '../../../assets/images/founders.webp';

import styles from './GoodHands.module.css';

const GoodHands = () => {

	return (
		<div className='col-xl-10 py-5 mb-2 d-md-flex'>
			<div className='text-center me-md-4'>
				<img src={founders} alt='Nicole and Can' className={styles.Image}/>
			</div>
			<div>
				<SectionHeader noCapitalization passOnClasses='mb-2 text-center text-md-start'>
					You are in good hands
				</SectionHeader>
				<HeaderTagline passOnClasses='text-center text-md-start mb-4'>
					Meet Nicole and Can. They know good olive oil. Nicole is a certified olive oil sommelier 
					and Can is a third generation member of an olive oil producing family. Together they started 
					True Olive, bringing the family’s gold-medal winning olive oils from the Dardanelles 
					to the United States.
				</HeaderTagline>
				<HeaderTagline passOnClasses='text-center text-md-start'>
					People who tried olive oils by True Olive immediately fell in love with the superior 
					quality and taste. They kept asking Can and Nicole for different ways they could use the olive 
					oil in their cooking. Can and Nicole started True Olive Academy to help pass on their olive oil 
					knowledge and recipes to all whose interested.
				</HeaderTagline>
			</div>
		</div>
	)

}

export default GoodHands;