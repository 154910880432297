import {useContext} from 'react';

import CartContext from '../../../store/cart-context';
import PromotionCodeContext from '../../../store/promotion-code-context';

import ListItemHeader from '../TextElements/ListItemHeader/ListItemHeader';
import BaseP from '../TextElements/BaseP/BaseP';
import OutlineButton from '../Buttons/OutlineButton/OutlineButton';
import ListItemInfoP from '../TextElements/ListItemInfoP/ListItemInfoP';


import tastingCollectionImg from '../../../assets/images/tasting-collection.webp';

import styles from './TastingCollection.module.css';

const TastingCollection = props => {

	const cartCtx = useContext(CartContext);
	const promoCodeCtx = useContext(PromotionCodeContext);

	let title = 'Try our tasting collection';
	let body = `Get a bottle of all three of our olive oils and taste what's true. Makes a great gift
	for those serious about their food.`

	if (props.subscription){
		title = 'Try our tasting collection before becoming a member';
		body = `Get a bottle of all three of our olive oils and taste what's true. Try them and 
		chose the membership plan that is right for you.`;
	}

	const listPrice = cartCtx.getItemListPrice('tastingCollection');  
	const displayPrice=  cartCtx.getItemDiscountedPrice('tastingCollection', promoCodeCtx.percentOff);

	return (
		<div className={styles.TastingCollection}>
			<div className='me-md-5 mb-4 mb-md-0 text-center text-md-start'>
				<ListItemHeader passOnClasses='mb-2' large>{title}</ListItemHeader>
				<BaseP>{body}</BaseP>
				<OutlineButton clickHandler={()=>cartCtx.addItem('tastingCollection')}>
					{`Try our tasting collection - $${displayPrice}`}
				</OutlineButton>
				{listPrice !== displayPrice && <ListItemInfoP passOnClasses='ps-2'>{`(List price $${listPrice})`}</ListItemInfoP>}
			</div>
			<div className='text-center'>
				<img src={tastingCollectionImg} alt='Tasting collection' className={styles.Img} />
			</div>
		</div>
	)
}

export default TastingCollection;