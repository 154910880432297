export const validateEmail = value => {
  const validatedEmail = String(value.trim())
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return validatedEmail ? true : false;
};

export const validateName = value => {
  if (value.trim().length > 2) return true;
  return false;
}

export const validatePassword = value => {
  if (value.trim().length >= 6) return true;
  return false;
}

export const validatePhoneNumber = value => {
  const validatedPhoneNumber = String(value.trim())
    .toLowerCase()
    .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  return validatedPhoneNumber ? true : false;  
}

export const validateAddress = value => {
  if (value.trim().length > 4) return true;
  return false;
}


export const validateStateAbb = value => {
  const theState = value.trim();
  if   (  theState === "AL" || theState === "AK" || theState === "AZ" || 
    theState === "AR" || theState === "CA" || theState === "CO" || theState === "CT" || 
    theState === "DE" || theState ===  "FL" || theState === "GA" || theState === "HI" || 
    theState === "ID" || theState === "IL" || theState === "IN" || theState === "IA" || 
    theState === "KS" || theState === "KY" || theState === "LA" || theState ===  "ME" || 
    theState === "MD" || theState === "MA" || theState === "MI" || theState === "MN" || 
    theState === "MS" || theState === "MO" || theState === "MT" || theState === "NE" || 
    theState === "NV" || theState === "NH" || theState === "NJ" || theState === "NM" || 
    theState === "NY" || theState === "NC" || theState === "ND" || theState === "OH" || 
    theState === "OK" || theState === "OR" || theState === "PA" || theState === "RI" || 
    theState === "SC" || theState === "SD" || theState === "TN" || theState === "TX" || 
    theState === "UT" || theState === "VT" || theState === "VA" || theState === "WA" || 
    theState === "WV" || theState === "WI" || theState === "WY" || theState === "AS" || 
    theState === "DC" || theState === "FM" || theState === "GU" || theState === "MH" || 
    theState === "MP" || theState === "PW" || theState === "PR" || theState === "VI" )
  {
    return true;
  }
  return false;
}

export const validateZipCode = value => {
  const validatedZipCode = String(value.trim())
    .toLowerCase()
    .match(/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/);
  return validatedZipCode ? true : false;  
}

export const validateAccessCode = value => {
  if (value.trim().length >= 6) return true;
  return false;
}


