import {useState, useEffect, useContext, useCallback, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

import AuthContext from '../../store/auth-context'; 
import CartContext from '../../store/cart-context';
import GuestContext from '../../store/guest-context';
import UserContext from '../../store/user-context';
import OrdersContext from '../../store/orders-context';
import PromotionCodeContext from '../../store/promotion-code-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Loader from '../../components/UIElements/Loader/Loader';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import BaseP from '../../components/UIElements/TextElements/BaseP/BaseP';
import AccountCreation from './AccountCreation/AccountCreation';
import AccountSignIn from './AccountSignIn/AccountSignIn';
import ShareOptions from './ShareOptions/ShareOptions';

import {postData} from '../../helpers/http-helpers';
import {fireOrderEmail} from '../../helpers/email-helpers';
import {splitName} from '../../helpers/misc-helpers';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

import styles from './OrderConfirmation.module.css';

const OrderConfirmation = props => {

	const navigate = useNavigate();

	const guestCtx = useContext(GuestContext);
	const cartCtx = useContext(CartContext);
	const authCtx = useContext(AuthContext);
	const userCtx = useContext(UserContext);
	const ordersCtx = useContext(OrdersContext);
	const promoCodeCtx = useContext(PromotionCodeContext);
	
	const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
	const [isSessionLoading, setIsSessionLoading] = useState(true);
	const [isAccountLoading, setIsAccountLoading] = useState(false);
	const [isAccountSuccessful, setIsAccountSuccessful] = useState(false);
	
	const [hasMembership, setHasMembership] = useState(false);
	const [userButNotSignedIn, setUserButNotSignedIn] = useState('');

	const urlParams = new Proxy(new URLSearchParams(window.location.search), {
	  get: (searchParams, prop) => searchParams.get(prop),
	});
	const sessionId = urlParams.sessionId;
	const promoCodeParam = urlParams.promoCode;

	// NAVIGATE AWAY IF NO SESSIONID
	useEffect(()=>{if (!sessionId) navigate('/')},[])

	const hydrateItems = (items, discountPercent) => {
		const lineItems = [];
		Object.keys(items).map(key=>{
			if (key === 'everydayLuxury' || key === 'refinedPalate' || key === 'trueConnoisseur'){
				if( items[key] > 0) setHasMembership(true);
			}
			if (items[key]) {
				lineItems.push({
					itemName: key,
					quantity: items[key],
					itemListPrice: cartCtx.getItemListPrice(key),
					itemPrice: cartCtx.getItemDiscountedPrice(key, discountPercent),
					total: items[key] * cartCtx.getItemDiscountedPrice(key, discountPercent)
				})
			}
		});
		return lineItems;
	}

	const hydrateItemsForEmail = (items, discountPercent) => {
		const lineItems = [];
		Object.keys(items).map(key=>{
			if (items[key]) {
				let discounted = false;
				if (cartCtx.getItemListPrice(key) > cartCtx.getItemDiscountedPrice(key, discountPercent)) {
					discounted = true;
				}
				
				lineItems.push({
					"imageUrl": `${cartCtx.getItemImage(key)}`,
					"imageAlt": 'True Olive Bottle(s)',
					"name": `${cartCtx.getItemName(key)}`,
					"listPrice": `${cartCtx.getItemListPriceAsString(key)}`,
					"unitPrice": `${cartCtx.getItemDiscountedPriceAsString(key, discountPercent)}`,
					"discounted": discounted,
					"quantity": `${items[key]}`,
					"totalPrice": `${cartCtx.getItemTotalPrice(key, items[key], discountPercent)}`
				});
			}
		});
		return lineItems;
	}

	const adjustStripePrice = sessionPrice => {
		let price = sessionPrice/100;
		if (price % 1 !== 0) price = price.toFixed(2);
		return price;
	}

	const fetchSessionInfo = async sessionId => {
		setIsSessionLoading(true);
		try {
	  	const response = await postData(
	  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}getSessionInfo`,
	  		{sessionId: sessionId}
	    );
	    if(!response){
				throw ({message: 'Something went wrong. Please try again'});
			}
			setIsSessionLoading(false);
			return response.data.sessionInfo;
	  } catch (error) {
	  	setIsSessionLoading(false);
	  	return(error);
	  }
	}

	const handleNewPurchase = useCallback(async sessionId => {
		setIsPurchaseLoading(true);

		if (cartCtx.sumBottles() === 0) navigate('/');

		let promotionCode = null;
		let discountPercent = 0;

		// 0 - Get promocode info if need be
		if (promoCodeParam && !promoCodeCtx.couponId) {
			const promotionCodeData = await promoCodeCtx.getPromotionData(promoCodeParam);
			if (promotionCodeData && cartCtx.hasDiscountElligibleItem()) {
				promotionCode = { 
					promotionCode: {
						couponId: promotionCodeData.coupon.id,
						codeName: promotionCodeData.code,
						amountOff: promotionCodeData.coupon.amount_off ? adjustStripePrice(promotionCodeData.coupon.amount_off) : 0,
						percentOff: promotionCodeData.coupon.percent_off ? promotionCodeData.coupon.percent_off : 0
					}
				}

				if (promotionCodeData.coupon.percent_off) discountPercent = promotionCode.promotionCode.percentOff;
			}
		}
	
		const items = {...cartCtx.quantities};
		const lineItems= hydrateItems(items, discountPercent);
		const lineItemsForEmail = hydrateItemsForEmail(items, discountPercent);
		const hasFreeShipping = cartCtx.hasFreeShipping();
		
		ReactGA.event({
    	category: eventCategories.REVENUE, 
    	action: eventNames.COMPLETED_PURCHASE,
    })

    if (hasMembership) {
    	ReactGA.event({
	    	category: eventCategories.REVENUE, 
	    	action: eventNames.CREATED_MEMBERSHIP,
	    })
    }

		cartCtx.resetCart();

		let orderToWrite = {}; 
		let orders = [];
		let emailData = {};
		
		// 1- Get Session Info
		const sessionInfo = await fetchSessionInfo(sessionId); 

		// 2- Check if the user is signed in, if so create an orderToWrite with the userId, fire the 
		if (authCtx.isLoggedIn) {
			orderToWrite = {
				userId: authCtx.localId,
				firstName: splitName(sessionInfo.customer_details.name).firstName,
				lastName: splitName(sessionInfo.customer_details.name).lastName,
				email: sessionInfo.customer_details.email,
				address: sessionInfo.shipping.address,
				itemsPurchased: lineItems,
				shippingPrice: adjustStripePrice(sessionInfo.total_details.amount_shipping),
				tax: adjustStripePrice(sessionInfo.total_details.amount_tax),
				discountAmount: adjustStripePrice(sessionInfo.total_details.amount_discount),
				totalPrice: adjustStripePrice(sessionInfo.amount_total),
				...promotionCode,
				status: 'new',
				createdAt: new Date()
			};

		// 3- Check if a user exists with the email from the session, if so write the order with that userId
		} else {
			const userId = await userCtx.getUserInfoByEmail(sessionInfo.customer_details.email);
			if (userId) {
				orderToWrite = {
					userId: userId,
					firstName: splitName(sessionInfo.customer_details.name).firstName,
					lastName: splitName(sessionInfo.customer_details.name).lastName,
					email: sessionInfo.customer_details.email,
					address: sessionInfo.shipping.address,
					itemsPurchased: lineItems,
					shippingPrice: adjustStripePrice(sessionInfo.total_details.amount_shipping),
					tax: adjustStripePrice(sessionInfo.total_details.amount_tax),
					discountAmount: adjustStripePrice(sessionInfo.total_details.amount_discount),
					totalPrice: adjustStripePrice(sessionInfo.amount_total),
					...promotionCode,
					status: 'new',
					createdAt: new Date()
				}
				setUserButNotSignedIn(sessionInfo.customer_details.email);

			// 4a - Create or fetch a guest user, associate the order with them
			} else {
				const guest = await guestCtx.createOrFetchGuest({
					firstName: splitName(sessionInfo.customer_details.name).firstName,
					lastName: splitName(sessionInfo.customer_details.name).lastName,
					email: sessionInfo.customer_details.email,
					address: sessionInfo.shipping.address,
					stripeCustomerId: sessionInfo.customer,
					createdAt: new Date()
				});

				orderToWrite = {
					guestId: guest.guestId,
					firstName: guest.firstName,
					lastName: guest.lastName,
					email: guest.email,
					address: sessionInfo.shipping.address,
					itemsPurchased: lineItems,
					shippingPrice: adjustStripePrice(sessionInfo.total_details.amount_shipping),
					tax: adjustStripePrice(sessionInfo.total_details.amount_tax),
					discountAmount: adjustStripePrice(sessionInfo.total_details.amount_discount),
					totalPrice: adjustStripePrice(sessionInfo.amount_total),
					...promotionCode,
					status: 'new',
					createdAt: new Date()
				}

			}
		}

		// 5- Now create your order
		const order = await ordersCtx.createOrder(orderToWrite); 

		// 6- Get all orders for guest or user to customize messaging
		let allOrders = null;
		if ('userId' in orderToWrite) {
			allOrders = await ordersCtx.getOrdersByUser(orderToWrite.userId);
		} else {
			allOrders = await ordersCtx.getOrdersByGuest(orderToWrite.guestId);
		}

		// 7- Fire the new order email 
		let dollarDiscountData = null;
		if (promotionCode) {
			if (promotionCode.promotionCode.amountOff) {
				dollarDiscountData = {
					hasDollarDiscount: true,
					dollarDiscount: promotionCode.promotionCode.amountOff
				}
			}
		}

		emailData ={
			email: orderToWrite.email,
			fullName: `${orderToWrite.firstName} ${orderToWrite.lastName}`,
			totalPrice: `$${orderToWrite.totalPrice}`, 
			items: lineItemsForEmail,
			tax: adjustStripePrice(sessionInfo.total_details.amount_tax),
			hasTax: (sessionInfo.total_details.amount_tax) > 0 ? true : false,
			...dollarDiscountData,
			shippingPrice: adjustStripePrice(sessionInfo.total_details.amount_shipping),
			hasFreeShipping: hasFreeShipping
		}
		const userIdForEmail = orderToWrite.userId ? {userId: orderToWrite.userId} : null;
		const guestIdForEmail = orderToWrite.guestId ? {guestId: orderToWrite.guestId} : null;

		emailData = {...emailData, ...userIdForEmail, ...guestIdForEmail}
		
		const emailToFire = fireOrderEmail(emailData);

		//8- Reset loading state
		setIsPurchaseLoading(false); 
		
	},[])

	// HANDLE THE NEW PURCHASE
	useEffect(()=>{
		if (cartCtx.sumBottles() === 0) navigate('/');
		if (!isPurchaseLoading && sessionId && cartCtx.sumBottles()>0) handleNewPurchase(sessionId);
	},[sessionId, handleNewPurchase]);


	// DISPLAY LOGIC
	let ctaArea = null;

	//If the user is not logged in and does NOT have memberships in the order, show share options
	if(!authCtx.isLoggedIn && !hasMembership) {
		ctaArea = <ShareOptions /> 
	} 

	//If the user is not logged in and does have memberships in the order, show create an account
	if(!authCtx.isLoggedIn && hasMembership) {
		ctaArea = (
			<AccountCreation 
				isAccountLoading={isAccountLoading} 
				setIsAccountLoading={setIsAccountLoading}
				isAccountSuccessful={isAccountSuccessful}
				setIsAccountSuccessful={setIsAccountSuccessful}
			/>
		);
		// But if they already have an account show sign in instead
		if(userButNotSignedIn) {
			ctaArea = ( 
				<AccountSignIn
					isAccountLoading={isAccountLoading} 
					setIsAccountLoading={setIsAccountLoading}
					isAccountSuccessful={isAccountSuccessful}
					setIsAccountSuccessful={setIsAccountSuccessful}
					email={userButNotSignedIn}
				/>
			)
		}
	} 

	// If the user is authenticated and account creation has finished showed (or never started) Show share options
	if (authCtx.isLoggedIn && !isAccountLoading) {
		ctaArea = <ShareOptions /> 
	}

	//If the purchase is progressing show a loader
	let confirmationArea = (
		<Fragment>
			<SectionHeader passOnClasses='mb-2'>Thank you for your order</SectionHeader>
			<BaseP>
				We will process your order and ship it within 1 business day. Check your email for 
				additional information.
			</BaseP>
			{ctaArea}
		</Fragment>
	)


	if (isPurchaseLoading) {
		confirmationArea = (
			<Fragment>
				<SectionHeader passOnClasses='mb-2'>Your order is being finalized</SectionHeader>
				<Loader>Processing order...</Loader> 
			</Fragment>
		);
	}


	return (
		<MainLayout>
			<div className='container'>
				<div className='row py-5'>
					<div className='py-5 col-md-10 offset-md-1 col-xl-8 offset-xl-2'>
						{confirmationArea}
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default OrderConfirmation;