import {Fragment} from 'react'; 

import BottleListItem from './BottleListItem/BottleListItem';
import AdditionalInfoP from '../../TextElements/AdditionalInfoP/AdditionalInfoP';

import styles from './BottleList.module.css'

const BottleList = props => {
	
	const list = props.bottleList.map(bottle=>{
		return <BottleListItem bottle={bottle} key={bottle.id} />
	});

	return (
		<Fragment>
			<div className='d-md-flex justify-content-between pt-4'>
				{list}
			</div>
			<AdditionalInfoP>
				Free shipping on orders with 2 or more bottles. For bulk orders 
				contact <a className={styles.EmailLink} href='mailto:nicole@trueolive.co'>nicole@trueolive.co</a>
			</AdditionalInfoP>
		</Fragment>
	)
}

export default BottleList