import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import HorizontalCard from '../../components/UIElements/HorizontalCard/HorizontalCard';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const ChoosingOliveOil = () => {

	const bottomCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BOTTOM_CTA,
    	label: 'shop'
    });
	}

	const points = [
		{
			id: 0,
			image: 'extra-virgin.webp',
			altText: 'Extra virgin olive oil',
			title: 'Stating the obvious: It has to be extra-virgin olive oil',
			body: 'Extra-virgin olive oil is extracted solely by crushing, or pressing the olives, without the use of chemicals or excessive heat. Furthermore, EVOO must have an acidity less than 0.8%.  This  leads to a better tasting olive oil full with nutrients.',
			trueOliveDifference: 'Needless to say, at True Olive our olive oils are extra virgin with acidity levels much lower than the 0.8% required giving them their delicate tastes.' 
		},
		{
			id: 1,
			image: 'fresh-olive-oil.webp',
			altText: 'Fresh olive oil is key',
			title: 'Fresh olive oil is key',
			body: '18-24 months after being pressed, olive oil starts to loose its taste and health benefits. If possible check when the olive oil you want to purchase has been pressed. For most store bought olive oil you may not be able to find that information, or be disappointed in the answer.',
			trueOliveDifference: 'At True Olive we only sell olive oil from the most recent harvest ensuring that the olive oil you get is less than 18 months old.' 
		},
		{
			id: 2,
			image: 'minimal-time.webp',
			altText: 'Minimal time between harvesting and pressing',
			title: 'Minimal time between harvesting and pressing',
			body: 'Think olive oil like olive juice. The fresher the fruit, the better its juice will taste. That same principal is as applicable to olives as it is to orange juice. For most commercially produced olive oil the time between harvest and pressing is unfortunately much larger.',
			trueOliveDifference: 'At True Olive our Private Reserve olive oil goes from tree to press in 1 hour of the olives being harvested. Our Premium and Luxe olive oils are pressed within 24 hours of the olives being harvested.' 
		},
		{
			id: 3,
			image: 'single-estate.webp',
			altText: 'Go for single estate olive oil',
			title: 'Go for single estate olive oil',
			body: 'Since single estate olive oil comes from a single source they can be harvested and pressed more quickly. Furthermore, just like wine, they can showcase the distinct terroir of a particular micro-region providing a more nuanced and specific flavor profile.',
			trueOliveDifference: 'At True Olive all our olive oil comes from our family farm in the Dardanelles, a region known for its high quality olive oil since the days of ancient Troy.' 
		},
		{
			id: 4,
			image: 'dark-bottle.webp',
			altText: 'Dark bottles are better',
			title: 'Dark bottles are better',
			body: 'Olive oil is sensitive to light, particularly ultraviolet (UV) rays. Exposure to light can cause oxidation and degradation of the oil, leading to a decrease in its nutritional value, flavor, and aroma. Dark bottles, block out light and prevent photochemical reactions.',
			trueOliveDifference: 'At True Olive all our olive oil comes in black bottles that completely block sunlight and preserve the flavor, aroma and nutritional quality of our superior olive oils.' 
		},
		{
			id: 5,
			image: 'transportation.webp',
			altText: 'How the oil has been transported matters',
			title: 'How the oil has been transported matters',
			body: 'High temperatures can accelerate the oxidation process and lead to rancidity. Store olive oil at a consistent temperature, ideally between 57°F and 70°F. This matters not only when you are storing olive oil but also when you are transporting it.',
			trueOliveDifference: 'At True Olive our olive oils are shipped from the Dardanelles to the US when the weather is favorable in the fall and the spring. At all other times we fly our olive oil to preserve its quality.' 
		}
	];

	const banner = {
		image: 'academy/academy-banner-2.webp',
		mobileImage: 'academy/banner-2-mobile.webp',
		altText: 'Get gold-award winning True Olive',
		title: 'Get gold-award winning True Olive',
		body: 'Experience the best taste and health benefits olive oil has to offer with our gold-award winning olive oil from our family-farm in the Dardanelles. At True Olive we sweat the details to provide the best.',
		CTA: 'Shop True Olive',
		linkTo: '/',
		analyticsHandler: ()=>bottomCTAAnalyticsHandler()
	}

	const pointsList = points.map(point=><HorizontalCard key={point.id} card={point} />);

	return (
		<MainLayout academy>
			<div className='container'>
				<div className='row'>
					<div className='col-12 py-5 mb-2'>
						<SectionHeader noCapitalization passOnClasses='mb-2 text-center text-lg-start'>
							The right olive oil makes all the difference
						</SectionHeader>
						<HeaderTagline passOnClasses='text-center text-lg-start'>
							High quality olive oil not only tastes significantly better, but is also a must to 
							experience the health benefits of olive oil. Selecting the right olive oil may be 
							difficult at first. It may feel like you do not have much to go off on except the 
							packaging and the price. Thankfully, there are other things you can look at.   
						</HeaderTagline>
					</div>
				</div>
				<div className='row'>
					<div className='col-12'>
						{pointsList}
					</div>
				</div>
				<div className='row'>
					<div className='col-12 pt-4 pb-5'>
						<ImageBanner banner={banner}/>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default ChoosingOliveOil;