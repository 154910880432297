import {Fragment} from 'react';

import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import Order from './Order/Order';

const Orders = props => {

	const orderList = props.orders.map(order=><Order order={order} key={order.createdAt}/>)

	return (
		<Fragment>
  		<ListItemHeader passOnClasses='d-none d-md-block mb-4'>Orders</ListItemHeader>
  		{orderList}
  	</Fragment>
	)
}

export default Orders;