import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import VerticalCard from '../../components/UIElements/VerticalCard/VerticalCard';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const HealthBenefits = () => {


	const benefitsAnalyticsHandler = benefit => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_HEALTH_BENEFIT_CTA,
    	label: benefit
    });
	}

	const bottomCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BOTTOM_CTA,
    	label: 'choosingOliveOil'
    });
	}
	
	const benefits = [
		{
			id: 0,
			image: 'heart-health.webp',
			altText: 'Improved heart health',
			title: 'Improved heart health',
			body: 'The monounsaturated fatty acids (MUFAs) and polyphenols in high-quality olive oil lower total cholesterol and LDL, the "bad" cholesterol.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://pubmed.ncbi.nlm.nih.gov/29897866/',
			analyticsHandler: ()=>benefitsAnalyticsHandler('heartHealth') 
		},
		{
			id: 1,
			image: 'brain-health.webp',
			altText: 'Improved brain health',
			title: 'Improved brain health',
			body: 'Olive oil helps prevent cognitive impairments like Alzheimer’s disease and dementia. The polyphenols, particulary oleocanthal, is to thank.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://ocm.auburn.edu/newsroom/news_articles/2018/11/080943-alzheimers-olive-oil.php',
			analyticsHandler: ()=>benefitsAnalyticsHandler('brainHealth') 
		},
		{
			id: 2,
			image: 'immune-health.webp',
			altText: 'Improved immune health',
			title: 'Improved immune health',
			body: 'Consuming high quality olive is associated with grater growth and activation of T-cells, responsible for attacking foreign invaders.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4527272/',
			analyticsHandler: ()=>benefitsAnalyticsHandler('immuneHealth') 
		},
		{
			id: 3,
			image: 'cancer-prevention.webp',
			altText: 'Cancer prevention',
			title: 'Cancer prevention',
			body: 'Studies show a reduced risk of cancer, especially breast cancer and cancers of the digestive system, for those eating a diet high in olive oil.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3199852/',
			analyticsHandler: ()=>benefitsAnalyticsHandler('canverPrevention') 
		},
		{
			id: 4,
			image: 'diabetes-prevention.webp',
			altText: 'Diabetes prevention',
			title: 'Diabetes prevention',
			body: 'The main monounsaturated fat in olive oil, oleic acid, has been shown in studies to be particularly protective against insulin resistance.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5436092/',
			analyticsHandler: ()=>benefitsAnalyticsHandler('diabetesPrevention') 
		},
		{
			id: 5,
			image: 'anti-inflammatory.webp',
			altText: 'Anti-inflammatory benefits',
			title: 'Anti-inflammatory benefits',
			body: 'Oleocanthal, the polyphenol found in high quality olive oil, has demonstrated anti-inflammatory properties similar to those found in ibuprofen.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://pubmed.ncbi.nlm.nih.gov/21443487',
			analyticsHandler: ()=>benefitsAnalyticsHandler('antiInflammatory') 
		},
		{
			id: 6,
			image: 'bone-health.webp',
			altText: 'Bone health and strength',
			title: 'Bone health and strength',
			body: 'Inflammation in the body can turn on osteoclast cells, which break down bone. Anti-inflammatory polyphenols in olive oil prevent this process.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6115724/',
			analyticsHandler: ()=>benefitsAnalyticsHandler('boneHealth') 
		},
		{
			id: 7,
			image: 'mental-outlook.webp',
			altText: 'Improved mental outlook',
			title: 'Improved mental outlook',
			body: 'Beneficial fats in olive oil support the central nervous system, increasing levels of mood-supporting neurotransmitter serotonin.',
			CTA: 'Geet geeky: Read the study',
			linkType: 'external',
			linkTo: 'https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-017-0791-y',
			analyticsHandler: ()=>benefitsAnalyticsHandler('mentalOutlook')
		},
		{
			id: 8,
			image: 'and-more.webp',
			altText: 'And more',
			title: 'And more',
			body: 'High quality olive oil consumption has been associated with improved vascular health, a healthier gut biome, increased fertility and more.'
		}
	];

	const banner = {
		image: 'academy/academy-banner-1.webp',
		mobileImage: 'academy/banner-1-mobile.webp',
		altText: 'Use high quality olive oil to experience the benefits',
		title: 'Use high quality olive oil to experience the benefits',
		body: 'To truly experience the benefits of olive oil you need to use high quality olive oil. Superior farming, pressing, storage and transportation methods ensures high quality olive oil has more beneficial nutrients in them.',
		CTA: 'Choose high quality olive oil',
		linkTo: '/academy/choosing-olive-oil',
		analyticsHandler: ()=>bottomCTAAnalyticsHandler()
	}

	const benefitList = benefits.map(benefit=> {return(
		<div className='col-md-6 col-lg-4 mb-4' key={benefit.id}><VerticalCard card={benefit} /></div>
	)});

	return (
		<MainLayout academy>
			<div className='container'>
				<div className='row'>
					<div className='col-12 py-5 mb-2'>
						<SectionHeader noCapitalization passOnClasses='mb-2 text-center text-lg-start'>
							The key to a long, healthy, happy life
						</SectionHeader>
						<HeaderTagline passOnClasses='text-center text-lg-start'>
							Olive oil is a fundamental component of the Mediterranean diet, which is renowned for 
							promoting longevity and reducing the risk of chronic diseases. But why is it so good 
							for us? High quality olive oil is rich in polyphenols, which protect the body's tissues 
							against oxidative stress and associated pathologies such as cancers, coronary heart 
							disease and inflammation.  
						</HeaderTagline>
					</div>
				</div>
				<div className='row'>
					{benefitList}
				</div>
				<div className='row'>
					<div className='col-12 pt-4 pb-5'>
						<ImageBanner banner={banner}/>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default HealthBenefits;