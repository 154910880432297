import {useState, Fragment} from 'react';

import ModalLayout from '../../Layouts/ModalLayout/ModalLayout';
import ListItemHeader from '../../UIElements/TextElements/ListItemHeader/ListItemHeader';
import ReAuthForm from '../../Forms/ReAuthForm/ReAuthForm';

import styles from './ReAuthModal.module.css';

const ReAuthModal = props => {

	return(
		<ModalLayout showModal={props.showModal} hideModal={props.hideModal}>
			<div className='container-fluid mb-4'>
				<ListItemHeader passOnClasses='mb-4'>Please enter your current password to continue updating your information</ListItemHeader>
				<div className={styles.FormWrapper}>
					<ReAuthForm dataToUpdate={props.dataToUpdate} hideModal={props.hideModal}/>
				</div>
			</div>
		</ModalLayout>
	)
}

export default ReAuthModal;