import {useContext, Fragment} from 'react';

import AuthContext from '../../../store/auth-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import Error from '../../UIElements/Error/Error';

import {validatePassword} from '../../../helpers/validation-functions';
import useInput from '../../../hooks/use-input';

const EditPasswordForm = props => {

	const authCtx = useContext(AuthContext);
 
	const newPassword = useInput(validatePassword, '');

	const fields = [
		{
			fid: 1,
      label: 'Your new password',
      id: 'newPassword',
      type: 'password',
      errorMessage: 'Please enter a valid password',
      value: newPassword.value,
      isValid: newPassword.isValid,
      hasError: newPassword.hasError,
      valueChangedHandler: newPassword.valueChangedHandler,
      touchHandler: newPassword.touchHandler,
      reset: ()=>newPassword.reset()
		}
	];

	const submitHandler = (event) => {
    event.preventDefault();
    props.showModal({newPassword: newPassword.value});  
    authCtx.resetStates();
  }

  // CONTENT
  let content = (
      <Form 
        fields={fields} 
        submitHandler={submitHandler} 
        serverError={authCtx.error}
      >
        Update your password
      </Form>
    );
  if (authCtx.isLoading) content = <Loader>Updating your account information...</Loader>;  

  return <Fragment>{content}</Fragment>
}

export default EditPasswordForm;