import {useContext} from 'react';
import CartContext from '../../../../../store/cart-context';

import premiumBottle from '../../../../../assets/images/premium.webp';
import luxeBottle from '../../../../../assets/images/luxe.webp';
import privateReserveBottle from '../../../../../assets/images/private-reserve.webp';
import premium750Bottle from '../../../../../assets/images/premium-750.webp';
import luxe750Bottle from '../../../../../assets/images/luxe-750.webp';
import privateReserve750Bottle from '../../../../../assets/images/private-reserve-750.webp';
import tastingCollectionImg from '../../../../../assets/images/tasting-collection-cart.webp';
import everydayLuxuryImg from '../../../../../assets/images/everyday-luxury.webp';
import refinedPalateImg from '../../../../../assets/images/refined-palate.webp';
import trueConnoisseurImg from '../../../../../assets/images/true-connoisseur.webp';

import FeatureP from '../../../../../components/UIElements/TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';

import styles from './OrderItem.module.css';

const OrderItem = props => {

	const cartCtx = useContext(CartContext);
	
	let image = <img src={premiumBottle} alt='Premium Bottle' className={styles.SingleBottleImage} />
	if (props.item.itemName === 'luxe') image = <img src={luxeBottle} alt='Luxe Bottle' className={styles.SingleBottleImage} />
	if (props.item.itemName === 'privateReserve') image = <img src={privateReserveBottle} alt='Private Reserve Bottle' className={styles.SingleBottleImage} />
	if (props.item.id === 'premium750') image = <img src={premium750Bottle} alt='Premium Bottle' className={styles.SingleBottleImage} />
	if (props.item.id === 'luxe750') image = <img src={luxe750Bottle} alt='Luxe Bottle' className={styles.SingleBottleImage} />
	if (props.item.id === 'privateReserve750') image = <img src={privateReserve750Bottle} alt='Private Reserve Bottle' className={styles.SingleBottleImage} />
	if (props.item.itemName === 'tastingCollection') image = <img src={tastingCollectionImg} alt='Tasting Collection' className={styles.MultipleBottlesImage} />
	if (props.item.itemName === 'everdayLuxury') image = <img src={everydayLuxuryImg} alt='Everyday Luxury' className={styles.SingleBottleImage} />
	if (props.item.itemName === 'refinedPalate') image = <img src={refinedPalateImg} alt='Refined Palate' className={styles.MultipleBottlesImage} />	
	if (props.item.itemName === 'trueConnoisseur') image = <img src={trueConnoisseurImg} alt='True Connoisseur' className={styles.MultipleBottlesImage} />


	let packageType = 'bottle'; 
	if (
		props.item.itemName === 'tastingCollection' || 
		props.item.itemName === 'refinedPalate' || 
		props.item.itemName === 'trueConnoisseur'
	) { packageType = 'set' }

	let isRecurringCopy = '';	
	if (
		props.item.itemName === 'everydayLuxury' || 
		props.item.itemName === 'refinedPalate' || 
		props.item.itemName === 'trueConnoisseur'
	) { isRecurringCopy = '/m' }

	let listPriceStriked = null;
	if (props.item.itemPrice !== props.item.itemListPrice){
		listPriceStriked = (
			<ListItemInfoP passOnClasses='mb-0'>
				<span className={styles.StrikedPrice}>{`$${props.item.itemListPrice}/${packageType}${isRecurringCopy}`}</span>
			</ListItemInfoP>
		)
	}  

	return (
		<div className={styles.OrderItem}>
			<div className='d-flex align-items-center'>
				<div className={styles.ImageWrapper}>
					{image}
				</div>
				<div className={styles.ItemInfo}>
					<FeatureP passOnClasses='mb-0'>{cartCtx.getItemName(props.item.itemName)}</FeatureP>
					{listPriceStriked}
					<ListItemInfoP passOnClasses='mb-0'>{`$${props.item.itemPrice}/${packageType}`}</ListItemInfoP>
				</div>
				<FeatureP passOnClasses='mb-0'>{props.item.quantity}</FeatureP>
				<div>
					<div className={styles.SmallTotalPrice}>
						<ListItemInfoP passOnClasses='mb-0 ms-1 text-center'>{`($${props.item.total}${isRecurringCopy})`}</ListItemInfoP>
					</div>
				</div>
			</div>
			<div className={styles.Price}>
				<FeatureP passOnClasses='mb-0'>{`$${props.item.total}${isRecurringCopy}`}</FeatureP>
			</div>
		</div>
	)
}

export default OrderItem