import styles from './SideNavItem.module.css';

const SideNavItem = props => {

	const isActive = props.item.active ? styles.Active : null;

	return (
		<span 
			className={`flex-fill text-start nav-link ${isActive} ${styles.SideNavItem}`} 
			aria-current='page' 
			onClick = {()=>props.clickHandler(props.item.id)}
		>
			{props.item.name}
		</span>
	)
}

export default SideNavItem;
