import React from 'react';
import styles from './LinkBtn.module.css';

const LinkBtn = props => {
	return (
		<button className={`btn btn-link ${styles.LinkBtn}`} onClick={props.clickHandler}>
			{props.children}
		</button>
	)
} 

export default LinkBtn;