import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import Loader from '../../../components/UIElements/Loader/Loader';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import ListItemInfoP from '../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import CreateAccountForm from '../../../components/Forms/CreateAccountForm/CreateAccountForm';

const AccountCreation = props => {

	let contentArea = (
		<CreateAccountForm 
			setIsAccountLoading={props.setIsAccountLoading} 
			setIsAccountSuccessful={props.setIsAccountSuccessful}
			orderConfirmationPage
		/>
	)
	if (props.isAccountLoading) contentArea = <Loader dark>Creating your account...</Loader> 

	return ( !props.isAccountSuccessful &&
		<OptionsWrapper passOnClasses='mb-5 mt-5'> 
			<ListItemHeader>Create an account to activate your membership</ListItemHeader>
			<ListItemInfoP>Your order has been processed, but your membership is not active yet.</ListItemInfoP>
			{contentArea}
		</OptionsWrapper>
	)
}

export default AccountCreation;