import { useContext, useState, useEffect, Fragment } from 'react';
import CartContext from '../../../store/cart-context';
import CartMessage from './CartMessage/CartMessage';

import cartIcon from '../../../assets/images/cart-icon.webp';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import styles from './CartIcon.module.css';

const CartIcon = props => {

	const cartCtx = useContext(CartContext);
	
	const [totalBottles, setTotalBottles] = useState(cartCtx.sumBottles());
	const [showCartMessage, setShowCartMessage] = useState(false);

	useEffect(()=>{
		setTotalBottles(cartCtx.sumBottles());
	},[cartCtx])

	const shiftUp = props.shiftUp ? styles.ShiftUp : '';
	const hasIcon = props.count ? styles.HasIcon : '';

	const clickHandler = () => {
		if (totalBottles>0) {
			cartCtx.toggleDrawer(true);
			ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.CLICKED_ON_CART,
	    	label: 'full'
	    });
		}
		if (totalBottles === 0) {
			setShowCartMessage(!showCartMessage);
			ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.CLICKED_ON_CART,
	    	label: 'empty'
	    })
		}
	}

	const cartMessageElement = showCartMessage ? <CartMessage hide={()=>setShowCartMessage(false)}/> : null;

	return (
		<div className={styles.Wrapper}>
			<div 
				className={`${styles.IconWrapper} ${shiftUp} ${hasIcon}`} 
				onClick={clickHandler}
			>
				<img src={cartIcon} alt='Your cart' className={styles.Icon} />
				<span className={`${styles.Badge} position-absolute top-50 start-100 translate-middle badge rounded-pill bg-light`}>
			    <span className='text-dark'>{totalBottles}</span>
			    <span className="visually-hidden">Items in your cart</span>
			  </span>
			</div>
			{cartMessageElement}
		</div>
	)

} 

export default CartIcon;

		