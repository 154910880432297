import {useState, Fragment} from 'react';

import ModalLayout from '../../Layouts/ModalLayout/ModalLayout';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import SignInButton from '../../UIElements/Buttons/SignInButton/SignInButton';
import SignInForm from '../../Forms/SignInForm/SignInForm';

import styles from './SignInModal.module.css';

const SignInModal = props => {

	const [shownElement, setShownElement] = useState('emailForm');

	let elementToShow = null;
	if (shownElement ==='options') {
		elementToShow = (
			<Fragment>
				<SectionHeader passOnClasses='mb-4'><em>Sign in to your account</em></SectionHeader>
				<SignInButton type='google' clickHandler={()=>console.log('Sign me in with Google')}/>
				<SignInButton type='facebook' clickHandler={()=>console.log('Sign me in with Facebook')}/>
				<SignInButton type='email' clickHandler={()=>setShownElement('emailForm')}/>
			</Fragment>
		)
	}
	if (shownElement ==='emailForm') elementToShow = <SignInForm backHandler={()=>setShownElement('options')}/>

	return(
		<ModalLayout showModal={props.showModal} hideModal={props.hideModal} type='signIn'>
			<div className='container-fluid text-center mb-4'>
				<div className={styles.OptionsWrapper}>
					{elementToShow}
				</div>
			</div>
		</ModalLayout>
	)
}

export default SignInModal;