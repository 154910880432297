import {useRef, useContext} from 'react';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';
import {bottleDescriptions} from '../../helpers/bottle-helpers';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Hero from './Hero/Hero';
import Bottles from '../../components/UIElements/Bottles/Bottles';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import Plans from '../../components/UIElements/Plans/Plans';
import FAQs from '../../components/UIElements/FAQs/FAQs';
import TastingCollection from '../../components/UIElements/TastingCollection/TastingCollection';

import CartContext from '../../store/cart-context';
import PromotionCodeContext from '../../store/promotion-code-context';

const Home = () => {

	const cartCtx = useContext(CartContext);
	const promoCodeCtx = useContext(PromotionCodeContext);

	const bottlesArray = [
		{
			id: 0,
			title: 'Premium',
			bottle: '375 ml bottle',
			description: bottleDescriptions.PREMIUM,
			img: 'premium',
			listPrice: cartCtx.getItemListPrice('premium'),  
			displayPrice: cartCtx.getItemDiscountedPrice('premium', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('premium')
		},
		{
			id: 1,
			title: 'Luxe',
			bottle: '375 ml bottle',
			description: bottleDescriptions.LUXE,
			img: 'luxe',
			listPrice: cartCtx.getItemListPrice('luxe'),
			displayPrice:cartCtx.getItemDiscountedPrice('luxe', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('luxe')
		},
		{
			id: 2,
			title: 'Private Reserve',
			bottle: '375 ml bottle',
			description: bottleDescriptions.PRIVATE_RESERVE,
			img: 'private-reserve',
			listPrice: cartCtx.getItemListPrice('privateReserve'),
			displayPrice:cartCtx.getItemDiscountedPrice('privateReserve', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('privateReserve')
		}
	];

	const banner = {
		image: 'academy-promo-banner.webp',
		mobileImage: 'academy-promo-mobile-banner.webp',
		altText: 'Level up your olive oil know-how with True Olive Academy',
		title: 'Level up your olive oil know-how with True Olive Academy',
		body: 'True Olive Academy will get you up to speed on the health benefits of high-quality olive oil, how you can identify and choose high-quality olive oil, and how you can delight and nurture your senses with curated recipes.',
		CTA: 'Level up with True Olive Academy',
		linkTo: '/academy/choosing-olive-oil',
		analyticsHandler: ()=>academyBannerCTAAnalyticsHandler()
	}

	const ref = useRef(null);

	const handleScroll = () => {
	  ref.current?.scrollIntoView({behavior: 'smooth'});
	  ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.SCROLLED_FROM_HERO,
    });
	};

	const academyBannerCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BANNER_CTA,
    	label: 'home'
    });
	}

	return (
		<MainLayout bottomCTA='default'>
			<Hero handleScroll={()=>handleScroll()} />
			<div className='container'>
				<div className='row' ref={ref}>
					<div id='bottles'><Bottles bottlesArray={bottlesArray} /></div>
					<div className='col-xl-10 mb-4'><TastingCollection/></div>
					<div className='col-12 mb-4'><ImageBanner banner={banner}/></div>
					<Plans />
					<FAQs />
				</div>
			</div>
		</MainLayout>
	)
}

export default Home;