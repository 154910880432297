import React from 'react'; 

import Question from './Question/Question';

const FAQList = props => {
	
	const list = props.questions.map(question=>{
		return <Question question={question} key={question.id} />
	});

	return <div className='py-4'>{list}</div>
}

export default FAQList