import googleIcon from '../../../../assets/images/google-icon.webp';
import facebookIcon from '../../../../assets/images/facebook-icon.webp';
import emailIcon from '../../../../assets/images/email-icon.webp';
import styles from './SignInButton.module.css';

const SignInButton = props => {

	let icon = googleIcon
	let copy = 'Sign in with Google';
	let buttonTypeStyles = styles.GoogleButton;

	if (props.type ==='facebook') {
		icon = facebookIcon;
		copy = 'Sign in with Facebook'
		buttonTypeStyles = styles.FacebookButton;
	}

	if (props.type ==='email') {
		icon = emailIcon;
		copy = 'Sign in with your email'
		buttonTypeStyles = styles.EmailButton;
	}

	const iconStyles = {background: `url(${icon})`}

	return (
		<button 
			className={`btn btn-lg btn-primary ${styles.SignInButton} ${buttonTypeStyles}`}
			onClick={props.clickHandler}
		>
			<div className={styles.Icon} style={iconStyles}></div>
			<span>{copy}</span>
		</button>
	)
}

export default SignInButton;