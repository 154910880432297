import {useContext} from 'react';
import OrdersContext from '../../../store/orders-context';

import LeadP from '../../../components/UIElements/TextElements/LeadP/LeadP';
import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import CopyUrl from '../../../components/UIElements/ShareOptions/CopyUrl/CopyUrl';
import TwitterButton from '../../../components/UIElements/ShareOptions/TwitterButton/TwitterButton';
import FacebookButton from '../../../components/UIElements/ShareOptions/FacebookButton/FacebookButton';
import WhatsappButton from '../../../components/UIElements/ShareOptions/WhatsappButton/WhatsappButton';

const ShareOptions = props => {

	const ordersCtx = useContext(OrdersContext);

	let shareMessage = `Just ordered True Olive olive oil for the first time. Can't wait 
	to get it and try it. #tastewhatstrue`;
	if (ordersCtx.getOrderCount() === 2) {
		shareMessage = `I ordered True Olive olive oil for the second time. I can't recommend it highly enough. 
		#tastewhatstrue`
	}
	if (ordersCtx.getOrderCount() > 3) {
		shareMessage = `I've been getting True Olive olive oil for a while now. I can't recommend it highly 
		enough! #tastewhatstrue`
	}

	const shareUrl = process.env.REACT_APP_BASE_URL;

	return (
		<OptionsWrapper passOnClasses='mb-4 mt-4'>
			<div className='d-flex align-items-center mb-3'>
				<LeadP passOnClasses='me-2'>Share the love:</LeadP>
				<div className='me-2'><TwitterButton tweetCopy={shareMessage} shareUrl={shareUrl}/></div>
				<div className='me-2'><WhatsappButton postCopy={shareMessage} shareUrl={shareUrl} /></div>
				<FacebookButton postCopy={shareMessage} shareUrl={shareUrl}/>
			</div>
			<div><CopyUrl shareUrl={shareUrl}/></div>
		</OptionsWrapper>
	)
}

export default ShareOptions;