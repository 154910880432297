import {Fragment, useState, useEffect} from 'react';
import Input from './Input/Input';
import PrimaryCTA from '../Buttons/PrimaryCTA/PrimaryCTA';
import Error from '../Error/Error';

const Form = props => {

	const [formIsValid, setFormIsValid] = useState(false);
	const fieldValidationArray = [];
	
	const fields = props.fields.map(field=>{
		fieldValidationArray.push(field.isValid);
		if (props.inline) {
			return (
				<div className='col-12 flex-grow-1' key={field.fid}>
					<Input key={field.fid} field={field} inline/>
				</div>
			);
		}
		return <Input key={field.fid} field={field} />
	});

	useEffect(()=>{
		const hasInvalidField = fieldValidationArray.some(value => value==false);
		setFormIsValid(!hasInvalidField);
	}, fieldValidationArray);

	const error = props.serverError ? <Error errorCode={props.serverError.code}>{props.serverError.message}</Error> : null;
	
	const inlineFormClasses = props.inline ? 'row row-cols-lg-auto align-items-end' : ''; 
	const smallSized = props.inline ? true : false; 

	const inlineButtonClasses = props.inline ? 'mb-3' : '';


	return (
		<Fragment>
			{error}
			<form onSubmit={props.submitHandler} className={inlineFormClasses}>
				{fields}
				<div className='col-12 mb-lg-3'>
	        <PrimaryCTA 
	        	disabled={!formIsValid} 
	        	mediumSized={!smallSized} 
	        	smallSized={smallSized} 
	        	passOnClasses={inlineButtonClasses}
	        >
	        	{props.children}
	        </PrimaryCTA>
	      </div>
			</form>
		</Fragment>
	)
} 

export default Form;