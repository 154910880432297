import styles from './Label.module.css';

const Label = props => {
	const passOnClasses = props.passOnClasses ? props.passOnClasses : '';

	return (
		<h3 className={`${passOnClasses} ${styles.Label}`}>{props.children}</h3>
	)
}

export default Label;