import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';

import Point from './Point/Point';

const Difference = props => {

	const points = [
		{
			id: 0,
			header: 'Like you, we strive for extraordinary',
			body: `Marrying thousands of years of culinary tradition with modern sensibility and state-of-the-art 
				farming and production techniques, we craft olive oils of incomparable quality and taste. Our  
				dedication to exceptional olive oil production has been recognized on both side of the Atlantic with
				gold medals in international competitions.`
		},
		{
			id: 1,
			header: 'Feel good about the olive oil you use',
			body: `Everything we produce is free from pesticides and harmful chemicals. Not just because the olive oil 
			tastes better that way, but because it is the right thing to do. We also do right by our people. Everyone 
			involved in the production of our olive oil is included in profit sharing, reaping the benefits of their 
			labor and passion.`
		},	
		{
			id: 2,
			header: 'Join an exclusive club',
			body: `We are a single-estate olive oil producer that prioritizes quality over quantity. Our primary focus 
			is on serving direct-to-consumer customers, and our supply is not endless. As a result, for the next year, 
			we have decided to collaborate exclusively with a select group of 15 restaurants that share our dedication 
			to exceptional taste and authenticity.`
		}
	];

	const supportingPoints = points.map(point=><Point key={point.id} point={point} />)

	return (
		<div className='col-lg-9 py-2'>
			<SectionHeader passOnClasses='mb-4'><em>Be a cut above with True Olive</em></SectionHeader>
			{supportingPoints}
		</div>
	)

}

export default Difference;