import {postData} from './http-helpers';

// NEW ORDER
export const fireOrderEmail = async emailData => {

	let trackUrl = '';
	if (emailData.userId) trackUrl = `${process.env.REACT_APP_BASE_URL}account?tab=orders`;
	if (emailData.guestId) trackUrl = `${process.env.REACT_APP_BASE_URL}create-account?guestId=${emailData.guestId}`; 

	try {
  	const response = await postData(
  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}fireOrderEmail`,
  		{
  			customerEmail: emailData.email,
  			customerName: emailData.fullName,
  			trackUrl: trackUrl, 
  			totalPrice: emailData.totalPrice,
  			items: emailData.items,
  			hasFreeShipping: emailData.hasFreeShipping,
  			shippingPrice: emailData.shippingPrice,
  			hasDollarDiscount: emailData.hasDollarDiscount,
				dollarDiscount: emailData.dollarDiscount,
  			tax: emailData.tax,
  			hasTax: emailData.hasTax
  		}
    );
		return true;
  } catch (error) {
  	return false;
  }
}

//NEW ACCOUNT
export const fireNewAccountEmail = async emailData => {

	try {
  	const response = await postData(
  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}fireNewAccountEmail`,
  		{
  			customerEmail: emailData.email,
  			customerName: emailData.fullName,
  			customerFirstName: emailData.firstName
  		}
    );
		return true;
  } catch (error) {
  	return false;
  }
}

//CANCELLED MEMBERSHIP
export const fireCancelledMembershipEmail = async emailData => {

	try {
  	const response = await postData(
  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}fireCancelledMembershipEmail`,
  		{
  			customerEmail: emailData.email,
  			customerName: emailData.fullName,
  			customerFirstName: emailData.firstName
  		}
    );
		return true;
  } catch (error) {
  	return false;
  }
}

