export const splitName = fullName => {
	const userNames = fullName.split(' ');
	const middleNames = userNames.slice(1, userNames.length-1);
	
	const names = {
		firstName: userNames[0],
		lastName: userNames[userNames.length-1], 
		middleNames: middleNames.join(' ')
	};

	return names;
}

export const makePossesive = name => {
	const lastCharacter = name.slice(-1);
	if (lastCharacter === 's') return `${name}'`;
	return `${name}'s`;
}

export const transformSecondsToDate = seconds => {
	var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(seconds);
  return t;
} 

export const transformSecondsToDisplayDate = seconds => {
	const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	
	const date = transformSecondsToDate(seconds);
	const dateAsString = date.toString();
	const dateAsArray = dateAsString.split(' ');

	const day = dateAsArray[2];
	const month = months[date.getMonth()];
	const year = dateAsArray[3];

	return `${month} ${day}, ${year}`;
}

export const easeTransform = (easeLevel, returnType) => {
	if (returnType === 'tag') {
		if (Number(easeLevel) === 1) return 'SuperEasy';
		if (Number(easeLevel) === 2) return 'FairlyEasy';
		if (Number(easeLevel) === 3) return 'NotSoEasy';
		if (Number(easeLevel) === 4) return 'Difficult';
	}
	if (Number(easeLevel) === 1) return 'Super easy';
	if (Number(easeLevel) === 2) return 'Fairly easy';
	if (Number(easeLevel) === 3) return 'Not so easy';
	if (Number(easeLevel) === 4) return 'Difficult';
}

export const goodForTransform = goodForNumber => {
	if (Number(goodForNumber) === 1) return 'Breakfast';
	if (Number(goodForNumber) === 2) return 'Lunch';
	if (Number(goodForNumber) === 3) return 'Dinner';
	if (Number(goodForNumber) === 4) return 'Snack';
	if (Number(goodForNumber) === 5) return 'Side';
}