import {useContext, Fragment} from 'react';

import UserContext from '../../../store/user-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import Error from '../../UIElements/Error/Error';

import ReAuthModal from '../../Modals/ReAuthModal/ReAuthModal';

import {validateName, validateEmail} from '../../../helpers/validation-functions';
import useInput from '../../../hooks/use-input';

const EditAccountInfoForm = props => {

	const userCtx = useContext(UserContext);

	const firstName = useInput(validateName,  userCtx.firstName);
	const lastName = useInput(validateName,  userCtx.lastName);
	const email = useInput(validateEmail,  userCtx.email);

	const fields = [
		{
			fid: 0,
      label: 'Your first name',
      id: 'firstName',
      type: 'text',
      errorMessage: 'Please enter a valid first name',
      value: firstName.value,
      isValid: firstName.isValid,
      hasError: firstName.hasError,
      valueChangedHandler: firstName.valueChangedHandler,
      touchHandler: firstName.touchHandler,
      reset: ()=>firstName.reset()
		},
		{
			fid: 1,
      label: 'Your lastName',
      id: 'lastName',
      type: 'text',
      errorMessage: 'Please enter a valid last name',
      value: lastName.value,
      isValid: lastName.isValid,
      hasError: lastName.hasError,
      valueChangedHandler: lastName.valueChangedHandler,
      touchHandler: lastName.touchHandler,
      reset: ()=>lastName.reset()
		},
		{
			fid: 2,
      label: 'Your email',
      id: 'email',
      type: 'text',
      errorMessage: 'Please enter a valid email address',
      value: email.value,
      isValid: email.isValid,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
		}
	];

	const submitHandler = (event) => {
    event.preventDefault();
    props.showModal({
    	firstName: firstName.value,
    	lastName: lastName.value,
    	email: email.value,
    	updatedAt: new Date()
    }); 
  }

	// CONTENT
  let content = <Loader>Getting your account information...</Loader>;
  if (userCtx.firstName) {
    content = (
      <Form 
        fields={fields} 
        submitHandler={submitHandler} 
        serverError={userCtx.error}
      >
        Update your account information
      </Form>
    );
  }   

  return <Fragment>{content}</Fragment>;

}

export default EditAccountInfoForm;