import {Fragment} from 'react';

import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import Loader from '../../../components/UIElements/Loader/Loader';
import SignInForm from '../../../components/Forms/SignInForm/SignInForm';

import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import BaseP from '../../../components/UIElements/TextElements/BaseP/BaseP';

const SignInToAccount = props => {

	let contentArea = (
		<SignInForm 
			setIsAccountLoading={props.setIsAccountLoading} 
			setIsAccountSuccessful={props.setIsAccountSuccessful}
			inline={true}
			email={props.email}
			createAccountPage
		/>
	)
	if (props.isAccountLoading) contentArea = <Loader dark>Signing in to your account...</Loader> 

	return ( !props.isAccountSuccessful &&
		<Fragment>
			<BaseP passOnClasses='mb-3'>{`You have an account with ${props.email} that has ${props.orderCount} orders.`}</BaseP>
			<OptionsWrapper passOnClasses='mb-5 mt-5'> 	
				<SectionHeader passOnClasses='mb-3'>Sign in to track your orders</SectionHeader>
				{contentArea}
			</OptionsWrapper>
		</Fragment>
	)
}

export default SignInToAccount;