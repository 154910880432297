import {useRef, useState, useContext} from 'react';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';
import {bottleDescriptions} from '../../helpers/bottle-helpers';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Hero from './Hero/Hero';
import Difference from './Difference/Difference';
import Bottles from '../../components/UIElements/Bottles/Bottles';
import FAQs from '../../components/UIElements/FAQs/FAQs';
import BottomContact from '../../components/UIElements/BottomContact/BottomContact';

import CartContext from '../../store/cart-context';
import PromotionCodeContext from '../../store/promotion-code-context';

const Home = () => {

	const ref = useRef(null);
	
	const cartCtx = useContext(CartContext);
	const promoCodeCtx = useContext(PromotionCodeContext);

	const [isSignInModalShown, setIsSignInModalShown] = useState(false);

	const bottlesArray = [
		{
			id: 0,
			title: 'Premium',
			bottle: '750 ml bottle',
			description: bottleDescriptions.PREMIUM,
			img: 'premium-750',
			listPrice: cartCtx.getItemListPrice('premium750'),  
			displayPrice: cartCtx.getItemDiscountedPrice('premium750', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('premium750')
		},
		{
			id: 1,
			title: 'Luxe',
			bottle: '750 ml bottle',
			description: bottleDescriptions.LUXE,
			img: 'luxe-750',
			listPrice: cartCtx.getItemListPrice('luxe750'),  
			displayPrice: cartCtx.getItemDiscountedPrice('luxe750', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('luxe750')
		},
		{
			id: 2,
			title: 'Private Reserve',
			bottle: '750 ml bottle',
			description: bottleDescriptions.PRIVATE_RESERVE,
			img: 'private-reserve-750',
			listPrice: cartCtx.getItemListPrice('privateReserve750'),  
			displayPrice: cartCtx.getItemDiscountedPrice('privateReserve750', promoCodeCtx.percentOff),
			clickHandler: ()=>cartCtx.addItem('privateReserve750')
		}
	];

	const handleScroll = () => {
	  ref.current?.scrollIntoView({behavior: 'smooth'});
	  ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.SCROLLED_FROM_RESTAURANT_HERO,
    });
	};

	return (
		<MainLayout transparentNavbar isSignInModalShown={isSignInModalShown}>
			<Hero handleScroll={()=>handleScroll()} fireSignInModal={()=>setIsSignInModalShown(true)}/>
			<div className='container'>
				<div className='row' ref={ref}>
					<Bottles bottlesArray={bottlesArray} noDiscount/>
					<Difference />
					<FAQs />
				</div>
			</div>
			<BottomContact />
		</MainLayout>
	)
}

export default Home;