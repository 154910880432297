import twitterIcon from '../../../../assets/images/twitter-icon.webp';
import styles from './TwitterButton.module.css';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../../helpers/analytics-helpers';

const TwitterButton = props => {

	let shareTypeEventName = eventNames.CLICKED_ORDER_SHARE_OPTION;
	if (props.shareObject==='recipe') shareTypeEventName = eventNames.CLICKED_RECIPE_SHARE_OPTION;

	const placeholderCopy = "True Olive's olive oils rock! Taste what's true." 
	const tweetCopy = props.tweetCopy ? props.tweetCopy : placeholderCopy;

	const tweetUrl = props.shareUrl; 

	const analyticsHandler = () => {
		ReactGA.event({
      category: eventCategories.VIRALITY, 
      action: shareTypeEventName,
      label: 'twitter'
    });
	}

	return (
		<a 
			href={`https://twitter.com/intent/tweet?text=${tweetCopy}&url=${tweetUrl}`}
			target="_blank"
			rel="noopener noreferrer"
			data-size="large"
			onClick={analyticsHandler}
		>
			<img src={twitterIcon} alt='Twitter icon' className={styles.Icon} />
		</a>
	)
}

export default TwitterButton;