import {useState, useContext, useEffect, Fragment} from 'react';

import AuthContext from '../../../store/auth-context';
import UserContext from '../../../store/user-context';
import AlertContext, {alertMessages} from '../../../store/alert-context'

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import Error from '../../UIElements/Error/Error';

import {validatePassword} from '../../../helpers/validation-functions';
import useInput from '../../../hooks/use-input';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const ReAuthForm = props => {

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);
  const alertCtx = useContext(AlertContext);

  const password = useInput(validatePassword, '');

  const fields = [
    {
      fid: 0,
      label: 'Your current password',
      id: password,
      type: 'password',
      errorMessage: 'Please enter a valid password',
      value: password.value,
      isValid: password.isValid,
      hasError: password.hasError,
      valueChangedHandler: password.valueChangedHandler,
      touchHandler: password.touchHandler,
      reset: ()=>password.reset()
    }
  ];

  useEffect(()=>{ 
    if (props.dataToUpdate){
      if(authCtx.isSuccessful){
        if (props.dataToUpdate.firstName && !authCtx.isLoading && 
          (  
            props.dataToUpdate.firstName !== userCtx.firstName ||
            props.dataToUpdate.lastName !== userCtx.lastName ||
            props.dataToUpdate.email !== userCtx.email
          )
        ) {  
          ReactGA.event({
            category: eventCategories.ACCOUNT, 
            action: eventNames.SUBMITTED_ACCOUNT_INFO_UPDATE
          });      
          userCtx.updateUserInfo(userCtx.userId, userCtx.stripeCustomerId, userCtx.email, props.dataToUpdate); 
          alertCtx.setAlert('success', alertMessages.ACCOUNT_UPDATED);
        }
        if (props.dataToUpdate.newPassword && !authCtx.isLoading && !passwordUpdated) {
          ReactGA.event({
            category: eventCategories.ACCOUNT, 
            action: eventNames.SUBMITTED_PASSWORD_CHANGE
          });      
          authCtx.onChangePassword(userCtx.email, password.value, props.dataToUpdate.newPassword);
          setPasswordUpdated(true); 
          alertCtx.setAlert('success', alertMessages.PASSWORD_UPDATED);
        }
        password.reset();
        props.hideModal();
      }
    }
  }, [authCtx, userCtx]);

  const submitHandler = async event => {
    event.preventDefault();
    setPasswordUpdated(false);
    await authCtx.onLogin({email: userCtx.email, password: password.value});
  }

  // CONTENT
  let content = (
      <Form 
        fields={fields} 
        submitHandler={submitHandler} 
        serverError={authCtx.error}
      >
        Submit
      </Form>
    );
  if (authCtx.isLoading) content = <Loader>Updating your account information...</Loader>;  

  return <Fragment>{content}</Fragment>
}

export default ReAuthForm;