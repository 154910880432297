import LeadP from '../../TextElements/LeadP/LeadP';
import ListItemInfoP from '../../TextElements/ListItemInfoP/ListItemInfoP';

import styles from './DollarDiscountItem.module.css';

const DollarDiscountItem = props => {

	return (

		<div className='d-flex align-items-center justify-content-between mt-3'>
			<LeadP passOnClasses='mb-0'>Discount applied</LeadP>
			<div className={styles.PriceWrapper}>
				<LeadP passOnClasses='mb-0'>{`- $${props.discountAmount}`}</LeadP>
			</div>
		</div>
	);

}

export default DollarDiscountItem;