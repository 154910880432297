import styles from './Hero.module.css';
import PrimaryCTA from '../../../components/UIElements/Buttons/PrimaryCTA/PrimaryCTA'; 
import BtnLink from '../../../components/UIElements/Buttons/BtnLink/BtnLink'; 
import {Link} from 'react-router-dom';

const Hero = props => {

	return (
		<section className={styles.Hero}>
			<div className='container'>
				<div className='row'>
					<div className='col-sm-10 col-md-9 col-lg-8 offset-sm-2 offset-lg-3 ps-5 ps-sm-0'>
						<h1 className={styles.Header}>
							Nourish, uplift and bring people to the table with olive oil
						</h1>
						<p className={styles.Tagline}>
							Unlock a long, healthy, happy life filled with delicious culinary experiences by 
							learning all about olive oil and how to use it.
						</p>
						<div className='d-sm-flex align-items-center'>
							<div className='d-block d-md-none'>
								<PrimaryCTA mediumSized clickHandler={props.handleScroll}>
									Start Discovering
								</PrimaryCTA>
							</div>
							<div className='d-none d-md-block'>
								<PrimaryCTA clickHandler={props.handleScroll}>
									Start Discovering
								</PrimaryCTA>
							</div>
							<div className='d-block d-md-none'>
								<Link to='/academy/recipes' onClick={props.recipesAnalyticsHandler}>
									<BtnLink passOnClasses='p-2'>Or, jump to recipes</BtnLink>
								</Link>
							</div>
							<div className='d-none d-md-block'>
								<Link to='/academy/recipes' onClick={props.recipesAnalyticsHandler}>
									<BtnLink passOnClasses='p-2 btn-lg'>Or, jump to recipes</BtnLink>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero;