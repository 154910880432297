import { useContext } from 'react';
import PromotionCodeContext from '../../../../store/promotion-code-context';
import CartContext from '../../../../store/cart-context';

import styles from './InfoBanner.module.css';

import AdditionalInfoP from '../../TextElements/AdditionalInfoP/AdditionalInfoP';
import shippingIcon from '../../../../assets/images/shipping-icon.webp'
import tagIcon from '../../../../assets/images/tag-icon.webp'

const InfoBanner = () => {

	const promoCodeCtx = useContext(PromotionCodeContext);
	const cartCtx = useContext(CartContext);

	let discount = null;
	if ((promoCodeCtx.amountOff || promoCodeCtx.percentOff) && cartCtx.hasDiscountElligibleItem()) {
		const discountTypeCopy =  promoCodeCtx.amountOff ? `$${promoCodeCtx.amountOff}` : `${promoCodeCtx.percentOff}%`;
		const bottomMargin = !cartCtx.hasFreeShipping() ? 'mb-2' : '';
		discount = (
			<div className={`d-flex align-items-center ${bottomMargin}`}>	
				<div className={styles.IconWrapper}>
					<img src={tagIcon} className={styles.Icon} alt="Tag icon" />
				</div>
				<AdditionalInfoP passOnClasses='mb-0 text-start'>
					{`${discountTypeCopy} off applied through code "${promoCodeCtx.name}".`}
				</AdditionalInfoP>
			</div>
		)
	}

	let freeShippingPromo = null;
	if (!cartCtx.hasFreeShipping()){
		freeShippingPromo = (
			<div className='d-flex align-items-center'>	
				<div className={styles.IconWrapper}>
					<img src={shippingIcon} className={styles.Icon} alt="Free shipping available" />
				</div>
				<AdditionalInfoP passOnClasses='mb-0 text-start'>Add a second item to get free shipping.</AdditionalInfoP>
			</div>
		)
	}

	return (
		<div className={styles.InfoBanner}>
			{discount}
			{freeShippingPromo}
		</div>
	)

}

export default InfoBanner;