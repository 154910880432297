import {Link} from 'react-router-dom';

import CardHeader from '../TextElements/CardHeader/CardHeader';
import FeatureP from '../TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../TextElements/ListItemInfoP/ListItemInfoP';
import OutlineButton from '../Buttons/OutlineButton/OutlineButton';
import BtnLink from '../Buttons/BtnLink/BtnLink';


import styles from './VerticalCard.module.css';

const VerticalCard = props => {

	let CTA = null
	if (props.card.CTA) {
		CTA = (
			<Link to={props.card.linkTo} onClick={props.card.analyticsHandler}>
				<OutlineButton>{props.card.CTA}</OutlineButton>
			</Link>
		);
	}
	if (props.card.CTA && props.card.linkType === 'external') {
		CTA = (
			<a href={props.card.linkTo} target='_blank' onClick={props.card.analyticsHandler}>
				<BtnLink>{props.card.CTA}</BtnLink>
			</a>
		);
	}

	let tagList = null;
	let tags = null;
	if (props.card.tags) {
		tags = props.card.tags.map(tag=><ListItemInfoP key={tag} passOnClasses='mx-2'>{`#${tag}`}</ListItemInfoP>) 
		tagList = <div className='d-flex justify-content-center'>{tags}</div> 
	}

	return (
		<div className={styles.Card}>
			<img 
				src={require(`../../../assets/images/academy/${props.card.image}`)}
				alt={props.card.altText}
				className={styles.Image}
			/>
			<div className='py-4 px-4 text-center'>
				<CardHeader>{props.card.title}</CardHeader> 
				{tagList}
				<FeatureP lessLineHeight passOnClasses='mb-3'>{props.card.body}</FeatureP>
				{CTA}
			</div>
		</div>

	)
}

export default VerticalCard