import React from 'react';
import styles from './SectionHeader.module.css';

const SectionHeader = props => {

	const noCapitalizationStyles = props.noCapitalization ? styles.NoCapitalization : '';

	return (
		<h2 className={`${props.passOnClasses} ${styles.SectionHeader} ${noCapitalizationStyles}`}>
			{props.children}
		</h2>
	)
}

export default SectionHeader