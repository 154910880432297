import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Card from './Card/Card';
import Error from '../../../components/UIElements/Error/Error';
import Loader from '../../../components/UIElements/Loader/Loader';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import FeatureP from '../../../components/UIElements/TextElements/FeatureP/FeatureP';
import OutlineButton from '../../../components/UIElements/Buttons/OutlineButton/OutlineButton';

const Cards = props => {

	let displayContent = null;

	if (props.list.length > 0) displayContent = props.list.map(card => {
		return <Card 
			cardData={card} 
			key={card.id} 
			removeCardHandler={props.removeCardHandler}
		/>
	});

	if (props.list.length === 0) displayContent = (
		<Fragment>
			<FeatureP>
				You don't have any payment methods on file yet. We'll save one for you if you become a member.
			</FeatureP>
			<Link to='/become-a-member'>
				<OutlineButton>Explore membership options</OutlineButton>
			</Link>
		</Fragment>
	); 

	if(props.isLoading) displayContent = <Loader>Loading your saved payment methods...</Loader>;
	if (props.Error) displayContent = <Error errorCode={props.error}>There was an error loading your saved payment methods</Error>;

	return (
		<Fragment>
			<ListItemHeader passOnClasses='d-none d-md-block mb-4'>Payment methods</ListItemHeader>
			{displayContent}
		</Fragment>
	)
}

export default Cards;