import { createContext, useState } from 'react';

const AlertContext = createContext({
	isShown: '',
	isClosed: '',
	type: '',
	message: '',
	setAlert: (type, message) => {}
});

export const AlertContextProvider = props => {
	const [isShown, setIsShown] = useState(false);
	const [isClosed, setIsClosed] = useState(null);
	const [type, setType] = useState('');
	const [message, setMessage] = useState('');

	const setAlert = (type, message) => {
		setIsClosed(false);
		setIsShown(true);
		setType(type);
		setMessage(message);

		closeAlert();
	}

	const closeAlert = () => setTimeout(() => {
		setIsShown(false);
		setIsClosed(true);
		setType('');
		setMessage('');
		resetIsClosed();
	},3500)

	const resetIsClosed = () => setTimeout(()=>{setIsClosed(null)}, 500)

	return (
		<AlertContext.Provider value={{
			isShown: isShown,
			isClosed: isClosed,
			type: type,
			message: message,
			setAlert: setAlert
		}}>
			{props.children}
		</AlertContext.Provider>
	)
}

export const alertMessages = {
	SOFT_LAUNCH_PROMOTION_CODE_APPLIED: 'Happy Mother\'s Day!',
	ACCESS_GRANTED: 'Early access granted',
	RESTAURANT_ACCESS_GRANTED: 'Restaurant access granted',
	SIGN_IN_SUCCESS: 'Signed in successfully',
	REGISTER_SUCCESS: 'Created account successfully',
	ACCOUNT_UPDATED: 'Account information updated',
	PASSWORD_UPDATED: 'Password updated successfully',
	SHIPPING_INFO_UPDATED: 'Shipping information updated',
	MEMBERSHIP_CANCELLED: 'Membership cancelled successfully',
	MEMBERSHIP_ACTIVATED: 'Membership activated',
	REMOVED_PAYMENT_METHOD: 'Payment method removed successfully',
	LOGGGED_OUT: 'Logged out successfully',
}

export default AlertContext;