import { Link } from 'react-router-dom';

import OutlineButton from '../../Buttons/OutlineButton/OutlineButton';
import BaseP from '../../TextElements/BaseP/BaseP';
import ListItemHeader from '../../TextElements/ListItemHeader/ListItemHeader';

import styles from './PurchaseOptionItem.module.css';

import tastingCollection from '../../../../assets/images/tasting-collection-cart.webp';
import bottles from '../../../../assets/images/individual-bottles.webp';
import subscriptions from '../../../../assets/images/membership-options.webp';

const PurchaseOptionItem = props => {

	let image = <img src={tastingCollection} alt='Tasting Collection' className={styles.Image} />
	if (props.option.type === 'shop') image = <img src={bottles} alt='Bottles' className={styles.Image} />
	if (props.option.type === 'subscribe') image = <img src={subscriptions} alt='Subscriptions' className={styles.Image} />

	const btn = <OutlineButton clickHandler={props.option.clickHandler}>{props.option.CTA}</OutlineButton>

	const CTA = props.option.type === 'sample' ? btn : <Link to={props.option.link}>{btn}</Link>

	return (
		<div className={styles.OptionContainer}>
			<ListItemHeader passOnClasses='mb-3'>{props.option.title}</ListItemHeader>
			<div className='mb-4'>{image}</div>
			<BaseP>{props.option.description}</BaseP>
			{CTA}
		</div>
	)

}

export default PurchaseOptionItem;