import ListItemHeader from '../../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import ListItemInfoP from '../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import FeatureP from '../../../../components/UIElements/TextElements/FeatureP/FeatureP';
import AdditionalInfoP from '../../../../components/UIElements/TextElements/AdditionalInfoP/AdditionalInfoP';
import BtnLink from '../../../../components/UIElements/Buttons/BtnLink/BtnLink';

import everydayLuxuryImg from '../../../../assets/images/everyday-luxury.webp';
import refinedPalateImg from '../../../../assets/images/refined-palate.webp';
import trueConnoisseurImg from '../../../../assets/images/true-connoisseur.webp';

import styles from './Membership.module.css';

const Membership = props => {

	const unitPrice = `${props.membershipData.unitPrice/100}/set/month`;
	const totalPrice = `$${props.membershipData.unitPrice*props.membershipData.quantity/100}/m`
	const date = new Date(props.membershipData.nextInvoiceDate * 1000);
	const formattedBillingDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;

	let image = null;
	if (props.membershipData.productName === 'Everyday Luxury') image = <img src={everydayLuxuryImg} alt='Everyday Luxury' className={styles.Image} />
	if (props.membershipData.productName === 'Refined Palate') image = <img src={refinedPalateImg} alt='Refined Palate' className={styles.Image} />	
	if (props.membershipData.productName === 'True Connoisseur') image = <img src={trueConnoisseurImg} alt='True Connoisseur' className={styles.Image} />

	const cancelMembership = () => {
		props.cancelMembershipHandler(
			props.membershipData.subscriptionId, 
			props.membershipData.subscriptionItemId, 
			props.membershipData.productName
		);
	}	

	return (
		<div className={styles.Membership}>
			<div className={styles.ProductInfoWrapper}>
				<div className={styles.ImageWrapper}>
					{image}
				</div>
				<div className='flex-grow-1'>
					<FeatureP passOnClasses='mb-0'>{props.membershipData.productName}</FeatureP>
					<ListItemInfoP passOnClasses='mb-0'>{props.membershipData.description}</ListItemInfoP>
					<div className='d-flex'>
						<ListItemInfoP passOnClasses='mb-0 me-1'>{unitPrice}</ListItemInfoP>
						<div className={styles.SmallScreenQuantity}>
							<ListItemInfoP passOnClasses='mb-0'>{`X ${props.membershipData.quantity}`}</ListItemInfoP>
						</div>
					</div>
					<div className={styles.SmallScreenTotalPrice}>
						<ListItemInfoP passOnClasses='mb-0'><strong>{`Monthly price: ${totalPrice}`}</strong></ListItemInfoP>
					</div>
				</div>
				<div className={styles.Quantity}>
					<FeatureP passOnClasses='mb-0'>{props.membershipData.quantity}</FeatureP>
					<p className={styles.Small}>{`set${props.membershipData.quantity >1 ? 's' : ''}/month`}</p>
				</div>
				<div className={styles.TotalPrice}>
					<ListItemHeader>{totalPrice}</ListItemHeader>
				</div>
			</div>
			<div className={styles.BottomWrapper}>
				<ListItemInfoP passOnClasses='mb-0'>
					{`Next billing date: ${formattedBillingDate}`}
				</ListItemInfoP>
				<BtnLink 
					noWrap 
					clickHandler={()=>cancelMembership()}
				>
					Cancel
				</BtnLink>
			</div>
		</div>
	)

}

export default Membership;