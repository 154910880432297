import React from 'react';
import {Link} from 'react-router-dom';

import styles from './NavLink.module.css';

const NavLink = props => {

	const isActive = props.active ? styles.Active : null;

	return <Link to={props.destination} className={`${styles.NavLink} ${isActive}`}>{props.children}</Link>
}

export default NavLink;