import { createContext, useState, useContext } from 'react';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../helpers/analytics-helpers';

const BetaContext = createContext({
	modalIsShown: '',
	setModalIsShown: boolean => {},
	checkAccess: accessCode => {},
	giveAccess: ()=>{}
});

export const BetaContextProvider = props => {

	// TURN MODDAL BACK ON
	// const [modalIsShown, setModalIsShown] = useState(localStorage.getItem('TrueOliveBetaAccess') ? false : true);
	// Modal off
	const [modalIsShown, setModalIsShown] = useState(false);
	const [error, setError] = useState('');

	const checkAccess = accessCode => {
		if(accessCode === 'earlyaccess'){
    	setModalIsShown(false);
    	localStorage.setItem('TrueOliveBetaAccess', 'true');
    	ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.ACCESS_CODE_SUCCESS
	    })
    } else {
    	setError({message: 'Incorrect access code'});
    	ReactGA.event({
	    	category: eventCategories.ENGAGEMENT, 
	    	action: eventNames.ACCESS_CODE_ERROR
	    })
    }
	}

	// Give access from other places like restaurant-access-context
  const giveAccess = () => {
  	console.log('hello');
  	setModalIsShown(false);
  	localStorage.setItem('TrueOliveBetaAccess', 'true');
  }

	return (
		<BetaContext.Provider value={{
			modalIsShown: modalIsShown,
			setModalIsShown: setModalIsShown,
			checkAccess: checkAccess,
			giveAccess: giveAccess
		}}>
			{props.children}
		</BetaContext.Provider>
	)
}

export default BetaContext;
