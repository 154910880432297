import {useState, useEffect, useContext, Fragment} from 'react';

import AuthContext from '../../../store/auth-context';
import UserContext from '../../../store/user-context';

import ReAuthModal from '../../../components/Modals/ReAuthModal/ReAuthModal';

import EditAccountInfoForm from '../../../components/Forms/EditAccountInfoForm/EditAccountInfoForm';
import EditPasswordForm from '../../../components/Forms/EditPasswordForm/EditPasswordForm';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';

const AccountInfo = props => {

	const authCtx = useContext(AuthContext);
	const userCtx = useContext(UserContext);

	const [isModalShown, setIsModalShown] = useState(false);
	const [dataToUpdate, setDataToUpdate] = useState(null);

	const showModal = dataToUpdate => {
		setIsModalShown(true);
		setDataToUpdate(dataToUpdate);
		authCtx.resetStates();
	}

	const hideModal = () => {
		setIsModalShown(false);
		authCtx.resetStates();
	}

	return (
		<Fragment>
  		<ListItemHeader passOnClasses='d-none d-md-block mb-4'>Account information</ListItemHeader>
  		<EditAccountInfoForm showModal={showModal} />
  		<div className='py-4'>
  			<hr />
  		</div>
  		<ListItemHeader passOnClasses='mb-4'>Update your password</ListItemHeader>
  		<EditPasswordForm showModal={showModal}/>
  		<ReAuthModal
  			showModal={isModalShown} 
  			hideModal={hideModal} 
  			dataToUpdate={dataToUpdate}
  		/>
  	</Fragment>
	)
}

export default AccountInfo;