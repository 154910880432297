import { createContext, useState } from 'react';
import { getFBUserInfo, getFBUserInfoByEmail, createFBUser, updateFBUserInfo } from '../helpers/firebase-helpers';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../helpers/analytics-helpers';

const UserContext = createContext({
	userId: '',
	guestId:'',
	email:'',
	firstName: '',
	lastName: '',
	stripeCustomerId: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	resetUserData: ()=>{},
	createUser: userData => {},
	getUserInfo: userId => {},
	getUserInfoByEmail: email => {},
	updateUserInfo: (userId, currentEmail, dataToUpdate) => {}
});

export const UserContextProvider = props => {

	const [userId, setUserId] = useState('');
	const [guestId, setGuestId] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [lastName, setLastName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const resetUserData = () => {
		resetStates();
		setUserId('');
		setGuestId('');
		setEmail('');
		setFirstName('');
		setLastName('');
		setStripeCustomerId('');
	}

	const handleSuccess = (userId, guestId, email, firstName, lastName, stripeCustomerId) => {

		setUserId(userId);
		setGuestId(guestId);
		setEmail(email);
		setFirstName(firstName);
		setLastName(lastName);
		setStripeCustomerId(stripeCustomerId);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const createUser = async userData => {
		resetStates();
		setIsLoading(true);
		const user = await createFBUser(userData);
		if (user){
			return handleSuccess(
				userData.userId,
				userData.guestId,
				userData.email,
				userData.firstName,
				userData.lastName,
				userData.stripeCustomerId
			)
		} else {
			return handleFail({message: 'Failed to create a user'});
		}
	}

	const getUserInfo = async userId => {
		resetStates();
		setIsLoading(true);
		const user = await getFBUserInfo(userId);

		if (user){
			handleSuccess(
				userId,
				user.guestId,
				user.email,
				user.firstName,
				user.lastName,
				user.stripeCustomerId
			);
			return user;
		}else {
			handleFail({message: 'Failed to get user data'});
			return; 
		}
	}

	const getUserInfoByEmail = async email => {
		resetStates();
		setIsLoading(true);
		const user = await getFBUserInfoByEmail(email);
		if (user){
			handleSuccess(
				user.userId,
				user.guestId,
				email,
				user.firstName,
				user.lastName,
				user.stripeCustomerId
			);
			return user.userId;
		}else {
			handleFail({message: 'Failed to get user data'});
			return; 
		}
	}

	const updateUserInfo = async (userId, stripeCustomerId, currentEmail, dataToUpdate) => {
		resetStates();
		setIsLoading(true);

		const result = await updateFBUserInfo(userId, stripeCustomerId, currentEmail, dataToUpdate);
		if (result.type === 'success') {

			ReactGA.event({
        category: eventCategories.ACCOUNT, 
        action: eventNames.ACCOUNT_INFO_UPDATE_SUCCESS
      });      

			return handleSuccess(
				userId,
				guestId,
				dataToUpdate.email,
				dataToUpdate.firstName,
				dataToUpdate.lastName, 
				stripeCustomerId
			)
		}

		if (result.type === 'error') {

			ReactGA.event({
        category: eventCategories.ACCOUNT, 
        action: eventNames.ACCOUNT_INFO_UPDATE_ERROR
      }); 

			return handleFail({message: result.message});
		}

	}

	return (
		<UserContext.Provider value={{
			userId: userId,
			guestId: guestId,
			email: email,
			firstName: firstName,
			lastName: lastName,
			stripeCustomerId: stripeCustomerId,
			error: error,
			isLoading: isLoading,
			isSuccessful: isSuccessful,
			resetUserData: resetUserData,
			createUser: createUser,
			getUserInfo: getUserInfo,
			getUserInfoByEmail: getUserInfoByEmail,
			updateUserInfo: updateUserInfo
		}}>
			{props.children}
		</UserContext.Provider>
	)

}

export default UserContext;

