import styles from './PrimaryCTA.module.css';

const PrimaryCTA = props => {

	let btnClasses = 'btn btn-lg btn-primary'; 
	if (props.mediumSized) btnClasses = 'btn btn-primary'; 
	if (props.smallSized) btnClasses = 'btn btn-primary btn-sm'; 
	const XLargeStyles = props.xLargeSized ? styles.XLarge : '';

	return (
		<button 
			className={`${btnClasses} ${styles.PrimaryCTA} ${XLargeStyles}`} 
			disabled={props.disabled}
			onClick={props.clickHandler}
		>
			{props.children}
		</button>
	)
}

export default PrimaryCTA;