import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../../components/UIElements//TextElements/HeaderTagline/HeaderTagline';

import {makePossesive} from '../../../helpers/misc-helpers';

import styles from './Story.module.css';

const Story = props => {

	return (
		<div className='col-xl-10 py-5 mb-2 text-center d-md-flex align-items-center text-md-start'>
			<div className='d-flex align-items-center justify-content-center mb-4 mb-md-0 me-md-4'>
				<img 
					src={require(`../../../assets/images/influencers/${props.imageFileName}`)}
					alt={`${props.name}\'s image`}
					className={styles.Image}
				/>
			</div>
			<div>
				<SectionHeader passOnClasses='mb-2'><em>{`${makePossesive(props.name)} True Olive Story`}</em></SectionHeader>
				<HeaderTagline>{`"${props.story}"`}</HeaderTagline>
			</div>
		</div>
	)
}

export default Story;