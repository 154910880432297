import { createContext, useState, useContext } from 'react';
import UserContext from './user-context';
import AlertContext, {alertMessages}  from './alert-context';
import { useNavigate } from 'react-router-dom';
import { postData } from '../helpers/http-helpers';
import { registerWithFBAuth, signInWithFBAuth, updateFBPassword, sendFBPasswordResetEmail } from '../helpers/firebase-helpers';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../helpers/analytics-helpers';

const AuthContext = createContext({
	idToken: '',
	refreshToken: '',
	localId: '',
	isLoggedIn: '',
	isLoginFormSubmitted: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	fpIsLoading: '',
	fpError: '',
	fpIsSuccessful: '',
	initiateStates: ()=>{},
	setStates: ()=>{},
	setIsLoginFormSubmitted: boolean=>{},
	onLogin: userData => {},
	onRegister: userData => {},
	onLogout: ()=>{},
	onChangePassword: (email, password, newPassword) => {},
	onRefreshAuth: refreshToken => {},
	onForgotPassword: email => {}
})

export const AuthContextProvider = props => {

	const userCtx = useContext(UserContext);
	const alertCtx = useContext(AlertContext);
	const navigate = useNavigate();

	const [idToken, setIdToken] = useState(localStorage.getItem('TrueOliveIdToken'));
	const [refreshToken, setRefreshToken] = useState(localStorage.getItem('TrueOliveRefreshToken'));
	const [localId, setLocalId] = useState(localStorage.getItem('TrueOliveLocalId'));
	const [isLoggedIn, setIsLoggedIn] = useState(idToken ? true : false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);
	const [fpIsLoading, setFpIsLoading] = useState(false);
	const [fpError, setFpError] = useState(null);
	const [fpIsSuccessful, setFpIsSuccessful] = useState(null);

	
	const fbKey = process.env.REACT_APP_FIREBASE_API_KEY;

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const initiateStates = () => {
		setIsLoading(true);
		setError(null);
		setIsSuccessful(null);
	}

	const autoLogout = authDuration => {
		setTimeout(()=>{
			setIsLoggedIn(false);
			setIdToken('');
    	setLocalId('');
    	setRefreshToken('');

    	userCtx.resetUserData();
    	alertCtx.setAlert('success', alertMessages.LOGGGED_OUT);

			localStorage.removeItem('TrueOliveIdToken');
	  	localStorage.removeItem('TrueOliveLocalId');
		}, authDuration) 
	}

	const handleAuthSuccess = (idToken, refreshToken, localId, authDuration) => {
		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
		setIsLoggedIn(true);

		setIdToken(idToken);
		setRefreshToken(refreshToken);
		setLocalId(localId);

		localStorage.setItem('TrueOliveIdToken', idToken);
  	localStorage.setItem('TrueOliveRefreshToken', refreshToken);
  	localStorage.setItem('TrueOliveLocalId', localId);

  	autoLogout(authDuration);
	}

	const handleAuthFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const onLogin = async userData => {
		initiateStates();

		try{
			const authUser = await signInWithFBAuth(userData.email, userData.password);
			const tokens = authUser._tokenResponse;

			userCtx.getUserInfo(tokens.localId);
			handleAuthSuccess(
				tokens.idToken, 
				tokens.refreshToken, 
				tokens.localId, 
				tokens.expiresIn*1000
			);

			ReactGA.event({
	    	category: eventCategories.AUTHENTICATION, 
	    	action: eventNames.SIGN_IN_SUCCESS,
	    });

			return true;
		} catch (error) {
			handleAuthFail({message: error.message});

			ReactGA.event({
	    	category: eventCategories.AUTHENTICATION, 
	    	action: eventNames.SIGN_IN_SUCCESS,
	    });

			return false;
		}
	}

	const onRegister = async userData => {
		initiateStates();

		try {
			const authUser = await registerWithFBAuth(userData.email, userData.password);
			const tokens = authUser._tokenResponse;
			
			const user = await userCtx.createUser({
				userId: tokens.localId, 
				guestId: userData.guestId,
				stripeCustomerId: userData.stripeCustomerId,
				firstName: userData.firstName,
				lastName: userData.lastName,
				email: userData.email,
				createdAt: new Date()
			});

			handleAuthSuccess(
				tokens.idToken, 
				tokens.refreshToken, 
				tokens.localId, 
				tokens.expiresIn*1000 
			);

			ReactGA.event({
	      category: eventCategories.AUTHENTICATION, 
	      action: eventNames.ACCOUNT_CREATION_SUCCESS
	    });

			return tokens.localId;	
				
		} catch (error) {
			handleAuthFail({message: error.message});
			ReactGA.event({
	      category: eventCategories.AUTHENTICATION, 
	      action: eventNames.ACCOUNT_CREATION_ERROR
	    });

			return false;
		}
	}

	const onLogout = () => {
		resetStates();
		
		setIsLoggedIn(false);
		setIdToken('');
		setRefreshToken('');
		setLocalId('');

		userCtx.resetUserData();
		alertCtx.setAlert('success', alertMessages.LOGGGED_OUT);

		localStorage.removeItem('TrueOliveIdToken');
  	localStorage.removeItem('TrueOliveLocalId');
  	localStorage.removeItem('TrueOliveRefreshToken');
  	localStorage.removeItem('TrueOliveGuestId');
  	localStorage.removeItem('TrueOliveGuestEmail');
  	localStorage.removeItem('TrueOliveGuestStripeCustomerId');
	}

	const onRefreshAuth = async refreshToken => {
		resetStates();

		try{
			const response = await postData(
				`https://securetoken.googleapis.com/v1/token?key=${fbKey}`,
				{
					grant_type: 'refresh_token',
          refresh_token: refreshToken
				}
			);
			if (!response.ok) return handleAuthFail(response.error);
			userCtx.getUserInfo(response.data.user_id);
			return handleAuthSuccess(
				response.data.id_token, 
				response.data.refresh_token, 
				response.data.user_id, 
				response.data.expires_in*1000 
			);
		} catch (error) {
			console.log(error);
		}
	}

	const onChangePassword = async (email, password, newPassword) => {		
		initiateStates();
		const result = await updateFBPassword(email, password, newPassword);
		if (result.type === 'error') {
			ReactGA.event({
	    	category: eventCategories.ACCOUNT, 
	    	action: eventNames.PASSWORD_CHANGE_ERROR,
	    });

			setError({message: result.message});
			setIsLoading(false);
			setIsSuccessful(null);
		} else {
			ReactGA.event({
	    	category: eventCategories.ACCOUNT, 
	    	action: eventNames.PASSWORD_CHANGE_SUCCESS,
	    });

			setError(null);
			setIsLoading(false);
			setIsSuccessful(true);
		}
	}

	const onForgotPassword = async email => {
		
		setFpError(null);
		setFpIsSuccessful(null);
		setFpIsLoading(true);
		
		const result = await sendFBPasswordResetEmail(email);

		if (result.type === 'success') {
			setFpIsSuccessful(true);
			ReactGA.event({
	    	category: eventCategories.AUTHENTICATION, 
	    	action: eventNames.FORGOT_PASSWORD_SUCCESS,
	    });
		} else {
			setFpError({message: result.message});
			ReactGA.event({
	    	category: eventCategories.AUTHENTICATION, 
	    	action: eventNames.FORGOT_PASSWORD_ERROR,
	    });
		}

		setFpIsLoading(false);
		
	}

	return (
		<AuthContext.Provider value={{
			idToken: idToken,
			localId: localId,
			error: error,
			isLoading: isLoading,
			isSuccessful: isSuccessful,
			isLoggedIn: isLoggedIn,
			fpIsLoading: fpIsLoading,
			fpError: fpError,
			fpIsSuccessful: fpIsSuccessful,
			resetStates: resetStates,
			onLogin: onLogin,
			onRegister: onRegister,
			onLogout: onLogout,
			onChangePassword: onChangePassword, 
			onRefreshAuth: onRefreshAuth,
			onForgotPassword: onForgotPassword
		}}>
			{props.children}
		</AuthContext.Provider>
	)

}

export default AuthContext;