import ListItemInfoP from '../../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import FeatureP from '../../../../../components/UIElements/TextElements/FeatureP/FeatureP';

import styles from './DollarDiscountItem.module.css';

const DollarDiscountItem = props => {
	return (
		<div className={styles.DollarDiscountItem}>
			<FeatureP passOnClasses='mb-0'>Discount applied</FeatureP>
			<div className={styles.PriceWrapper}>
				<FeatureP passOnClasses='mb-0 ms-1 text-end'>{`-$${props.discountAmount}`}</FeatureP>
			</div>
		</div>
	);
}

export default DollarDiscountItem;