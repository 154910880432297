import ReactGA from 'react-ga4';

export const eventNames = {
	SUBMITTED_ACCESS_CODE: 'submittedAccessCode',
	SUBMITTED_RESTAURANT_ACCESS_CODE: 'submittedRestaurantAccessCode',
	ACCESS_CODE_SUCCESS: 'accessCodeSucess',
	ACCESS_CODE_ERROR: 'accessCodeError',
	RESTAURANT__CODE_SUCCESS: 'restaurantAccessCodeSucess',
	RESTAURANT_ACCESS_CODE_ERROR: 'restaurnatAccessCodeError',
	CLICKED_ON_CART: 'clickedOnCart',
	CLICKED_ON_NAV_ITEM: 'clickedOnNavItem',
	CLICKED_NAV_CTA: 'clickedNavCTA',
	SCROLLED_FROM_HERO: 'scrolledFromHero',
	SCROLLED_FROM_RESTAURANT_HERO: 'scrolledFromRestaurantHero',
	SCROLLED_FROM_INFLUENCER_HERO: 'scrolledFromInfluencerHero',
	SCROLLED_FROM_ACADEMY_HERO: 'scrolledFromAcademyHero',
	CLICKED_BOTTOM_CTA: 'clickedBottomCTA',
	CLICKED_PURCHASE_OPTION: 'clickedPurchaseOption',
	ADDED_ITEM_TO_CART: 'addedItemToCart',
	REMOVED_ITEM_FROM_CART: 'removedItemFromCart',
	INCREMENTED_CART_ITEM: 'incrementedCartItem',
	DECREMENTED_CART_ITEM: 'decrementedCartItem',
	CLICKED_CHECK_OUT: 'clickedCheckOut',
	CHECK_OUT_CREATION_SUCCESS: 'checkOutCreationSuccess',
	CHECK_OUT_CREATION_ERROR: 'checkOutCreationError',
	COMPLETED_PURCHASE: 'completedPurchase',
	CREATED_MEMBERSHIP: 'createdMembership',
	OPENED_SIGN_IN: 'openedSignIn',
	SUBMITTED_SIGN_IN: 'submittedSignIn',
	SIGN_IN_SUCCESS: 'signInSuccess',
	SIGN_IN_ERROR: 'signInError',
	OPENED_FORGOT_PASSWORD: 'openedForgotPassword',
	SUBMITTED_FORGOT_PASSWORD: 'submittedForgotPassword',
	FORGOT_PASSWORD_SUCCESS: 'forgotPasswordSucces',
	FORGOT_PASSWORD_ERROR: 'forgotPasswordError',
	CLICKED_ORDER_SHARE_OPTION: 'clickedOrderShareOption',
	CLICKED_RECIPE_SHARE_OPTION: 'clickedRecipeShareOption',
	SUBMITTED_ACCOUNT_CREATION: 'submittedAccountCreation',
	ACCOUNT_CREATION_SUCCESS: 'accountCreationSuccess',
	ACCOUNT_CREATION_ERROR: 'accountCreationError',
	SUBMITTED_ACCOUNT_INFO_UPDATE: 'submittedAccountInfoUpdate',
	ACCOUNT_INFO_UPDATE_SUCCESS: 'accountInfoUpdateSuccess',
	ACCOUNT_INFO_UPDATE_ERROR: 'accountInfoUpdateError',
	SUBMITTED_PASSWORD_CHANGE: 'submittedPasswordChange',
	PASSWORD_CHANGE_SUCCESS: 'passwordChangeSuccess',
	PASSWORD_CHANGE_ERROR: 'passwordChangeError',
	SUBMITTED_SHIPPING_INFO_UPDATE: 'submittedShippingInfoUpdate',
	SHIPPING_INFO_UPDATE_SUCCESS: 'shippingInfoUpdateSuccess',
	SHIPPING_INFO_UPDATE_ERROR: 'shippingInfoUpdateError',
	CLICKED_REMOVE_PAYMENT_METHOD: 'clickedRemovePaymentMethod',
	REMOVE_PAYMENT_METHOD_SUCCESS: 'removePaymentMethodSuccess',
	REMOVE_PAYMENT_METHOD_ERROR: 'removePaymentMethodError',
	CLICKED_CANCEL_MEMBERSHIP: 'clickedCancelMembership',
	CANCEL_MEMBERSHIP_SUCCESS: 'cancelMembershipSuccess',
	CANCEL_MEMBERSHIP_ERROR: 'cancelMembershipError',
	JUMPED_TO_RECIPES: 'jumpedToRecipes',
	CLICKED_ACADEMY_BANNER_CTA: 'clickedAcademyBannerCTA',
	CLICKED_ACADEMY_OPTION_CTA: 'clickedAcademyOptionCTA',
	CLICKED_HEALTH_BENEFIT_CTA: 'clickedHealthBenefitCTA',
	CLICKED_ACADEMY_BOTTOM_CTA: 'clickedAcademyBottomCTA',
	CLICKED_RECIPE_CTA: 'clickedRecipeCTA'
};

export const eventCategories = {
	ENGAGEMENT: 'engagement',
	AUTHENTICATION: 'authentication',
	INTENT: 'intent',
	REVENUE: 'revenue',
	ACCOUNT: 'account',
	VIRALITY: 'virality'
};