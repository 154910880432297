import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';
import VerticalCard from '../../../components/UIElements/VerticalCard/VerticalCard';

const Explanation = props => {

	const optionList = props.options.map(option=> {return(
		<div className='col-lg-4 mb-4' key={option.id}><VerticalCard card={option} /></div>
	)});


	return (
		<div className='pt-5 pb-1 mb-2'>
			<SectionHeader noCapitalization passOnClasses='mb-2 text-center text-lg-start'>
				All you need for a life rich in health and sensation
			</SectionHeader>
			<HeaderTagline passOnClasses='text-center text-lg-start'>
				Many people are vaguely familiar with the health and culinary benefits of olive oil, but 
				they don’t know the specifics. Our guide to health benefits of olive oil, instructions on 
				how to select good olive oil, and curated recipes from easy and quick dishes to 
				sophisticated masterpieces will help you get the most out of olive oil.
			</HeaderTagline>
			<div className='row py-5'>
				{optionList}
			</div>
		</div>
	)
}

export default Explanation;