import FeatureP from '../../TextElements/FeatureP/FeatureP';
import OutlineButton from '../../Buttons/OutlineButton/OutlineButton';
import {Link} from 'react-router-dom';

import styles from './CartMessage.module.css';

const CartMessage = props => {
	return (
		<div className={styles.CartMessage}>
			<FeatureP passOnClasses='mb-3'>You don't have any items in your cart yet. Add your first item.</FeatureP>
			<Link to='/our-olive-oils'>
				<OutlineButton passOnClasses='w-100' clickHandler={props.hide}>Explore our olive oils</OutlineButton>
			</Link>
		</div>
	)

}

export default CartMessage