import LeadP from '../../../../components/UIElements/TextElements/LeadP/LeadP';
import FeatureP from '../../../../components/UIElements/TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import ListItemHeader from '../../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';
import OptionsWrapper from '../../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import OrderItem from './OrderItem/OrderItem';
import ShippingItem from './ShippingItem/ShippingItem';
import TaxItem from './TaxItem/TaxItem';
import DollarDiscountItem from './DollarDiscountItem/DollarDiscountItem';
import BtnLink from '../../../../components/UIElements/Buttons/BtnLink/BtnLink';

import {transformSecondsToDisplayDate} from '../../../../helpers/misc-helpers';

import styles from './Order.module.css';

const Order = props => {

	const orderDate = transformSecondsToDisplayDate(props.order.createdAt.seconds);	

	const orderItems = props.order.itemsPurchased.map(item=><OrderItem item={item} key={item.itemName} />)

	let dollarDiscountElement = null;
	if (props.order.promotionCode) {
		if (props.order.promotionCode.amountOff>0) dollarDiscountElement = <DollarDiscountItem discountAmount={props.order.promotionCode.amountOff} />
	} 

	let trackingInfo = <ListItemInfoP passOnClasses='mb-0'>Tracking information not available yet</ListItemInfoP>
	if (props.order.trackingURL) {
		trackingInfo = (
			<a href={props.order.trackingURL} target='_blank'>
				<BtnLink><small>Track Package</small></BtnLink>
			</a>
		) 
	}

	console.log(props.order);

	return (
		<div className={styles.Order}>
			<div className={styles.Header}>
				<div>
					<ListItemInfoP passOnClasses='mb-0'>Order placed on:</ListItemInfoP>
					<LeadP passOnClasses='mb-0'>{orderDate}</LeadP>
				</div>
				<ListItemHeader>{`$${props.order.totalPrice}`}</ListItemHeader>
			</div>
			<div className='py-3'>
				{orderItems}
				<ShippingItem 
					shippingPrice={props.order.shippingPrice ? props.order.shippingPrice : 0} 
				/>
				{(props.order.tax > 0) && <TaxItem taxAmount={props.order.tax} />}
				{dollarDiscountElement}
			</div>
			<OptionsWrapper>
				<div className='d-md-flex align-items-center'>
					<FeatureP passOnClasses='mb-0 me-3'>Shipping <br className='d-none d-md-block'/>address</FeatureP>
					<div>
						<ListItemInfoP passOnClasses='mb-0'>
							{`${props.order.firstName} ${props.order.lastName} - ${props.order.email}`}
						</ListItemInfoP>
						<ListItemInfoP passOnClasses='mb-0'>{props.order.address.line1}</ListItemInfoP>
						<ListItemInfoP passOnClasses='mb-0'>{props.order.address.line2}</ListItemInfoP>
						<ListItemInfoP passOnClasses='mb-0'>
							{`${props.order.address.city}, ${props.order.address.state}, ${props.order.address.country}, ${props.order.address.postal_code}`}
						</ListItemInfoP>
						{trackingInfo}
					</div>
				</div>
			</OptionsWrapper>
		</div>
	)

} 

export default Order;