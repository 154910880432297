const pageTitles = {
	'/': 'True Olive Co - Home',
	'our-story': 'True Olive Co - Our Story',
	'our-olive-oils': 'True Olive Co - Our Olive Oils',
	'become-a-member': 'True Olive Co - Become A Member',
	'restaurants': 'True Olive Co - Restaurants',
	'register': 'True Olive Co - Register',
	'create-account': 'True Olive Co - Track Orders',
	'order-confirmation': 'True Olive Co - Order Confirmation',
	'account': 'True Olive Co - Your Account',
	'academy': 'True Olive Co - Academy',
	'health-benefits': 'True Olive Co - Health Benefits',
	'choosing-olive-oil': 'True Olive Co - Choosing Olive Oil',
	'recipes': 'True Olive Co - Recipes',
}

export const selectPageTitle = path => {
	let selector = '';
	if (path === '/') selector = '/'


	const adjustRecipeName = slug => {
		const titleArray = slug.split('-');
		const capitalizedTitleArray = [];
		titleArray.map(word=>capitalizedTitleArray.push(word.charAt(0).toUpperCase() + word.slice(1)));
		const title = capitalizedTitleArray.toString().replace(/,/g, ' ');
		return title
	}	
	
	const pathArray = path.split('/');
	if (pathArray.length === 2) selector = pathArray[1];  
	if (pathArray[1] ==='academy' && pathArray.length === 3) selector = pathArray[2];
	if (pathArray[2] ==='recipes' && pathArray.length === 4) {
		return `True Olive Co - ${adjustRecipeName(pathArray[3])}`;
	}

	return pageTitles[`${selector}`] || 'True Olive Co';
}