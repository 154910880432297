import { useContext } from 'react';
import PromotionCodeContext from '../../../store/promotion-code-context';

import tagIcon from '../../../assets/images/tag-icon.webp';
import styles from './PromoBanner.module.css';

const PromoBanner = props => {

	const promoCodeCtx = useContext(PromotionCodeContext);

	let discountCopy = '';
	if (promoCodeCtx.amountOff) {
		discountCopy = `$${promoCodeCtx.amountOff} off applied through code "${promoCodeCtx.name}"`
	}
	if (promoCodeCtx.percentOff) {
		discountCopy = `${promoCodeCtx.percentOff}% off applied through code "${promoCodeCtx.name}"`
	}

	return (
		<div className={styles.PromoBanner}>
			<img src={tagIcon} alt='Tag icon' className={styles.Icon} />
			<p className={styles.PromoCopy}>{discountCopy}</p>
		</div>
	)
}

export default PromoBanner;