import HeaderTagline from '../TextElements/HeaderTagline/HeaderTagline';

import foundersPic from '../../../assets/images/founders.webp';

import styles from './BottomContact.module.css';

const BottomContact = props => {

	return (
		<section className={styles.BottomContact}>
			<div className='container'>
				<div className='row'>
					<div className='col-12 d-md-flex align-items-center'>
						<img src={foundersPic} alt='True Olive Founders' className={`${styles.Image} mb-3 mb-md-0 me-md-3`} />
						<div>
							<h1 className={styles.ContactHeader}>Additional questions? Special requests?</h1>
							<HeaderTagline>
								We are Nicole & Can. You can reach us directly 
								via <a href='mailto:founders@trueolive.co' className={styles.Email}>founders@trueolive.co</a>.
							</HeaderTagline>
						</div>
					</div>
				</div>
			</div>
		</section>
	)

}

export default BottomContact;