import {useContext, useState, useEffect, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import GuestContext from '../../store/guest-context';
import OrdersContext from '../../store/orders-context';
import UserContext from '../../store/user-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import SignInToAccount from './SignInToAccount/SignInToAccount';
import CreateNewAccount from './CreateNewAccount/CreateNewAccount';
import CreateAccountForm from '../../components/Forms/CreateAccountForm/CreateAccountForm';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import BaseP from '../../components/UIElements/TextElements/BaseP/BaseP';
import Loader from '../../components/UIElements/Loader/Loader';
import Error from '../../components/UIElements/Error/Error';

const CreateAccount = props => {

	const authCtx = useContext(AuthContext);
	const guestCtx = useContext(GuestContext);
	const ordersCtx = useContext(OrdersContext);
	const userCtx = useContext(UserContext);

	const [fetchIsLoading, setFetchIsLoading] = useState(true);
	const [fetchError, setFetchError] = useState(false);
	const [accountIsLoading, setAccountIsLoading] = useState(false);
	const [accountLoaded, setAccountLoaded] = useState(true);

	const [orderCount, setOrderCount] = useState(0);
	const [hasAccount, setHasAccount] = useState(false);

	const navigate = useNavigate();

	const queryParams = new Proxy(new URLSearchParams(window.location.search), {
	  get: (searchParams, prop) => searchParams.get(prop),
	});

	const fetchData = async guestId => {
		setFetchIsLoading(true);
		setFetchError(false);

		let orders = [];

		// 1 - Get the guest data
		const guest = await guestCtx.getGuestInfo(guestId);
		
		if (guest) {
			// 2 - If guest has userId show sign in form instead, fetch the user and pull the orders from the user
			if (guest.userId){
				const user = await userCtx.getUserInfo(guest.userId);
				orders = await ordersCtx.getOrdersByUser(guest.userId); 
				setHasAccount(true);
			// 3 - Else: Get the orders associated with the guest
			} else { 
				orders = await ordersCtx.getOrdersByGuest(guestId);
				setHasAccount(false); 
			}
			setOrderCount(orders.length);
		}

		if (!guest || ! orders) setFetchError(true);

		setFetchIsLoading(false);
	
	}

	useEffect(()=>{
		// NAVIGATE AWAY IF CONDITIONS ARE NOT MET
		if (authCtx.isLoggedIn){
			navigate('/account?tab=orders');
		} else if(!queryParams.guestId){
			navigate('/');
		} 

		// FETCH DATA
		const response = fetchData(queryParams.guestId);

	},[])
	
	let mainContent = null;
	if (fetchIsLoading) mainContent = <Loader>Loading your information...</Loader> 
	if (fetchError) {
		mainContent = (
			<Error>
				We failed to retrieve the information about your orders. Please contact us on support@trueolive.co.
			</Error>	
		);
	} 
	if (!fetchIsLoading && !hasAccount && orderCount > 0) {
		mainContent = (
			<CreateNewAccount
				email={guestCtx.email}
				orderCount={orderCount}
				isAccountLoading={accountIsLoading}
				setIsAccountLoading={setAccountIsLoading} 
				setIsAccountSuccessful={setAccountLoaded}
			/>
		)
	}
	if (hasAccount) {
		mainContent = (
			<SignInToAccount
				email={userCtx.email}
				orderCount={orderCount}
				setIsAccountLoading={setAccountIsLoading} 
				isAccountLoading={accountIsLoading} 
				setIsAccountSuccessful={setAccountLoaded}
			/>
		) 
	} 	

	return (
		<MainLayout>
			<div className='container py-5'>
				<div className='row py-5'>
					<div className='py-5 col-lg-10 offset-lg-1'>
						{mainContent}
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default CreateAccount;