import {Fragment} from 'react';

import OptionsWrapper from '../../../components/UIElements/OptionsWrapper/OptionsWrapper';
import Loader from '../../../components/UIElements/Loader/Loader';
import CreateAccountForm from '../../../components/Forms/CreateAccountForm/CreateAccountForm';

import SectionHeader from '../../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import BaseP from '../../../components/UIElements/TextElements/BaseP/BaseP';
import ListItemInfoP from '../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';

const CreateNewAccount = props => {

	let contentArea = (
		<Fragment>
			<CreateAccountForm 
				setIsAccountLoading={props.setIsAccountLoading} 
				setIsAccountSuccessful={props.setIsAccountSuccessful}
				createAccountPage
			/>
			<ListItemInfoP>We never share your information with 3rd parties.</ListItemInfoP>
		</Fragment>
	)
	if (props.isAccountLoading) contentArea = <Loader dark>Creating your account...</Loader> 

	const isPlural = props.orderCount > 1 ? 's' : '';

	return ( !props.isAccountSuccessful &&
		<Fragment>
			<BaseP passOnClasses='mb-0'>{`You have ${props.orderCount} order${isPlural} under ${props.email}`}</BaseP>
			<OptionsWrapper passOnClasses='mb-5 mt-5 pb-4'> 
				<SectionHeader passOnClasses='mb-3'>Create your account to track your orders</SectionHeader>
				{contentArea}
			</OptionsWrapper>
		</Fragment>
	)
}

export default CreateNewAccount;