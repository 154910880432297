import {useState, Fragment} from 'react';
import EditShippingInfoForm from '../../../components/Forms/EditShippingInfoForm/EditShippingInfoForm';
import {Link} from 'react-router-dom';
import OutlineButton from '../../../components/UIElements/Buttons/OutlineButton/OutlineButton';
import FeatureP from '../../../components/UIElements/TextElements/FeatureP/FeatureP';
import BtnLink from '../../../components/UIElements/Buttons/BtnLink/BtnLink';
import Error from '../../../components/UIElements/Error/Error';
import Loader from '../../../components/UIElements/Loader/Loader';
import ListItemHeader from '../../../components/UIElements/TextElements/ListItemHeader/ListItemHeader';

const ShippingInfo = props => {

	const [showEditShippingInfo, setShowEditShippingInfo] = useState(false);

	const controlShippingFormDisplay = boolean => {
  	setShowEditShippingInfo(boolean)
  }

	let displayContent = null 
	if (props.info.address) displayContent = (
		<Fragment>
			<FeatureP passOnClasses={'mb-0'}>{props.info.name}</FeatureP>
			<FeatureP passOnClasses={'mb-0'}>{props.info.phone}</FeatureP>
			<FeatureP passOnClasses={'mb-0'}>{props.info.address.line1}</FeatureP>
			<FeatureP passOnClasses={'mb-0'}>{props.info.address.line2}</FeatureP>
			<FeatureP passOnClasses={'mb-0'}>
				{`${props.info.address.city}, 
					${props.info.address.state}, 
					${props.info.address.country}, 
					${props.info.address.postal_code}`}
			</FeatureP>
			<BtnLink clickHandler={()=>controlShippingFormDisplay(true)}>Edit shipping address</BtnLink>
		</Fragment>
	);

	if (!props.info.address) displayContent = (
		<Fragment>
			<FeatureP>
				You don't have a shipping addresses on file yet. You can create one with your first order.
			</FeatureP>
			<Link to='/our-olive-oils'>
				<OutlineButton>Explore our olive oils</OutlineButton>
			</Link>
		</Fragment>
	);

	if(props.isLoading) displayContent = <Loader>Loading your shipping information...</Loader>;
	if (props.Error) displayContent = <Error errorCode={props.error}>There was an error loading your shipping information</Error>;

	if (showEditShippingInfo) displayContent = (
		<EditShippingInfoForm 
			info={props.info} 
			controlFormDisplay={controlShippingFormDisplay}
			updateShippingInfoState={dataToUpdate => props.setShippingInfo(dataToUpdate)}
		/>
	);
	
	return (
		<Fragment>
			<ListItemHeader passOnClasses='d-none d-md-block mb-4'>Shipping information</ListItemHeader>
			{displayContent}
		</Fragment>
	
	)	
}

export default ShippingInfo;