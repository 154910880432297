import { useContext, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import RecipesContext from '../../store/recipes-context';
import { easeTransform, goodForTransform } from '../../helpers/misc-helpers';

import {Link} from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';
import BtnLink from '../../components/UIElements/Buttons/BtnLink/BtnLink';
import CardHeader from '../../components/UIElements/TextElements/CardHeader/CardHeader';
import FeatureP from '../../components/UIElements/TextElements/FeatureP/FeatureP';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import Tags from './Tags/Tags';
import SocialSharing from './SocialSharing/SocialSharing';
import Loader from '../../components/UIElements/Loader/Loader';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

import styles from './Recipe.module.css';

const Recipe = () => {

	const bottomCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BOTTOM_CTA,
    	label: 'shop'
    });
	}

	const recipesCtx = useContext(RecipesContext);
	const {recipeSlug} = useParams();
	
	useEffect(()=>{
		const fetchData = async slug => {
			const recipe = await recipesCtx.getRecipeBySlug(slug);
			return recipe;
		};
		fetchData(recipeSlug);
	},[]);

	const banner = {
		image: 'academy/academy-banner-3.webp',
		mobileImage: 'academy/banner-3-mobile.webp',
		altText: 'Use gold-award winning True Olive with this recipe',
		title: 'Use gold-award winning True Olive',
		body: 'Experience the best taste and health benefits olive oil has to offer with our gold-award winning olive oil from our family-farm in the Dardanelles.',
		CTA: 'Shop True Olive',
		linkTo: '/',
		analyticsHandler: ()=>bottomCTAAnalyticsHandler()
	}

	const shareMessage = `You have to try ${recipesCtx.selectedRecipe.title} from True Olive 
		Academy. They have amazing recipes for those who want to incorporate more olive oil in 
		their cooking.`

	let ingredients = null;
	let directions = null;
	let instagramLink = null;

	if (recipesCtx.selectedRecipe) {
		ingredients = recipesCtx.selectedRecipe.ingredients.map((ingredient, index)=>{
			return <FeatureP key={index} passOnClasses='mb-0'>{ingredient}</FeatureP>
		});
		directions = recipesCtx.selectedRecipe.directions.map((direction, index)=>{
			return <FeatureP key={index} passOnClasses='mb-2'>{direction}</FeatureP>
		});
		if(recipesCtx.selectedRecipe.instagramLink){
			instagramLink = (
				<a href={recipesCtx.selectedRecipe.instagramLink} target='_blank'>
					<BtnLink passOnClasses='mb-3'>More images and videos on instagram</BtnLink>
				</a>
			)
		}
	} 

	let content = null;
	if (recipesCtx.isLoading) {
		content = (
			<div className='row'>
				<div className='col-12 pt-5'>
					<Loader>Recipe loading</Loader>
				</div>
			</div>
		)
	}
	if (recipesCtx.selectedRecipe) {
		content = (
			<Fragment>
				<div className='row'>
					<div className='col-12 pt-5'>
						<Link to='/academy/recipes'><BtnLink passOnClasses='mb-3'>{`<< Back to recipes`}</BtnLink></Link>
						<SectionHeader noCapitalization passOnClasses='mb-2'>
							{recipesCtx.selectedRecipe.title}
						</SectionHeader>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-8 order-2 order-md-1'>
						<HeaderTagline passOnClasses='mb-3'>{recipesCtx.selectedRecipe.introduction}</HeaderTagline>
						<img 
							src={require(`../../assets/images/academy/recipes/${recipesCtx.selectedRecipe.imageName}`)}
							alt={recipesCtx.selectedRecipe.title}
							className={styles.Image}
						/>
						{instagramLink}
						<CardHeader>Ingredients</CardHeader>
						<div className='mb-4'>{ingredients}</div>
						<CardHeader>Directions</CardHeader>
						<div className='mb-4'>{directions}</div>
					</div>
					<div className='col-md-4 col-xl-3 order-1 order-md-2'>
						<Tags 
							ease={easeTransform(recipesCtx.selectedRecipe.ease)}
							goodFor={goodForTransform(recipesCtx.selectedRecipe.goodFor)}
							oliveOils={recipesCtx.selectedRecipe.oliveOils.join(', ')}
							otherTags={recipesCtx.selectedRecipe.otherTags.join(', ')}
						/>
						<SocialSharing 
							slug={recipeSlug}
							shareMessage={shareMessage}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-12 pt-4 pb-5'>
						<ImageBanner banner={banner}/>
					</div>
				</div>
			</Fragment>
		)
	}

	return (
		<MainLayout academy>
			<div className='container'>
				{content}
			</div>
		</MainLayout>
	)

}

export default Recipe;