import React from 'react';

import bottle from '../../../assets/images/750-private-reserve.png';
import downArrow from '../../../assets/images/down-arrow.webp';

import styles from './Hero.module.css';

const Hero = props => {

	const iconStyles = {
		height: '32px',
		width: '32px',
		background: `url(${downArrow})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	}

	return (
		<section className={styles.Hero}>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-9'>
						<h1 className={styles.Header}>
							Taste <em className='me-1'>what's</em> True
						</h1>
						<p className={styles.Tagline}>
							Produced from centuries old trees in the Dardanelles, without pesticides 
							or harmful chemicals, our gold-medal-winning olive oils uplift, nourish, 
							and bring the people to the table.
						</p>
						<button className={`btn btn-link ${styles.CTA}`} onClick={props.handleScroll}>
							<div className={styles.Icon} style={iconStyles}></div>
							<span className={styles.LongCopy}>Available for select restaurants</span>
							<span className={styles.ShortCopy}>See your options</span>
						</button>
					</div>
				</div>
			</div>
			{/* 
				<div className={styles.BottleWrapper}>
					<img src={bottle} className={styles.Bottle}/>
				</div>
			*/}
			
		</section>
	)
}

export default Hero;