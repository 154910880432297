import {useRef} from 'react';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Hero from './Hero/Hero';
import Explanation from './Explanation/Explanation';
import GoodHands from './GoodHands/GoodHands';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const Academy = () => {

	const ref = useRef(null);

	const handleScroll = () => {
	  ref.current?.scrollIntoView({behavior: 'smooth'});
	  ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.SCROLLED_FROM_ACADEMY_HERO,
    });
	};

	const recipesAnalyticsHandler = () =>{
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.JUMPED_TO_RECIPES
    });
	}

	const optionAnalyticsHandler = type => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_OPTION_CTA,
    	label: type
    });
	}

	const options = [
		{
			id: 0,
			image: 'health-benefits.webp',
			altText: 'Health benefits',
			title: 'Health Benefits',
			body: 'Extra virgin olive oil promotes cardiovascular health, memory and brain function, gut health, improved immune function and more.',
			CTA: 'Learn About Health Benefits',
			linkType: 'internal',
			linkTo: '/academy/health-benefits',
			analyticsHandler: () => optionAnalyticsHandler('healthBenefits')
		},
		{
			id: 1,
			image: 'choosing-olive-oil.webp',
			altText: 'Choosing olive oil',
			title: 'Choosing Olive Oil',
			body: 'Olive oil grown, harvested and pressed using best practices will provide superior benefits and taste. Learn how to choose the best olive oil.',
			CTA: 'How To Choose Olive Oil',
			linkType: 'internal',
			linkTo: '/academy/choosing-olive-oil',
			analyticsHandler: () => optionAnalyticsHandler('choosingOliveOil')
		},
		{
			id: 2,
			image: 'recipes.webp',
			altText: 'Recipes',
			title: 'Recipes',
			body: 'We curated recipes from the Mediterranean and beyond, including generations old family recipes, that will delight and nurture your senses.',
			CTA: 'Uplevel With Recipes',
			linkType: 'internal',
			linkTo: '/academy/recipes',
			analyticsHandler: () => optionAnalyticsHandler('recipes')
		},
	];

	return (
		<MainLayout academy>
			<Hero handleScroll={handleScroll} recipesAnalyticsHandler={recipesAnalyticsHandler}/>
			<div className='container'>
				<div className='row' ref={ref}>
					<Explanation options={options}/>
					<GoodHands />
				</div>
			</div>
		</MainLayout>
	)
}

export default Academy;