import { createContext, useState } from 'react';
import { createFBOrder, updateAllGuestOrders, getAllGuestOrders, getAllUserOrders } from '../helpers/firebase-helpers';

const OrdersContext = createContext({
	orders: [],
	isLoading: '',
	error: '',
	isSuccessful: '',
	getOrderCount: ()=>{},
	getOrdersByGuest: guestId => {},
	getOrdersByUser: userId => {},
	createOrder: orderData => {},
	updateAllOrdersByGuest: (guestId, userId) => {}
});

export const OrdersContextProvider = props => {

	const [orders, setOrders] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}


	const handleSuccess = orders => {
		setOrders(orders);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const getOrderCount = () => {
		return orders.length;
	}

	const getOrdersByGuest = async guestId => {
		resetStates();
		setIsLoading(true);
		const orders = await getAllGuestOrders(guestId)
		if (orders) {
			handleSuccess(orders);
			return orders
		} else {
			handleFail({message: 'No orders associated with this guest'});
			return;
		}
	}

	const getOrdersByUser = async userId => {
		resetStates();
		setIsLoading(true);
		const orders = await getAllUserOrders(userId)
		if (orders) {
			handleSuccess(orders);
			return orders
		} else {
			handleFail({message: 'No orders associated with this user'});
			return;
		}
	}

	const createOrder = async orderData => {
		resetStates();
		setIsLoading(true);
		const order = await createFBOrder(orderData)
		if (order) {
			const updatedOrders = [...orders, order];
			handleSuccess(updatedOrders);
			return updatedOrders;
		} else {
			handleFail({message: 'Order creation failed'});
			return;
		}
	}

	const updateAllOrdersByGuest = async (guestId, userId) => {
		resetStates();
		setIsLoading(true);
		const updatedOrders = await updateAllGuestOrders(guestId, {userId: userId})
		if (updatedOrders) {
			handleSuccess(updatedOrders);
			return updatedOrders;
		} else {
			handleFail({message: "Couldn't update the orders"});
			return;
		}
	}

	return (
		<OrdersContext.Provider value={{
			orders: orders,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			getOrderCount: getOrderCount,
			getOrdersByGuest: getOrdersByGuest,
			getOrdersByUser: getOrdersByUser,
			createOrder: createOrder,
			updateAllOrdersByGuest: updateAllOrdersByGuest
		}}>
			{props.children}
		</OrdersContext.Provider>
	)

}

export default OrdersContext;

