import styles from './Input.module.css'; 

const Input = props => {

  let inputStyles = 'form-control';
  if (props.field.hasError) inputStyles = `form-control is-invalid`;
  if (props.field.isValid) inputStyles = `form-control is-valid ${styles.IsValid}`;

  return (
    <div className='mb-3'>
      <label 
        htmlFor={props.field.id}
        className='form-label'
       >
        {props.field.label}
      </label>
      <input 
        type={props.field.type}
        id={props.field.id} 
        disabled={props.field.disabled}
        onChange={props.field.valueChangedHandler}
        onBlur={()=>props.field.touchHandler(true)} 
        onFocus={()=>props.field.touchHandler(false)} 
        value={props.field.value}
        className={inputStyles} 
      />
      {
        props.field.helpText && <div className='form-text'>{props.field.helpText}</div>
      }
      {
         props.field.hasError && <div className='invalid-feedback'>{props.field.errorMessage}</div>
      }
    </div>
)
}

export default Input;