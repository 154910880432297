import { useContext, useEffect } from 'react';
import RecipesContext from '../../store/recipes-context';
import { easeTransform, goodForTransform } from '../../helpers/misc-helpers';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import HeaderTagline from '../../components/UIElements/TextElements/HeaderTagline/HeaderTagline';
import VerticalCard from '../../components/UIElements/VerticalCard/VerticalCard';
import ImageBanner from '../../components/UIElements/ImageBanner/ImageBanner';
import Loader from '../../components/UIElements/Loader/Loader';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../helpers/analytics-helpers';

const Recipes = () => {

	const bottomCTAAnalyticsHandler = () => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ACADEMY_BOTTOM_CTA,
    	label: 'shop'
    });
	}

	const recipeAnalyticsHandler = recipeName => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_RECIPE_CTA,
    	label: recipeName
    });
	}

	const recipesCtx = useContext(RecipesContext);

	useEffect(()=>{
		if(recipesCtx.selectedRecipe) recipesCtx.resetSelectedRecipe();
		if (recipesCtx.recipes.length === 0) recipesCtx.getRecipes(); 
	}, [recipesCtx.recipes]);

	const createRecipeCard = recipe => {
		return {
			id: recipe.recipeId,
			image: `recipes/${recipe.imageName}`,
			altText: recipe.title,
			title: recipe.title,
			body: recipe.introduction,
			CTA: 'Go to Recipe',
			linkType: 'internal',
			linkTo: `/academy/recipes/${recipe.slug}`,
			tags: [easeTransform(recipe.ease, 'tag'), goodForTransform(recipe.goodFor)],
			analyticsHandler: () => recipeAnalyticsHandler(recipe.title)
		}
	}

	let recipeList = null;
	if (recipesCtx.recipes.length ===0) recipeList = <div className='col-12'><h3>There aren't any recipes available</h3></div>
	if (recipesCtx.isLoading) recipeList = <div className='col-12'><Loader>Recipes are loading</Loader></div>
	if (recipesCtx.recipes.length>0) {
		recipeList = recipesCtx.recipes.map(recipe=>{
			const recipeCard = createRecipeCard(recipe);
			return (
				<div className='col-md-6 col-lg-4 mb-4' key={recipeCard.id}><VerticalCard card={recipeCard} /></div>
			)
		})
	}

	const banner = {
		image: 'academy/academy-banner-3.webp',
		mobileImage: 'academy/banner-3-mobile.webp',
		altText: 'Use gold-award winning True Olive',
		title: 'Use gold-award winning True Olive with these recipes',
		body: 'Experience the best taste and health benefits olive oil has to offer with our gold-award winning olive oil from our family-farm in the Dardanelles.',
		CTA: 'Shop True Olive',
		linkTo: '/',
		analyticsHandler: ()=>bottomCTAAnalyticsHandler()
	}

	return (
		<MainLayout academy>
			<div className='container'>
				<div className='row'>
					<div className='col-12 py-5 mb-2'>
						<SectionHeader noCapitalization passOnClasses='mb-2 text-center text-lg-start'>
							Curated recipes starring olive oil 
						</SectionHeader>
						<HeaderTagline passOnClasses='text-center text-lg-start'>
							We curated recipes from the Dardanelles, the wider Mediterranean, and beyond, including 
							generations old family recipes. They will delight and nurture your senses. 
						</HeaderTagline>
					</div>
				</div>
				<div className='row'>
					{recipeList}
				</div>
				<div className='row'>
					<div className='col-12 pt-4 pb-5'>
						{recipesCtx.recipes.length>0 && <ImageBanner banner={banner}/>}
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default Recipes;
