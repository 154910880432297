import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import RegistrationForm from '../../components/Forms/RegistrationForm/RegistrationForm';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';

const RegisterPage = props => {
	return (
		<MainLayout>
			<div className='container py-5'>
				<div className='row py-5'>
					<div className='py-5 col-md-8 offset-md-2 col-lg-6 offset-lg-2'>
						<SectionHeader passOnClasses='mb-3'>Create your account</SectionHeader>
						<RegistrationForm />
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default RegisterPage;