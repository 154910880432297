import {Fragment, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import useInput from '../../../hooks/use-input';

import AuthContext from '../../../store/auth-context';
import AlertContext, {alertMessages} from '../../../store/alert-context';

import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import Form from '../../UIElements/Form/Form';
import BtnLink from '../../UIElements/Buttons/BtnLink/BtnLink';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import {validateEmail, validatePassword} from '../../../helpers/validation-functions';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const SignInForm = props => {

  const navigate = useNavigate();

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const authCtx = useContext(AuthContext);
  const alertCtx = useContext(AlertContext);

	const email = useInput(validateEmail);
	const password = useInput(validatePassword);

	const fields = [
		{
			fid: 0,
      label: 'Your email',
      id: `${props.inline ? 'inline-' :''}email`,
      type: 'email',
      errorMessage: 'Please enter a valid email',
      value: props.email ? props.email : email.value,
      disabled: props.email ? true : false,
      isValid: email.isValid,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
		},
		{
			fid: 1,
      label: 'Your password',
      id: `${props.inline ? 'inline-' :''}password`,
      type: `password`,
      errorMessage: 'Please enter a valid password (at least 6 characters)',
      value: password.value,
      isValid: password.isValid,
      hasError: password.hasError,
      valueChangedHandler: password.valueChangedHandler,
      touchHandler: password.touchHandler,
      reset: ()=>password.reset()
		}
	];

	const submitHandler = async event => {
    if (props.setIsAccountLoading && props.setIsAccountSuccessful){
      props.setIsAccountLoading(true);
      props.setIsAccountSuccessful(false);
    }

    event.preventDefault();

    ReactGA.event({
      category: eventCategories.AUTHENTICATION, 
      action: eventNames.SUBMITTED_SIGN_IN
    });

    fields.map(field=>field.reset());
    const user = await authCtx.onLogin({
      email: props.email ? props.email : email.value,
      password: password.value
    });

    if (props.setIsAccountLoading && props.setIsAccountSuccessful){
      props.setIsAccountLoading(false);
      if (user){
        props.setIsAccountSuccessful(true);
        if (props.orderConfirmationPage) alertCtx.setAlert('success', alertMessages.MEMBERSHIP_ACTIVATED);
        if (props.createAccountPage) {
          alertCtx.setAlert('success', alertMessages.REGISTER_SUCCESS);
          navigate('/account?tab=orders');
        }
      }
    }
  }

  const forgotPasswordClickHandler = () => {
    setShowForgotPassword(true);
    ReactGA.event({
      category: eventCategories.AUTHENTICATION, 
      action: eventNames.OPENED_FORGOT_PASSWORD
    })
  }

  let formToShow = (
    <Fragment>
      {!props.inline && <SectionHeader passOnClasses='mb-4'>Sign in to your account</SectionHeader>}
      <Form fields={fields} submitHandler={submitHandler} serverError={authCtx.error} inline={props.inline}>
        Sign in
      </Form>
      <div className='d-md-flex justify-content-between mt-3'>
        <BtnLink clickHandler={forgotPasswordClickHandler}>
          Forgot your password?
        </BtnLink>
        {/* 
        <BtnLink clickHandler={props.backHandler}>
          Back to other sign in options
        </BtnLink> 
        */}
      </div>
    </Fragment> 
   )

  if (showForgotPassword) {
    formToShow = (
      <ForgotPasswordForm 
        backHandler={()=>setShowForgotPassword(false)} 
        inline={props.inline}
        email={props.email}
      />
    ) 
  } 

  return <Fragment>{formToShow}</Fragment>
} 

export default SignInForm;