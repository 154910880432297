import {useContext} from 'react';
import RestaurantAccessContext from '../../../store/restaurant-access-context';

import Form from '../../UIElements/Form/Form';

import useInput from '../../../hooks/use-input';
import {validateAccessCode} from '../../../helpers/validation-functions';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const RestaurantAccessForm = props => {

	const restaurantAccessCtx = useContext(RestaurantAccessContext);

	const accessCode = useInput(validateAccessCode);

	const fields = [
		{
			fid: 0,
      label: 'Access code',
      id: 'access-code',
      type: 'password',
      errorMessage: 'Please enter a valid access code',
      value: accessCode.value,
      isValid: accessCode.isValid,
      hasError: accessCode.hasError,
      valueChangedHandler: accessCode.valueChangedHandler,
      touchHandler: accessCode.touchHandler,
      reset: ()=>accessCode.reset()
		}
	];

	const submitHandler = async event => {
		event.preventDefault();
    fields.map(field=>field.reset());
    restaurantAccessCtx.checkAccess(accessCode.value);
    ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.SUBMITTED_RESTAURANT_ACCESS_CODE
    })
	}

	return (
		<Form fields={fields} submitHandler={submitHandler} serverError={restaurantAccessCtx.error} inline>
      Gain access
    </Form>
	)

}

export default RestaurantAccessForm;