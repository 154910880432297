import whatsappIcon from '../../../../assets/images/whatsapp-icon.webp';
import {WhatsappShareButton} from 'react-share';

import styles from './WhatsappButton.module.css';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../../helpers/analytics-helpers';

const WhatsappButton = props => {
	
	const placeholderCopy = "I just ordered True Olive olive oil for the first time. I can't wait for my olive oil to get here." 
	const postCopy = props.postCopy ? props.postCopy : placeholderCopy;

	const postUrl = props.shareUrl;

	let shareTypeEventName = eventNames.CLICKED_ORDER_SHARE_OPTION;
	if (props.shareObject==='recipe') shareTypeEventName = eventNames.CLICKED_RECIPE_SHARE_OPTION; 

	const analyticsHandler = () => {
		ReactGA.event({
      category: eventCategories.VIRALITY, 
      action: shareTypeEventName,
      label: 'whatsapp'
    });
	}

	return (
		<span onClick={analyticsHandler}>
			<WhatsappShareButton
	      url={postUrl}
	      title={postCopy}
	      separator=':: '
	    >
	    	<img src={whatsappIcon} alt='Whatsapp icon' className={styles.Icon} />
	    </WhatsappShareButton>
	  </span>
	)
}

export default WhatsappButton;