import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import PrimaryCTALink from '../Buttons/PrimaryCTALink/PrimaryCTALink';
import SectionHeader from '../TextElements/SectionHeader/SectionHeader';

import styles from './BottomCTA.module.css';

const BottomCTA = props => {

	const copy = props.copy === 'default' ? 'Don’t ever wait. Always have access.' : props.copy;

	const clickHandler = () => ReactGA.event({
		category: eventCategories.ENGAGEMENT, 
		action: eventNames.CLICKED_BOTTOM_CTA
	});

	return (
		<section className={styles.BottomCTA}>
			<div className='container'>
				<div className='row'>
					<div className='col-12 d-md-flex align-items-center'>
						<SectionHeader passOnClasses='me-4 pt-2' noCapitalization>{copy}</SectionHeader>
						<PrimaryCTALink destination='/become-a-member' clickHandler={clickHandler}>Become a member</PrimaryCTALink>
					</div>
				</div>
			</div>
		</section>
	)

}

export default BottomCTA;