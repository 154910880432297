import React from 'react';

import OutlineButton from '../../../Buttons/OutlineButton/OutlineButton';
import FeatureP from '../../../TextElements/FeatureP/FeatureP';
import ListItemHeader from '../../../TextElements/ListItemHeader/ListItemHeader';

import styles from './PlanListItem.module.css';

import everyDayLuxuryImg from '../../../../../assets/images/everyday-luxury.webp';
import refinedPalateImg from '../../../../../assets/images/refined-palate.webp';
import trueConnoisseurImg from '../../../../../assets/images/true-connoisseur.webp';


const PlanListItem = props => {

	const badge = props.plan.invitationOnly ? <div className={styles.BadgeWrapper}><div className={styles.Badge}>Invitation-only</div></div> : null;

	let image = <img src={everyDayLuxuryImg} alt='Every-day Luxury' className={styles.Image} />
	if (props.plan.title === 'Refined Palate') image = <img src={refinedPalateImg} alt='Refined Palate' className={styles.Image} />
	if (props.plan.title === 'True Connoisseur') image = <img src={trueConnoisseurImg} alt='True Connoisseur' className={styles.Image} />

	const features = props.plan.features.map((feature, index)=>{
		return <FeatureP key={index}>{feature}</FeatureP>
	})

	return(
		<div className={styles.PlanContainer}>
			{badge}
			<div className='mb-4'>{image}</div>
			<ListItemHeader passOnClasses='mb-3'>{props.plan.title}</ListItemHeader>
			<h2 className={styles.Price}>{props.plan.price}</h2>
			{features}
			<OutlineButton clickHandler={props.plan.clickHandler}>{props.plan.CTA}</OutlineButton>
		</div>
	)
}

export default PlanListItem;