import {useState} from 'react';
import styles from './CopyUrl.module.css';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../../helpers/analytics-helpers';

const CopyUrl = props => {

	const [isCopied, setIsCopied] = useState(false);

	let shareTypeEventName = eventNames.CLICKED_ORDER_SHARE_OPTION;
	if (props.shareObject==='recipe') shareTypeEventName = eventNames.CLICKED_RECIPE_SHARE_OPTION;

	const copyHandler = () => {
		navigator.clipboard.writeText(props.shareUrl)
		setIsCopied(true);
		ReactGA.event({
      category: eventCategories.VIRALITY, 
      action: shareTypeEventName,
      label: 'copy'
    });
	}

	const copiedStyle = isCopied ? styles.Active : '';

	return (
		<div className={styles.CopyUrlWrapper}>
			<div className={styles.Link}>{props.shareUrl}</div>
			<div className={`${styles.Button} ${copiedStyle}`} onClick={copyHandler}>
				{isCopied ? 'Copied' : 'Copy'}
			</div>
		</div>
	)
}

export default CopyUrl;