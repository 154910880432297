import {Fragment, useContext} from 'react';
import CartContext from '../../../store/cart-context';
import Cart from '../Cart/Cart';

import styles from './CartDrawer.module.css';

const CartDrawer = props => {

	const cartCtx = useContext(CartContext);

	const openStylesBg = cartCtx.isDrawerShown ? 'd-block' : '';
	let showDrawerStyles = '';
	if (cartCtx.isDrawerShown) showDrawerStyles = styles.SlideInRight; 
	if (cartCtx.isDrawerClosed) showDrawerStyles = styles.SlideOutRight;

	return (
		<Fragment>
			<div 
				className={`${styles.TransparentBg} ${openStylesBg}`} 
				onClick={()=>cartCtx.toggleDrawer(false)}
			></div>
			<div className={`${styles.Drawer} ${showDrawerStyles}` }>
				<h3 className={styles.Close} onClick={()=>cartCtx.toggleDrawer(false)}>&#10005;</h3>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<Cart />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default CartDrawer;