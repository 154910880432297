import ModalLayout from '../../Layouts/ModalLayout/ModalLayout';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import FeatureP from '../../UIElements/TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../../UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import BetaAccessForm from '../../Forms/BetaAccessForm/BetaAccessForm';

import tastingCollection from '../../../assets/images/tasting-collection.webp'

import styles from './BetaModal.module.css';

const BetaModal = props => {

	return(
		<ModalLayout showModal={props.showModal} hideModal={props.hideModal} type='beta'>
			<div className='container-fluid text-center mb-4'>
				<SectionHeader passOnClasses='mb-4'><em>We are available privately</em></SectionHeader>
				<img src={tastingCollection} className={styles.Image} alt='Bottles' />
				<FeatureP>
					Thank you for checking out True Olive. We opened our doors to friends and family. 
					Use your access code to browse and shop our gold-medal-winning olive oils.
				</FeatureP>
				<div className={styles.FormWrapper}>
					<BetaAccessForm />
				</div>
				<ListItemInfoP>
					Want early access? Email Nicole and Can: <a className={styles.EmailLink} href='mailto:founders@trueolive.co'>founders@trueolive.co</a></ListItemInfoP>
			</div>
		</ModalLayout>
	)
}

export default BetaModal;
