import { useContext } from 'react';
import CartContext from '../../../../store/cart-context';

import LeadP from '../../TextElements/LeadP/LeadP';
import ListItemInfoP from '../../TextElements/ListItemInfoP/ListItemInfoP';

import styles from './ShippingCartItem.module.css';

const ShippingCartItem = props => {
	
	const cartCtx = useContext(CartContext);

	const priceCopy = cartCtx.hasFreeShipping() ? '$0' : `$${cartCtx.shippingPrice}`; 
	const shippingHeaderCopy = cartCtx.hasFreeShipping() ? 'Free shipping' : 'Standard shipping'; 

	return (

		<div className='d-flex align-items-center justify-content-between'>
			<LeadP passOnClasses='mb-0'>{shippingHeaderCopy}</LeadP>
			<div className={styles.PriceWrapper}>
				<LeadP passOnClasses='mb-0'>{priceCopy}</LeadP>
			</div>
		</div>
	);

}

export default ShippingCartItem;