import React from 'react';
import {Link} from 'react-router-dom';
import PrimaryCTA from '../PrimaryCTA/PrimaryCTA';

const PrimaryCTALink = props => {
	return (
		<Link to={props.destination}>
			<PrimaryCTA clickHandler={props.clickHandler}>{props.children}</PrimaryCTA>
		</Link>
	)
}

export default PrimaryCTALink;