import {useState, useContext, Fragment} from 'react';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import Error from '../../UIElements/Error/Error';

import UserContext from '../../../store/user-context';
import AlertContext, {alertMessages} from '../../../store/alert-context'

import {validateName, validateStateAbb, validatePhoneNumber, validateZipCode, validateAddress} from '../../../helpers/validation-functions';
import {postData} from '../../../helpers/http-helpers';
import useInput from '../../../hooks/use-input';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const EditShippingInfoForm = props => {

	const userCtx = useContext(UserContext);
  const alertCtx = useContext(AlertContext);

  const [isShippingInfoLoading, setIsShippingInfoLoading] = useState(false);
	const [shippingInfoError, setShippingInfoError] = useState(null);
	
	const name = useInput(validateName,  props.info.name);
	const phone = useInput(validatePhoneNumber,  props.info.phone);
	const line1 = useInput(validateAddress, props.info.address.line1);
	const line2 = useInput(validateAddress, props.info.address.line2);
	const city = useInput(validateName, props.info.address.city);
	const state = useInput(validateStateAbb, props.info.address.state);
	const country = useInput(validateName, props.info.address.country);
	const postalCode = useInput(validateZipCode, props.info.address.postal_code);

	const fields = [
		{
			fid: 0,
      label: 'Your name',
      id: 'name',
      type: 'text',
      errorMessage: 'Please enter a valid name',
      value: name.value,
      isValid: name.isValid,
      hasError: name.hasError,
      valueChangedHandler: name.valueChangedHandler,
      touchHandler: name.touchHandler,
      reset: ()=>name.reset()
		},
		{
			fid: 1,
      label: 'Your phone number',
      id: 'phone',
      type: 'text',
      errorMessage: 'Please enter a valid phone number',
      value: phone.value,
      isValid: phone.isValid,
      hasError: phone.hasError,
      valueChangedHandler: phone.valueChangedHandler,
      touchHandler: phone.touchHandler,
      reset: ()=>phone.reset()
		},
		{
			fid: 2,
      label: 'Your address - line 1',
      id: 'line1',
      type: 'text',
      errorMessage: 'Please enter a valid address',
      value: line1.value,
      isValid: line1.isValid,
      hasError: line1.hasError,
      valueChangedHandler: line1.valueChangedHandler,
      touchHandler: line1.touchHandler,
      reset: ()=>line1.reset()
		},
		{
			fid: 3,
      label: 'Your address - line 2',
      id: 'line2',
      type: 'text',
      errorMessage: 'Please enter a valid address',
      value: line2.value,
      isValid: line2.isValid,
      hasError: line2.hasError,
      valueChangedHandler: line2.valueChangedHandler,
      touchHandler: line2.touchHandler,
      reset: ()=>line2.reset()
		},
		{
			fid: 4,
      label: 'Your city',
      id: 'city',
      type: 'text',
      errorMessage: 'Please enter a valid city',
      value: city.value,
      isValid: city.isValid,
      hasError: city.hasError,
      valueChangedHandler: city.valueChangedHandler,
      touchHandler: city.touchHandler,
      reset: ()=>city.reset()
		},
		{
			fid: 5,
      label: 'Your state',
      id: 'state',
      type: 'text',
      helpText: 'Please use the abbreviation. E.g. "CA" not "California"',
      errorMessage: 'Please enter a valid state abbreviation. E.g. "CA" not "California"',
      value: state.value,
      isValid: state.isValid,
      hasError: state.hasError,
      valueChangedHandler: state.valueChangedHandler,
      touchHandler: state.touchHandler,
      reset: ()=>state.reset()
		},
		{
			fid: 6,
      label: 'Your country',
      id: 'country',
      type: 'text',
      helpText: 'Currently we only ship to the US',
      disabled: true,
      value: country.value,
      isValid: country.isValid,
      hasError: country.hasError,
      valueChangedHandler: country.valueChangedHandler,
      touchHandler: country.touchHandler,
      reset: ()=>country.reset()
		},
		{
			fid: 7,
      label: 'Your zip code',
      id: 'postalCode',
      type: 'text',
      errorMessage: 'Please enter a valid zip code',
      value: postalCode.value,
      isValid: postalCode.isValid,
      hasError: postalCode.hasError,
      valueChangedHandler: postalCode.valueChangedHandler,
      touchHandler: postalCode.touchHandler,
      reset: ()=>postalCode.reset()
		}		
	];

	const submitHandler = (event) => {
    event.preventDefault();

    ReactGA.event({
      category: eventCategories.ACCOUNT, 
      action: eventNames.SUBMITTED_SHIPPING_INFO_UPDATE
    });

    const dataToUpdate = {
    	name: name.value, 
    	phone: phone.value.length>0 ? phone.value : null, 
    	address: {
    		city: city.value,
	      country: country.value,
	      line1: line1.value,
	      line2: line2.value.length>0 ? line2.value : null,
	      postal_code: postalCode.value,
	      state: state.value
    	}
    };
    updateShippingAddress(userCtx.stripeCustomerId, dataToUpdate);
  }

  const updateShippingAddress = async (id, dataToUpdate) => {
  	
  	setShippingInfoError(null);
		setIsShippingInfoLoading(true);

	  try {
	  	const response = await postData(
        'https://us-central1-true-olive-dev.cloudfunctions.net/updateCustomerInfo',
        {
          id: id,
          shipping: dataToUpdate
        }
	    );

	    if(!response.ok){
				throw (response.error);
			}

      ReactGA.event({
        category: eventCategories.ACCOUNT, 
        action: eventNames.SHIPPING_INFO_UPDATE_SUCCESS
      });

			props.controlFormDisplay(false);
			props.updateShippingInfoState(dataToUpdate);
			alertCtx.setAlert('success', alertMessages.SHIPPING_INFO_UPDATED);
	  } catch (error) {

      ReactGA.event({
        category: eventCategories.ACCOUNT, 
        action: eventNames.SHIPPING_INFO_UPDATE_ERROR
      });

	  	setShippingInfoError({message: error.message});
	  }
	  setIsShippingInfoLoading(false);
  }

  // CONTENT
  let content = (
      <Form 
        fields={fields} 
        submitHandler={submitHandler} 
        serverError={shippingInfoError}
      >
        Update your shipping information
      </Form>
    );
  if (isShippingInfoLoading) content = <Loader>Updating your shipping information...</Loader>;  

  return <Fragment>{content}</Fragment>
}
export default EditShippingInfoForm;