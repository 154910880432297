import styles from './Loader.module.css';
import LeadP from '../TextElements/LeadP/LeadP';

const Loader = props => {

	const darkColor = props.dark ? styles.Dark : '';
	const centralized = props.centralized ? 'justify-content-center' : '';
	const heroCopy = props.hero ? styles.HeroCopy : ''; 

	return (
		<div className={`d-flex align-items-center ${centralized}`}>
			<div className={`${styles.Loader} ${darkColor}`}></div>
			{!props.hideCopy && <LeadP passOnClasses={`mb-0 ms-4 ${heroCopy}`}>{(props.children) ? props.children : 'Loading...'}</LeadP>}
		</div>
	)
}

export default Loader;