import { useState, useContext, useEffect, useRef } from 'react';

import ModalLayout from '../../Layouts/ModalLayout/ModalLayout';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import FeatureP from '../../UIElements/TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../../UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import ListItemHeader from '../../UIElements/TextElements/ListItemHeader/ListItemHeader';
import PrimaryCTA from '../../UIElements/Buttons/PrimaryCTA/PrimaryCTA';

//import tastingCollection from '../../../assets/images/tasting-collection.webp'
import mothersDay from '../../../assets/images/mothers-day.webp'

import AlertContext, { alertMessages } from '../../../store/alert-context'

import styles from './SoftLaunchModal.module.css';

const SoftLaunchModal = props => {

	const alertCtx = useContext(AlertContext);

	const handleClose = ()=>{
		props.hideModal();
		alertCtx.setAlert('success', alertMessages.SOFT_LAUNCH_PROMOTION_CODE_APPLIED);
	}

	return(
		<ModalLayout showModal={props.showModal} hideModal={props.hideModal} type='softLaunch'>
			<div className='container-fluid text-center mb-4'>
				<SectionHeader passOnClasses='mb-3'><em>Happy Mother's Day!</em></SectionHeader>
				<img src={mothersDay} className={styles.Image} alt="Mother's Day Promo"/>
				<div className={styles.BodyCopyWrapper}>
					<ListItemHeader passOnClasses='mb-4'>10% Off On All Orders</ListItemHeader>
					{/*
					<FeatureP>
						Come celebrate our first Black Friday with our gold-award winning single estate olive oils. 
						Help us grow by spreading the word to your friends! 
					</FeatureP>
					*/}
				</div>
				<PrimaryCTA mediumSized clickHandler={handleClose}>Shop with 10% off</PrimaryCTA>
			</div>
		</ModalLayout>
	)
}

export default SoftLaunchModal;
