import { useContext } from 'react';
import CartContext from '../../../../store/cart-context';

import LeadP from '../../TextElements/LeadP/LeadP';
import ListItemInfoP from '../../TextElements/ListItemInfoP/ListItemInfoP';
import BtnLink from '../../Buttons/BtnLink/BtnLink';
import CircleButton from '../../Buttons/CircleButton/CircleButton';

import premiumBottle from '../../../../assets/images/premium.webp';
import luxeBottle from '../../../../assets/images/luxe.webp';
import privateReserveBottle from '../../../../assets/images/private-reserve.webp';
import premium750Bottle from '../../../../assets/images/premium-750.webp';
import luxe750Bottle from '../../../../assets/images/luxe-750.webp';
import privateReserve750Bottle from '../../../../assets/images/private-reserve-750.webp';
import tastingCollectionImg from '../../../../assets/images/tasting-collection-cart.webp';
import everydayLuxuryImg from '../../../../assets/images/everyday-luxury.webp';
import refinedPalateImg from '../../../../assets/images/refined-palate.webp';
import trueConnoisseurImg from '../../../../assets/images/true-connoisseur.webp';

import styles from './CartItem.module.css';

const CartItem = props => {

	const cartCtx = useContext(CartContext);

	let image = <img src={premiumBottle} alt='Premium Bottle' className={styles.BottleImage} />
	if (props.item.id === 'luxe') image = <img src={luxeBottle} alt='Luxe Bottle' className={styles.BottleImage} />
	if (props.item.id === 'privateReserve') image = <img src={privateReserveBottle} alt='Private Reserve Bottle' className={styles.BottleImage} />
	if (props.item.id === 'premium750') image = <img src={premium750Bottle} alt='Premium Bottle' className={styles.BottleImage} />
	if (props.item.id === 'luxe750') image = <img src={luxe750Bottle} alt='Luxe Bottle' className={styles.BottleImage} />
	if (props.item.id === 'privateReserve750') image = <img src={privateReserve750Bottle} alt='Private Reserve Bottle' className={styles.BottleImage} />
	if (props.item.id === 'tastingCollection') image = <img src={tastingCollectionImg} alt='Tasting Collection' className={styles.Image} />
	if (props.item.id === 'everydayLuxury') image = <img src={everydayLuxuryImg} alt='Everyday Luxury' className={styles.Image} />
	if (props.item.id === 'refinedPalate') image = <img src={refinedPalateImg} alt='Refined Palate' className={styles.Image} />	
	if (props.item.id === 'trueConnoisseur') image = <img src={trueConnoisseurImg} alt='True Connoisseur' className={styles.Image} />

	let totalPrice = props.item.displayPrice * props.item.quantity;
	if (totalPrice % 1 !== 0) totalPrice = totalPrice.toFixed(2);

	let packageType = 'bottle'; 
	if (
		props.item.id === 'tastingCollection' || 
		props.item.id === 'refinedPalate' || 
		props.item.id === 'trueConnoisseur'
	) { packageType = 'set' }

	let isRecurringCopy = '';	
	if (
		props.item.id === 'everydayLuxury' || 
		props.item.id === 'refinedPalate' || 
		props.item.id === 'trueConnoisseur'
	) { isRecurringCopy = '/m' }

	const handleRemove = () => {
		cartCtx.changeItemCount(props.item.id, 0);
	}

	let listPriceStriked = null;
	if (props.item.displayPrice !== props.item.listPrice){
		listPriceStriked = (
			<ListItemInfoP passOnClasses='mb-0'>
				<span className={styles.StrikedPrice}>{`$${props.item.listPrice}/${packageType}${isRecurringCopy}`}</span>
			</ListItemInfoP>
		)
	}  

	return (
		<div className={styles.CartItem}>
			<div className={styles.ItemInfoWrapper}>
				<div className={styles.ImageWrapper}>
					{image}
				</div>
				<div className={styles.ItemInfo}>
					<LeadP passOnClasses='mb-0'>{props.item.name}</LeadP>
					{listPriceStriked}
					<ListItemInfoP passOnClasses='mb-0'>
						{`$${props.item.displayPrice}/${packageType}${isRecurringCopy}`}
					</ListItemInfoP>
				</div>
				<div className={styles.AdjustQuantityWrapper}>
					<div>
						<div className='d-flex align-items-center'>
							<CircleButton 
								passOnClasses='me-2'
								clickHandler={()=>cartCtx.removeItem(props.item.id)}
							>-</CircleButton>
							<LeadP passOnClasses='mb-0'>{props.item.quantity}</LeadP>
							<CircleButton 
								passOnClasses='ms-2' 
								clickHandler={()=>cartCtx.addItem(props.item.id)}
							>+</CircleButton>
						</div>
						<div className={styles.SmallTotalPrice}>
							<ListItemInfoP passOnClasses='mb-0 text-center'>
								{`($${totalPrice}${isRecurringCopy})`}
							</ListItemInfoP>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.Price}>
				<LeadP passOnClasses='mb-0'>{`$${totalPrice}${isRecurringCopy}`}</LeadP>
				<BtnLink 
					clickHandler={handleRemove}
					small
				>Remove</BtnLink>
			</div>
		</div>
	)

}

export default CartItem;