import SideNavItem from './SideNavItem/SideNavItem';
import DropDown from './Dropdown/DropDown';

const SideNav = props => {

	const sideNavItems = props.items.map(item=>{
		return <SideNavItem key={item.id} clickHandler={props.clickHandler} item={item} />
	});

	return(
		<nav className='nav nav-pills flex-column flex-sm-row mb-3'>
		  {sideNavItems}
		  <DropDown items={props.items} clickHandler={props.clickHandler}/>
		</nav>
	)
}

export default SideNav;