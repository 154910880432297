import { useEffect, useRef } from 'react';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Loader from '../../components/UIElements/Loader/Loader';
import SectionHeader from '../../components/UIElements/TextElements/SectionHeader/SectionHeader';
import instagramLogo from '../../assets/images/instagram-icon.webp';

import styles from './InstaRedirect.module.css';

const InstaRedirect = () => {

	const instagramLinkRef = useRef(null);

	useEffect(()=>{
		setTimeout(()=>{instagramLinkRef.current.click()}, 2000);
		setTimeout(()=>{window.location.href ='https://www.instagram.com/trueoliveco/'}, 7000);
	},[])

	return (
		<MainLayout>
			<div className='container py-1'>
				<div className='row py-5'>
					<div className='col-md-8 offset-md-2 py-5 text-center'>
						<img src={instagramLogo} alt='Instagram Logo' className={styles.InstagramLogo} />	
						<Loader centralized hideCopy></Loader>
						<SectionHeader passOnClasses='mt-5'>
							Redirecting you to @trueoliveco for recipes.
						</SectionHeader>
						<a
							className={styles.InstaLink}  
							ref={instagramLinkRef} 
							href='instagram://user?username=trueoliveco'
						>
							Link to Instagram Profile
						</a> 			
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default InstaRedirect