import { createContext, useState } from 'react';
import { getFBGuestInfo, createOrFetchFBGuest, updateFBGuest } from '../helpers/firebase-helpers';

const GuestContext = createContext({
	guestId: '',
	userId: '',
	email:'',
	firstName: '',
	lastName: '',
	stripeCustomerId: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	createOrFetchGuest: guestData => {},
	getGuestInfo: guestId => {},
	updateGuestInfo: (guestId, dataToUpdate) => {}
});

export const GuestContextProvider = props => {

	const [guestId, setGuestId] = useState(localStorage.getItem('TrueOliveGuestId'));
	const [userId, setUserId] = useState(localStorage.getItem('TrueOliveLocalId'));
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [stripeCustomerId, setStripeCustomerId] = useState(localStorage.getItem('TrueOliveGuestStripeCustomerId'));
	const [lastName, setLastName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const handleSuccess = (guestId, email, firstName, lastName, stripeCustomerId, userId) => {
		setGuestId(guestId);
		setEmail(email);
		setFirstName(firstName);
		setLastName(lastName);
		setStripeCustomerId(stripeCustomerId);
		setUserId(userId);


		localStorage.setItem('TrueOliveGuestId', guestId);
		localStorage.setItem('TrueOliveGuestEmail', email);
		localStorage.setItem('TrueOliveGuestStripeCustomerId', stripeCustomerId);
		// No need to set localId on local storage. AuthContext already does that

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const createOrFetchGuest = async guestData => {
		resetStates();
		setIsLoading(true);
		const guest = await createOrFetchFBGuest(guestData);
		if (guest){
			handleSuccess(
				guest.guestId,
				guestData.email,
				guestData.firstName,
				guestData.lastName,
				guestData.stripeCustomerId,
				''
			)
			return guest;
		
		} else {
			handleFail({message: 'Failed to create a guest'});
			return;
		}
	}

	const getGuestInfo = async guestId => {
		resetStates();
		setIsLoading(true);
		const guest = await getFBGuestInfo(guestId);
		if (guest){
			handleSuccess(
				guestId,
				guest.email,
				guest.firstName,
				guest.lastName, 
				guest.stripeCustomerId,
				guest.userId ? guest.userId : ''
			)
		 return guest;
		}else {
			handleFail({message: 'Failed to get guest data'});
		 return;
		}
	}

	const updateGuestInfo = async (guestId, dataToUpdate) => {
		resetStates();
		setIsLoading(true);

		const result = await updateFBGuest(guestId, dataToUpdate);
		if (result.type === 'success') {
			handleSuccess(
				guestId,
				email,
				firstName,
				lastName, 
				stripeCustomerId,
				dataToUpdate.userId
			);
			return dataToUpdate.userId;
		}

		if (result.type === 'error') {
			handleFail({message: result.message});
			return false
		}

	}

	return (
		<GuestContext.Provider value={{
			guestId: guestId,
			email: email,
			firstName: firstName,
			lastName: lastName,
			stripeCustomerId: stripeCustomerId,
			error: error,
			isLoading: isLoading,
			isSuccessful: isSuccessful,
			createOrFetchGuest: createOrFetchGuest,
			getGuestInfo: getGuestInfo,
			updateGuestInfo: updateGuestInfo
		}}>
			{props.children}
		</GuestContext.Provider>
	)

}

export default GuestContext;