import { useState, useContext, useEffect, useRef } from 'react';

import AuthContext from '../../../store/auth-context';
import AlertContext, { alertMessages } from '../../../store/alert-context';

import { Modal } from 'bootstrap';

import styles from './ModalLayout.module.css';

const ModalLayout = props => {

	const [modal, setModal] = useState([]);
	const mainModal = useRef();

	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);

	useEffect(() => {
	    const modal = new Modal(mainModal.current, {keyboard: true, backdrop: 'static'})
	    setModal(modal);
	    if (props.showModal) modal.show();
			if (!props.showModal && mainModal.current.className === 'modal show') handleClose();  
	},[props.showModal])

  const handleClose = () => {
  	modal.hide();
  	props.hideModal();
  	if (props.type === 'signIn' && authCtx.isLoggedIn) alertCtx.setAlert('success', alertMessages.SIGN_IN_SUCCESS);
  	if (props.type === 'beta') alertCtx.setAlert('success', alertMessages.ACCESS_GRANTED);
  	if (props.type === 'restaurant') alertCtx.setAlert('success', alertMessages.RESTAURANT_ACCESS_GRANTED);
  }

  let closeIcon = <h3 className={styles.Close} onClick={()=>handleClose()}>&#10005;</h3>
  if (props.type === 'beta' || props.type === 'restaurant' || props.type === 'softLaunch') closeIcon = null;

  const BetaModalStyles = (props.type === 'beta' || props.type === 'restaurant') ? styles.BetaModal : ''; 

	return (
		<div className='modal' tabIndex='-1' ref={mainModal}>
		  <div className='modal-dialog modal-dialog-centered modal-lg'>
		    <div className={`modal-content ${styles.ModalContent} ${BetaModalStyles}`}>
		    	<div className='modal-body'>
		    		{closeIcon}
		        <div className='pt-5'>
		        	{props.children}
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
	)
}

export default ModalLayout;