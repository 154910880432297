import ListItemInfoP from '../../../../../components/UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import FeatureP from '../../../../../components/UIElements/TextElements/FeatureP/FeatureP';

import styles from './ShippingItem.module.css';

const ShippingItem = props => {

	const priceCopy = props.shippingPrice ? `$${props.shippingPrice}` : '$0'
	const shippingHeaderCopy = props.shippingPrice  ? 'Standard shipping' : 'Free shipping'; 
	const hasTaxMargin = props.hasTax ? styles.TaxMargin : '';

	return (

		<div className={styles.ShippingItem}>
			<FeatureP passOnClasses='mb-0'>{shippingHeaderCopy}</FeatureP>
			<div className={styles.PriceWrapper}>
				<FeatureP passOnClasses='mb-0 ms-1 text-end'>{priceCopy}</FeatureP>
			</div>
		</div>
	);

}

export default ShippingItem;