import {Fragment, useContext} from 'react';

import AuthContext from '../../../store/auth-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import ListItemHeader from '../../UIElements/TextElements/ListItemHeader/ListItemHeader';
import BtnLink from '../../UIElements/Buttons/BtnLink/BtnLink';
import ForgotPasswordSuccess from './ForgotPasswordSuccess/ForgotPasswordSuccess';

import {validateEmail} from '../../../helpers/validation-functions';

import useInput from '../../../hooks/use-input';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

const ForgotPasswordForm = props => {

  const authCtx = useContext(AuthContext);

	const email = useInput(validateEmail);

	const fields = [
		{
			fid: 0,
      label: 'Your email',
      id:  `${props.inline ? 'inline-' :''}email`,
      type: 'email',
      helpText: `${!props.inline ? 'Please enter the email that you registered with' : '' }`,
      errorMessage: 'Please enter a valid email',
      disabled: props.email ? true : false,
      value: props.email ? props.email : email.value,
      isValid: email.isValid,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
		}
	];

	const submitHandler = async event => {
    event.preventDefault();

    ReactGA.event({
      category: eventCategories.AUTHENTICATION, 
      action: eventNames.SUBMITTED_FORGOT_PASSWORD
    });

    fields.map(field=>field.reset());
    await authCtx.onForgotPassword(email.value);
  }

  let content = (
    <Fragment>
      {!props.inline && <SectionHeader passOnClasses='mb-4'>Reset your password</SectionHeader>}
      {props.inline && <ListItemHeader passOnClasses='mb-2'>Reset your password</ListItemHeader>}
      <Form fields={fields} submitHandler={submitHandler} inline={props.inline} serverError={authCtx.fpError}>
        Send password reset email
      </Form>
      <div className='mt-3'>
        <BtnLink clickHandler={props.backHandler}>
          Back to sign in
        </BtnLink>
      </div>
    </Fragment>
  );

  if (authCtx.fpIsLoading) content = <Loader>Looking for your account</Loader>
  if (authCtx.fpIsSuccessful) content = <ForgotPasswordSuccess />
  

  return (
    <Fragment>
      {content}
    </Fragment>
  );
} 

export default ForgotPasswordForm;