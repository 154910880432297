import React from'react';

import styles from './OutlineButton.module.css';

const OutlineButton = props => {

	const passOnClasses = props.passOnClasses ? props.passOnClasses : ''

	return (
		<button 
			className={`btn btn-outline-primary ${styles.OutlineButton} ${passOnClasses}`} 
			onClick={props.clickHandler}
		>
			{props.children}
		</button>
	)
}

export default OutlineButton;