import ModalLayout from '../../Layouts/ModalLayout/ModalLayout';
import SectionHeader from '../../UIElements/TextElements/SectionHeader/SectionHeader';
import FeatureP from '../../UIElements/TextElements/FeatureP/FeatureP';
import ListItemInfoP from '../../UIElements/TextElements/ListItemInfoP/ListItemInfoP';
import RestaurantAccessForm from '../../Forms/RestaurantAccessForm/RestaurantAccessForm';

import restaurantLineup from '../../../assets/images/restaurant-lineup.webp'

import styles from './RestaurantAccessModal.module.css';

const RestaurantAccessModal = props => {

	return(
		<ModalLayout showModal={props.showModal} hideModal={props.hideModal} type='restaurant'>
			<div className='container-fluid text-center mb-4'>
				<SectionHeader passOnClasses='mb-4'><em>Available for select restaurants</em></SectionHeader>
				<img src={restaurantLineup} className={styles.Image} alt='Bottles' />
				<FeatureP>
					We are a gold-medal-winning, single-estate olive oil producer committed to producing the highest quality
					olive oils. Our dedication to exceptional olive oil production has been recognized on both side of the 
					Atlantic with gold medals in international competitions. Our supply is not endless; therefore, we only
					work with a select group of restaurants. If you have an access code, enter it below to get started.
				</FeatureP>
				<div className={styles.FormWrapper}>
					<RestaurantAccessForm />
				</div>
				<ListItemInfoP>
					To become a partner restaurant contact us: <a className={styles.EmailLink} href='mailto:founders@trueolive.co'>founders@trueolive.co</a>
				</ListItemInfoP>
			</div>
		</ModalLayout>
	)
}

export default RestaurantAccessModal;
