import { firebaseDB } from './firebase-config'; 
import { firebaseAuth } from './firebase-config';
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, addDoc, orderBy } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updatePassword, updateEmail, sendPasswordResetEmail } from "firebase/auth";
import {postData} from './http-helpers';

// AUTH METHODS
export const registerWithFBAuth = async (email, password) => {
	return await createUserWithEmailAndPassword(firebaseAuth, email, password);
}

export const signInWithFBAuth = async (email, password) => {
	return await signInWithEmailAndPassword(firebaseAuth, email, password)
}

export const sendFBPasswordResetEmail = async email => {
	try {
		await sendPasswordResetEmail(firebaseAuth, email);
		return {type: 'success'};
	} catch (error) {
		return {type: 'error', message: error.message};
	}	
}

export const updateFBPassword = async (email, password, newPassword) => {	
	try {
		const authUser = await signInWithFBAuth(email, password); 
		try {
			const user = firebaseAuth.currentUser;
			await updatePassword(user, newPassword);
			return {type: 'success'};
		} catch (error) {

			return {type: 'error', message: error.message}
		}
	} catch (error) {
		console.log(error);
		return {type: 'error', message: error.message};
	}		
}

export const updateAuthEmail = async newEmail => {
	try {
		const user = firebaseAuth.currentUser;
		await updateEmail(user, newEmail);
		return {type: 'success'};
	} catch (error) {
		console.log(error.message)
		return {type: 'error', message: error.message}
	}
}

// USER METHODS

// Create a user
export const createFBUser = async userData => {
	const userDocRef = doc(firebaseDB, 'users', userData.userId);
	const userSnapshot = await getDoc(userDocRef);

	if(!userSnapshot.exists()){
		try {
			await setDoc(userDocRef, {
				firstName: userData.firstName,
				lastName: userData.lastName,
				email: userData.email,
				guestId: userData.guestId,
				stripeCustomerId: userData.stripeCustomerId,
				createdAt: userData.createdAt
			});
			return {
				userId: userData.userId,
				firstName: userData.firstName,
				lastName: userData.lastName,
				email: userData.email,
				guestId: userData.guestId,
				stripeCustomerId: userData.stripeCustomerId
			};
		} catch (error) {
			console.log(error.message)
		}
	}

	return userDocRef; 
}

// Fetch user data by id 
export const getFBUserInfo = async userId => {
	const userDocRef = doc(firebaseDB, 'users', userId);
	const userSnapshot = await getDoc(userDocRef);
	if(userSnapshot.exists()) return userSnapshot.data();
	return null;
}

// Fetch user data by email
export const getFBUserInfoByEmail = async email => {
	const userRef = collection(firebaseDB, 'users');
	const getUserByEmailQ = query(userRef, where('email', '==', email))
	const querySnapshot = await getDocs(getUserByEmailQ);
	if(querySnapshot.empty) return false;
	return {userId: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data()}
}


// Update user data: Also update in Stripe if need be change the email in auth as well 
export const updateFBUserInfo = async (userId, stripeId, currentEmail, dataToUpdate) => {
	const userDocRef = doc(firebaseDB, 'users', userId);
	const userSnapshot = await getDoc(userDocRef);
	if(userSnapshot.exists()) {
		// if the user exists check if they are updating their email
		if(currentEmail !== dataToUpdate.email) {
			const result = await updateAuthEmail(dataToUpdate.email);
			if (result.type === 'error') {
				return {type: 'error', message: result.message} 
			}
		}
		try {
			const user = await updateDoc(userDocRef, {
				firstName: dataToUpdate.firstName,
				lastName: dataToUpdate.lastName,
				email: dataToUpdate.email,
				updatedAt: dataToUpdate.updatedAt
			});

			if(stripeId){
				const stripeUser = await postData(
	        'https://us-central1-true-olive-dev.cloudfunctions.net/updateCustomerInfo',
	        {
	          id: stripeId,
	          name: `${dataToUpdate.firstName} ${dataToUpdate.lastName}`,
	          email: dataToUpdate.email
	        }
		    );
		  }

			return {
				type: 'success',
				data: {
					firstName: dataToUpdate.firstName,
					lastName: dataToUpdate.lastName,
					email: dataToUpdate.email,
				}
			}
		} catch (error) {
			const result = await updateAuthEmail(currentEmail);
			return {type: 'error', message: 'Failed to update your account information'} 
		}
	
	} else {
		return {type: 'error', message: 'Failed to update your account information'}
	}
}

// GUEST METHODS

//Fetch a guest by Id
export const getFBGuestInfo = async guestId => {
	const guestDocRef = doc(firebaseDB, 'guests', guestId);
	const guestSnapshot = await getDoc(guestDocRef);
	if(guestSnapshot.exists()) return guestSnapshot.data();
	return null;
}

//Create or fetch a guest
export const createOrFetchFBGuest = async guestData => {
	// Check if there is an existing guest
	const guestsRef = collection(firebaseDB, 'guests');
	const getGuestByEmailQ = query(guestsRef, where('email', '==', guestData.email))
	const querySnapshot = await getDocs(getGuestByEmailQ);
	// If no guest create one and return its data
	if(querySnapshot.empty) {
		try {
			const docRef = await addDoc(guestsRef, guestData);
			return {
				type: 'new',
				guestId: docRef.id,
				...guestData
			};	
		} catch (error) {
			return({type: 'error', message: error.message});
		}		
	} else { // get the guestData and return it 
		return {
			type: 'existing',
			guestId: querySnapshot.docs[0].id,
			...querySnapshot.docs[0].data()
		}
	}
}

// Update a guest
export const updateFBGuest = async (guestId, dataToUpdate) => {
	const guestDocRef = doc(firebaseDB, 'guests', guestId);
	const userSnapshot = await getDoc(guestDocRef);
	if(userSnapshot.exists()) {
		try {
			await updateDoc(guestDocRef, {
				...dataToUpdate,
				updatedAt: new Date()
			});
			return {
				type: 'success',
				data: {
					userId: dataToUpdate.userId
				}
			}
		} catch (error) {
			return {type: 'error', message: 'Failed to update your account information'} 
		}	
	}
}

// ORDER METHODS

// Fetch all orders from a guest
export const getAllGuestOrders = async guestId => {
	const ordersRef = collection(firebaseDB, 'orders');
	const getOrdersByGuestIdQ = query(ordersRef, where('guestId', '==', guestId), orderBy('createdAt'))
	const querySnapshot = await getDocs(getOrdersByGuestIdQ);	
	if(querySnapshot.empty) return false;
	const orders = [];
	querySnapshot.docs.forEach(doc=>{
		orders.push(doc.data());
	})
	return orders.reverse();
}


// Fetch all orders from a user
export const getAllUserOrders = async userId => {
	const ordersRef = collection(firebaseDB, 'orders');
	const getOrdersByUserIdQ = query(ordersRef, where('userId', '==', userId), orderBy('createdAt'))
	const querySnapshot = await getDocs(getOrdersByUserIdQ);	
	if(querySnapshot.empty) return false;
	const orders = [];
	querySnapshot.docs.forEach(doc=>{
		orders.push(doc.data());
	})
	return orders.reverse();
}

// Create an order
export const createFBOrder = async orderData => {
	const ordersRef = collection(firebaseDB, 'orders');
	try {
		const docRef = await addDoc(ordersRef, orderData);
		return {
			orderId: docRef.id,
			...orderData
		};	
	} catch (error) {
		return({type: 'error', message: error.message});
	}	
}

// Update an order
export const updateFBOrder = async (orderId, dataToUpdate) => {
	const orderDocRef = doc(firebaseDB, 'orders', orderId);
	const userSnapshot = await getDoc(orderDocRef);
	if(userSnapshot.exists()) {
		try {
			await updateDoc(orderDocRef, {
				...dataToUpdate,
				updatedAt: new Date()
			});
			return {
				type: 'success',
				data: {
					orderId: dataToUpdate.orderId
				}
			}
		} catch (error) {
			return {type: 'error', message: 'Failed to update your order'} 
		}	
	}
}

// Update all orders from a guest
export const updateAllGuestOrders = async (guestId, dataToUpdate) => {
	const ordersRef = collection(firebaseDB, 'orders');
	const getOrdersByGuestIdQ = query(ordersRef, where('guestId', '==', guestId))
	const querySnapshot = await getDocs(getOrdersByGuestIdQ);

	if(!querySnapshot.empty) {
		querySnapshot.forEach(doc=>updateFBOrder(doc.id, dataToUpdate));
		return true; 
	}

	return;
}	

// INFLUENCER METHODS
// Fetch influencer by slug
export const getFBInfluencerBySlug = async slug => {
	const influencersRef = collection(firebaseDB, 'influencers');
	const getInfluencerBySlugQ = query(influencersRef, where('slug', '==', slug))
	const querySnapshot = await getDocs(getInfluencerBySlugQ);	
	if(querySnapshot.empty) return false;
	const influencer = querySnapshot.docs[0].data();
	return influencer;
}

//RECIPE METHODS
// Fetch all recipes
export const getFBRecipes = async () => {
	const recipesRef = collection(firebaseDB, 'recipes');
	const getRecipes = query(recipesRef, orderBy('createdAt'));
	const querySnapshot = await getDocs(getRecipes);
	if(querySnapshot.empty) return false;
	const recipes = [];
	querySnapshot.docs.forEach(doc=>{
		recipes.push({recipeId: doc.id, ...doc.data()});
	})
	return recipes.reverse();
}

// Fetch individual Recipe 
export const getFBRecipeBySlug = async slug => {
	const recipesRef = collection(firebaseDB, 'recipes');
	const getRecipeBySlugQ = query(recipesRef, where('slug', '==', slug));
	const querySnapshot = await getDocs(getRecipeBySlugQ);
	if(querySnapshot.empty) return false;
	const recipe = querySnapshot.docs[0].data();
	return recipe;
}



