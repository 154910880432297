import styles from './ListItemInfoP.module.css';

const ListItemInfoP = props => {

	const passOnClasses = props.passOnClasses ? props.passOnClasses : '';

	return (
		<p className={`${passOnClasses} ${styles.ListItemInfoP}`}>{props.children}</p>
	)
}

export default ListItemInfoP;