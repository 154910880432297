import styles from './OptionsWrapper.module.css'

const OptionsWrapper = props => {

	const passOnClasses = props.passOnClasses ? props.passOnClasses : '';
	const smallStyles = props.small ? styles.SmallSize : '';
	
	return (
		<div className={`${styles.OptionsWrapper} ${smallStyles} ${passOnClasses}`}>
			{props.children}
		</div>
	)
}

export default OptionsWrapper;