import styles from './CircleButton.module.css';

const CircleButton = props => {

	return (
		<div 
			className={`${styles.CircleButton} ${props.passOnClasses}`} 
			disabled={props.disabled}
			onClick={props.clickHandler}
		>
			{props.children}
		</div>
	)
}

export default CircleButton;