import { useContext } from 'react';
import AuthContext from '../../../store/auth-context'; 

import Form from '../../UIElements/Form/Form';
import {validateName, validateEmail, validatePassword} from '../../../helpers/validation-functions';
import useInput from '../../../hooks/use-input';

const RegistrationForm = props => {

  const authCtx = useContext(AuthContext);

	const email = useInput(validateEmail);
	const firstName = useInput(validateName);
	const lastName = useInput(validateName);
	const password = useInput(validatePassword);

	const fields = [
		{
			fid: 0,
      label: 'Your first name',
      id: 'firstName',
      type: 'text',
      errorMessage: 'Please enter a valid first name',
      value: firstName.value,
      isValid: firstName.isValid,
      hasError: firstName.hasError,
      valueChangedHandler: firstName.valueChangedHandler,
      touchHandler: firstName.touchHandler,
      reset: ()=>firstName.reset()
		},
		{
			fid: 1,
      label: 'Your last name',
      id: 'lastName',
      type: 'text',
      errorMessage: 'Please enter a valid last name',
      value: lastName.value,
      isValid: lastName.isValid,
      hasError: lastName.hasError,
      valueChangedHandler: lastName.valueChangedHandler,
      touchHandler: lastName.touchHandler,
      reset: ()=>lastName.reset()
		},
		{
			fid: 2,
      label: 'Your email',
      id: 'email',
      type: 'email',
      errorMessage: 'Please enter a valid email',
      value: email.value,
      isValid: email.isValid,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
		},
		{
			fid: 3,
      label: 'Create your password',
      id: 'password',
      type: 'password',
      helpText: 'Your password must be at least 6 characters long but we recommend you use a password even safer than that',
      errorMessage: 'Please enter a valid password (at least 6 characters)',
      value: password.value,
      isValid: password.isValid,
      hasError: password.hasError,
      valueChangedHandler: password.valueChangedHandler,
      touchHandler: password.touchHandler,
      reset: ()=>password.reset()
		}
	];

	const submitHandler = event => {
    event.preventDefault();
    fields.map(field=>field.reset());
    authCtx.onRegister({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value 
    });
  }

  return (
    <Form fields={fields} submitHandler={submitHandler} serverError={authCtx.error}>
    	Register
    </Form>
  );
} 

export default RegistrationForm;